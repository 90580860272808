import {gql} from '@apollo/client'
import {NESTEDSCHEMA_DESCENDANTS, NESTEDSCHEMA_INFO} from './fragments'

export const FETCH_NESTEDSCHEMAS = gql`
  ${NESTEDSCHEMA_INFO}
  ${NESTEDSCHEMA_DESCENDANTS}
  query NestedSchemas($isParent: Boolean, $ids: [ID]) @api(name: "datasets") {
    nestedSchemas(isParent: $isParent, ids: $ids) {
      id
      ...NestedSchemaInfo
      ...NestedSchemaDescendants
      datasetsCount
      createdById
      createdAt
    }
  }
`

export const FETCH_NESTEDSCHEMA = gql`
  ${NESTEDSCHEMA_INFO}
  ${NESTEDSCHEMA_DESCENDANTS}
  query FetchNestedSchema($id: ID!) @api(name: "datasets") {
    nestedSchema(id: $id) {
      id
      ...NestedSchemaInfo
      options
      ...NestedSchemaDescendants
      createdById
      updatedAt
      datasetsCount
    }
  }
`
export const FETCH_NESTEDSCHEMA_BY_NAME = gql`
  ${NESTEDSCHEMA_INFO}
  ${NESTEDSCHEMA_DESCENDANTS}
  query FetchNestedSchema($name: String!) @api(name: "datasets") {
    parentSchemaByName(name: $name) {
      ...NestedSchemaInfo
      ...NestedSchemaDescendants
    }
  }
`

export const FETCH_DATASETS = gql`
  ${NESTEDSCHEMA_INFO}
  query Datasets($ids: [ID]) @api(name: "datasets") {
    datasets(ids: $ids) {
      id
      data
      nestedSchema {
        id
        ...NestedSchemaInfo
        descendants {
          title
          name
          type
        }
      }
    }
  }
`
export const FETCH_SCHEMA_WITH_DATASETS = gql`
  ${NESTEDSCHEMA_INFO}
  ${NESTEDSCHEMA_DESCENDANTS}
  query FetchSchemaWithDatasets($id: ID!) @api(name: "datasets") {
    nestedSchema(id: $id) {
      id
      ...NestedSchemaInfo
      ...NestedSchemaDescendants
      datasets {
        id
        data
      }
      datasetsCount
    }
  }
`

export const FETCH_DATASETS_BY_SCHEMA = gql`
  query FetchDatasetsBySchema($id: String!, $option: OptionInput)
  @api(name: "datasets") {
    datasetsBySchema(id: $id, option: $option) {
      data {
        id
        data
      }
      total
    }
  }
`

export const FETCH_DATASET_USAGE = gql`
  query FetchDatasetUsage($ids: [String!]!) @api(name: "datasets") {
    datasetUsage(ids: $ids) {
      datasetId
      assetsCount
      usersCount
    }
  }
`

export const FETCH_DATASETS_BY_SCHEMA_NAMES = gql`
  query FetchDatasetsBySchemaName(
    $name: String!
    $option: OptionInput
    $filters: [FiltersInput!]
  ) @api(name: "datasets") {
    datasetsBySchemaName(name: $name, option: $option, filters: $filters) {
      data
      nestedSchema {
        name
        id
      }
    }
  }
`

export const FETCH_SCHEMA_USAGE = gql`
  query NestedSchemaUsage($id: ID!) @api(name: "datasets") {
    nestedSchemaUsage(id: $id) {
      nestedSchema {
        id
      }
      usage {
        datasetId
        assetsCount
        usersCount
      }
    }
  }
`

export const FETCH_SCHEMA_IDS_BY_FIELD = gql`
  ${NESTEDSCHEMA_INFO}
  ${NESTEDSCHEMA_DESCENDANTS}
  query NestedSchemaIdsByField($field: String!, $value: String!)
  @api(name: "datasets") {
    nestedSchemaIdsByField(field: $field, value: $value)
  }
`

export const FETCH_PARENT_SCHEMA_BY_FIELDS = gql`
  ${NESTEDSCHEMA_INFO}
  ${NESTEDSCHEMA_DESCENDANTS}
  query FetchParentSchemaByFields($fields: [String!]!) @api(name: "datasets") {
    parentSchemasByFields(fields: $fields) {
      id
      ...NestedSchemaInfo
      ...NestedSchemaDescendants
      datasets {
        id
        data
      }
      datasetsCount
    }
  }
`

export const FETCH_ALL_DATASETS_BY_SCHEMA = gql`
  query AllDatasetsBySchema($id: String!) @api(name: "datasets") {
    allDatasetsBySchema(id: $id) {
      url
      background
    }
  }
`
