import type {InputHTMLAttributes} from 'react'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  id?: string
  name: string
  sizeVariant?: `sm` | `base`
}

const sizes = {
  sm: `w-3 h-3`,
  base: `w-4 h-4`,
}

const RadioButton = ({
  id,
  name,
  sizeVariant = `base`,
  className = ``,
  ...props
}: Props) => {
  return (
    <input
      {...props}
      id={id}
      type="radio"
      name={name}
      className={`
        ${className}
        ${sizes[sizeVariant]}
        form-radio
        inline-flex
        shrink-0
        appearance-none
        rounded-full
        border
        bg-white
        align-middle
        text-blue-500
        shadow
      `}
    />
  )
}

export default RadioButton
