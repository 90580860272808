import {useEffect, useState} from 'react'

export const useRuntimeString = (
  initial: string,
  alternate: string,
  condition: boolean
) => {
  const [string, setString] = useState(initial)

  useEffect(() => {
    setString(condition ? initial : alternate)
  }, [condition, initial, alternate])

  return string
}
