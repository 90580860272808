import {CheckIcon as Tick} from '@heroicons/react/24/outline'
import {MouseEvent, ReactNode} from 'react'
import {cn} from '../../util'
import {BulletPoint} from '../Icons'
import {useRuntimeString} from './useRuntimeString'

interface Props {
  href?: string
  index?: number
  isSelected?: boolean
  clickHandler?(e: MouseEvent): void
  icon?: `tick` | `dot`
  children: ReactNode
  className?: string
}

export const firstItemClassName = `mt-1`
export const selectedClassName = `text-ui-700 font-normal`
const iconBaseClasses = `
  md:mr-0.5
  text-blue-600
  dark:text-blue-500
  block
  group-hocus:opacity-70
  right-[100%]
  top-0
  absolute
  w-3
  h-3
  translate-y-1/2
  `
const hiddenClassName = `opacity-0`
const shownClassName = `opacity-100`

const noop = () => {}

const FilterListItem = ({
  children,
  href,
  index,
  isSelected = false,
  clickHandler = noop,
  icon = `dot`,
  className = ``,
  ...props
}: Props) => {
  const visibilityClasses = useRuntimeString(
    shownClassName,
    hiddenClassName,
    isSelected
  )

  const iconClassnames = cn(iconBaseClasses, visibilityClasses)

  const icons = {
    tick: <Tick className={iconClassnames} />,
    dot: <BulletPoint className={iconClassnames} />,
  }

  const childSelectedClasses = useRuntimeString(
    selectedClassName,
    ``,
    isSelected
  )
  const InteractiveTag = href ? `a` : `button`

  return (
    <li
      className={`
      ${index === 0 ? firstItemClassName : ``}
      group
      list-none
    `}
    >
      <InteractiveTag
        href={href}
        className={cn(
          `
          hover:text-ui-700
          relative
          block
          text-sm
          font-light
          hover:underline
          `,
          className,
          childSelectedClasses
        )}
        style={{
          paddingTop: `2px`,
          paddingBottom: `2px`,
          lineHeight: `inherit`,
        }}
        onClick={clickHandler}
        {...props}
      >
        {icons[icon]}
        <span>{children}</span>
      </InteractiveTag>
    </li>
  )
}

export default FilterListItem
