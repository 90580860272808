/*
 * SEO component that queries for data with
 * Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import {graphql, useStaticQuery} from 'gatsby'
import Helmet from 'react-helmet'

interface Props {
  description?: string
  lang?: string
  meta?: {
    name: string
    content: string
  }[]
  title?: string
}

const SEO = ({description = ``, lang = `en`, meta = [], title = ``}: Props) => {
  const {site} = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

export default SEO
