import {ReactNode} from 'react'

export interface HeaderProps {
  startContent?: ReactNode
  endContent?: ReactNode
  children?: ReactNode
}

const HeaderLayout = ({
  children,
  endContent,
  startContent,
  ...props
}: HeaderProps) => {
  return (
    <div
      id="Header"
      className={`
        👴🏽
        md:h-18
        bg-ui-50
        relative
        z-20
        flex
        h-16
        shrink-0
        shadow
      `}
      {...props}
    >
      {startContent}
      <div
        className={`
          👴🏽inner
          flex
          min-w-0
          flex-1
          justify-between
        `}
      >
        <div
          className={`
            👴🏽main
            flex
            min-w-0
            flex-1
          `}
        >
          {children}
        </div>
        {endContent && (
          <div
            className={`
              👴🏽supporting
              ml-auto
              flex
              items-center
              justify-end
              gap-3
              pr-4
              md:pr-8
            `}
          >
            {endContent}
          </div>
        )}
      </div>
    </div>
  )
}

export default HeaderLayout
