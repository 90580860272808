import {cn} from '@myadbox/stellar-ui'
import {MultiValueGenericProps, components} from 'react-select'

type OurMultiValueProps = MultiValueGenericProps & {className?: string}

export const OurMultiValueLabel = ({
  className = ``,
  ...props
}: OurMultiValueProps) => {
  const newProps = {
    ...props,
    innerProps: {
      ...props.innerProps,
      className: cn(
        `
          text-xs
          dark:text-ui-500
          text-ui-600
          relative
          w-full
        `,
        className
      ),
    },
  }
  return <components.MultiValueLabel {...newProps} />
}
