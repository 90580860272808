import {ChevronDownIcon} from '@heroicons/react/20/solid'
import {MenuButton, MenuLink} from '@reach/menu-button'
import {
  ComponentProps,
  ComponentPropsWithoutRef,
  MouseEvent,
  ReactNode,
} from 'react'
import {cn} from '../../util'
import LoaderIcon from '../Icons/LoaderIcon'
import {useTriggerSizeContext} from './helper'
import {buttonClassNames, iconClassnames} from './styles'
import {DropdownTriggerVariant} from './types'

type NativeButtonProps = ComponentPropsWithoutRef<`button`>
export type DropdownTriggerProps = Omit<NativeButtonProps, `id`> & {
  id: NativeButtonProps[`id`]
  unstyled?: boolean
  variant?: DropdownTriggerVariant
  loading?: boolean
  loadingText?: string
  icon?: boolean | ReactNode
  // TODO: Enhance polymorphism implems
  onButtonClick?: <T extends HTMLButtonElement | HTMLAnchorElement>(
    event: MouseEvent<T>
  ) => void
  href?: string
}

export default function DropdownTrigger({
  unstyled,
  variant = `primary`,
  className,
  disabled,
  loading,
  loadingText,
  icon,
  href,
  onButtonClick,
  children,
  ...props
}: DropdownTriggerProps) {
  const sizeCls = useTriggerSizeContext()

  const rootCls = cn(
    sizeCls,
    !unstyled && [
      buttonClassNames.base,
      buttonClassNames[variant],
      disabled && buttonClassNames.disabled,
    ],
    className
  )
  const rootContent = loadingText || children

  const showIcon = !unstyled || !!icon
  const IconRender =
    icon ?? loading ? (
      <LoaderIcon size={16} />
    ) : (
      <ChevronDownIcon width={18} height={18} />
    )
  const iconCls = cn(
    iconClassnames.base,
    variant === `primary` && iconClassnames.primary
  )

  if (!(href || onButtonClick))
    return (
      <MenuButton
        type="button"
        disabled={disabled || loading}
        className={cn(rootCls, !unstyled && `pl-4`, `py-2`)}
        {...props}
      >
        <span className={cn(!unstyled && `mr-4`)}>{rootContent}</span>
        {showIcon ? <span className={iconCls}>{IconRender}</span> : null}
      </MenuButton>
    )

  return (
    <div className={rootCls}>
      {href ? (
        <MenuLink
          disabled={disabled}
          href={href}
          rel="noreferrer"
          target="_blank"
          className={buttonClassNames.asLink}
          onClick={onButtonClick}
        >
          {children}
        </MenuLink>
      ) : (
        rootContent && (
          <button
            type="button"
            className={`px-3 py-2 leading-5`}
            disabled={disabled || loading}
            onClick={onButtonClick}
            aria-label={props[`aria-label`]}
          >
            {rootContent}
          </button>
        )
      )}
      {showIcon ? (
        <MenuButton
          type="button"
          disabled={disabled || loading}
          className={cn(iconCls, `py-2`)}
          {...props}
        >
          {icon}
        </MenuButton>
      ) : null}
    </div>
  )
}

export const DropdownTriggerPlaceholder = function ({
  className,
  ...props
}: ComponentProps<`span`>) {
  const sizeCls = useTriggerSizeContext()

  return (
    <span
      {...props}
      className={cn(`pointer-events-none absolute inset-0`, sizeCls, className)}
    />
  )
}

DropdownTrigger.Placeholder = DropdownTriggerPlaceholder
