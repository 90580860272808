import type {Environment} from '@myadbox/nebula-routes'
import {ReactNode, createContext, useContext} from 'react'

interface AppOptions {
  activeEnv: Environment
  cloudName: string
  region: string
  adminPoolId: string
  adminPoolAppId: string
  primaryColor?: string
  loginBackgroundFileUrl?: string
  loginBackgroundPosition?: string
  loginBackgroundOverlay?: number
}

const StateContext = createContext<AppOptions | null>(null)
StateContext.displayName = `AppOptions StateContext`

export const AppOptionsProvider = ({
  initialValue,
  children,
}: {
  initialValue: AppOptions
  children: ReactNode
}): JSX.Element => {
  return (
    <StateContext.Provider value={initialValue}>
      {children}
    </StateContext.Provider>
  )
}

export const useAppOptions = () => {
  const state = useContext(StateContext)
  if (state === null) {
    throw new Error(`useAppOptions must be used within a AppOptionsProvider`)
  }
  return state
}
