import {Banner} from '@myadbox/stellar-ui'
import {createPortal} from 'react-dom'

interface Props {
  showBanner: boolean
  setShowBanner: (show: boolean) => void
  children: React.ReactNode
}

const CloudinaryUploaderSizeBanner = ({
  showBanner,
  setShowBanner,
  children,
}: Props) => (
  <>
    {createPortal(
      <div
        className={`
        absolute
        w-full
      `}
        style={{zIndex: 1001000}}
      >
        {showBanner && (
          <div
            className={`
            mx-auto
            my-3
            max-w-lg
            md:max-w-xl
          `}
          >
            <Banner
              fadeIn
              intent="warning"
              onDismiss={() => setShowBanner(false)}
            >
              {children}
            </Banner>
          </div>
        )}
      </div>,
      document.body
    )}
  </>
)

export default CloudinaryUploaderSizeBanner
