import {withNebulaLDProvider} from '@myadbox/nebula-launchdarkly'

export const wrapRootElement = ({element}, pluginOptions) => {
  if (!pluginOptions.clientSideID) {
    throw new Error(`LaunchDarkly client side ID is required`)
  }

  const hoc = withNebulaLDProvider({
    clientSideID: pluginOptions.clientSideID,
  })

  const LDApp = hoc(() => element)

  return <LDApp />
}
