import {
  ActiveIntegrationType,
  AppLink,
  Asset,
  AssetLinkage,
  CarsalesInput,
  ConnectedIntegration,
  ExportSocialInput,
  CarsalesAdFormatId,
  CarsalesSizes,
} from '@myadbox/nebula-service-api'
import {SocialsIntegrationFormValues} from '../types'

export const urlRegex =
  /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/i
export const isGoogleAds = (integrationType: ActiveIntegrationType) =>
  integrationType === `GOOGLE_ADS`
export const isCarsales = (integrationType: ActiveIntegrationType) =>
  integrationType === `CARSALES`

export const inLinkages = (
  asset: Asset,
  id: string,
  integrationType: ActiveIntegrationType
) => {
  const versions = asset.versions

  const activeCloudinaryId =
    versions.length === 1
      ? versions[0].cloudinaryId
      : versions.find(version => version.active)?.cloudinaryId

  const linkageApps = asset?.linkages?.find(
    (linkage: AssetLinkage) => linkage.type === integrationType
  )?.apps

  return linkageApps?.some(
    (app: AppLink) =>
      app.cloudinaryId === activeCloudinaryId && app.appId === id
  )
}

export const isValidDimensions = (
  width: number | undefined,
  height: number | undefined
) => {
  const dimStr = `${width} × ${height}`

  return Object.values(CarsalesSizes).some(size => size === dimStr)
}

export const findAdFormatId = (asset: Asset) => {
  const versions = asset.versions
  const activeVersion =
    versions.length === 1
      ? versions[0]
      : versions.find(version => version.active)

  const activeWidth = activeVersion?.width
  const activeHeight = activeVersion?.height

  const dimStr = `${activeWidth} × ${activeHeight}`

  const foundFormat =
    Object.entries(CarsalesSizes).find(([key, value]) => value === dimStr) ?? ``

  return foundFormat[0] as CarsalesAdFormatId
}

export const disableForCarsales = (
  asset: Asset,
  integrationType: ActiveIntegrationType
) => {
  const versions = asset.versions
  const activeVersion =
    versions.length === 1
      ? versions[0]
      : versions.find(version => version.active)

  const activeWidth = activeVersion?.width
  const activeHeight = activeVersion?.height

  return isCarsales(integrationType)
    ? isValidDimensions(activeWidth, activeHeight)
    : true
}

export const transformPayload = (
  values: SocialsIntegrationFormValues,
  integrationType: ActiveIntegrationType,
  connectedIntegration: ConnectedIntegration | undefined
): ExportSocialInput | CarsalesInput => {
  const {asset, message, page, carSales} = values
  const payloadBody = {
    id: page,
    integrationId: connectedIntegration?.integrationId,
    assetId: asset?.id,
    type: String(integrationType),
  }
  switch (integrationType) {
    case `FACEBOOK`:
    case `INSTAGRAM`:
      return {
        ...payloadBody,
        message,
      } as ExportSocialInput
    case `CARSALES`:
      return {
        ...payloadBody,
        carSales,
      } as CarsalesInput
    case `GOOGLE_ADS`:
    default:
      return {
        ...payloadBody,
      } as ExportSocialInput
  }
}
