import {Progress} from '@myadbox/gatsby-theme-nebula'
import {useAccount, useProfiles} from '@myadbox/nebula-service-api'
import type {Segment} from '@myadbox/stellar-ui'
import {Legend, Pie, Text} from '@myadbox/stellar-ui'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {useEffect} from 'react'

export const AccountUsers = () => {
  const {t} = useTranslation()
  const {
    fetchProfileStats,
    fetchProfileStatsResults: {data},
  } = useProfiles()
  const {account} = useAccount()

  useEffect(() => {
    fetchProfileStats()
  }, [fetchProfileStats])

  const usersLimit = account?.billing?.limits?.users
  const hasData = !!data?.userStats
  const totalUsers = hasData
    ? data.userStats.admins + data.userStats.members
    : 0
  const segments: Segment[] = []

  if (hasData) {
    if (data.userStats.admins > 0) {
      segments.push({
        value: data.userStats.admins,
        label: t`storage.admins`,
      })
    }
    if (data.userStats.members > 0) {
      segments.push({
        value: data.userStats.members,
        label: t`storage.members`,
      })
    }
  }

  const usersPercentage = Math.round((totalUsers / usersLimit) * 100)

  if (hasData && totalUsers === 0) {
    return (
      <div className={`italic`}>
        <Text variant="subHeading">{t`storage.noUsers`}</Text>
      </div>
    )
  }

  return (
    <div>
      {Boolean(usersLimit) && (
        <div
          className={`
            mb-4
            w-full
            md:w-[50%]
          `}
        >
          <Progress
            label={t(`storage.progress`, {
              value: totalUsers,
              max: usersLimit,
            })}
            labelPos="right"
            value={totalUsers}
            max={usersLimit}
          />
        </div>
      )}
      <div className={`flex`}>
        <div
          className={`
            w-18
            mr-4
            xl:w-[10%]
          `}
        >
          {hasData ? (
            <Pie
              segments={segments}
              total={usersLimit}
              autoComplete={!!usersLimit}
            >
              {!!usersLimit && (
                <div className={`text-center`}>
                  <div
                    className={`
                      text-ui-900
                      dark:text-ui-900
                      text-sm
                      font-medium
                    `}
                  >
                    {usersPercentage}%
                  </div>
                  <div className={`text-tiny`}>{t`storage.utilised`}</div>
                </div>
              )}
            </Pie>
          ) : (
            <Pie segments={[{value: 0, label: ``}]} total={1} />
          )}
        </div>
        <div className={`grow`}>
          {hasData ? (
            <Legend
              header={{label: t`storage.users`, value: totalUsers}}
              segments={segments}
            />
          ) : (
            <Text variant="subHeading">{t`storage.loading`}</Text>
          )}
        </div>
      </div>
    </div>
  )
}

export default AccountUsers
