import {
  downloadErrorTextFile,
  getToggledClassName,
  isBrowser,
  isEmptyObject,
} from './src/utils/helpers'
import {noop} from './src/utils/noop'
import {clearAuthData, isValidSession, setAuthData} from './src/utils/session'
import silhouetteStylesWhen from './src/utils/states/silhouetteStylesWhen'
import {
  dateStyles,
  getDefaultTimezone,
  getUserTimezoneTime,
  numDateStyles,
  timeStyles,
  toLocaleTimezone,
  toUTC,
} from './src/utils/timezone'

export {
  clearAuthData,
  downloadErrorTextFile,
  getDefaultTimezone,
  getToggledClassName,
  getUserTimezoneTime,
  isBrowser,
  isEmptyObject,
  isValidSession,
  noop,
  setAuthData,
  silhouetteStylesWhen,
  toLocaleTimezone,
  toUTC,
}

export default {
  clearAuthData,
  dateStyles,
  downloadErrorTextFile,
  getDefaultTimezone,
  getToggledClassName,
  getUserTimezoneTime,
  isValidSession,
  isBrowser,
  isEmptyObject,
  noop,
  numDateStyles,
  setAuthData,
  silhouetteStylesWhen,
  timeStyles,
  toLocaleTimezone,
  toUTC,
}
