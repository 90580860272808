import {DatablockRecordFilter, FilterValue} from '../types'
import {isPageKey} from './guards'

export const calculatePageKey = (page: string | number) => {
  const pageKey = page.toString().padStart(3, `0`)
  return isPageKey(pageKey) ? pageKey : raise(`Invalid pageKey: ${pageKey}`)
}

const raise = (message: string): never => {
  throw new Error(message)
}

export const deriveDataBlockRecordFilters = (
  filters: DatablockRecordFilter[]
): Array<Omit<DatablockRecordFilter, `value`> & {value: FilterValue[]}> => {
  return filters.map(filter => {
    return {
      ...filter,
      value: getDatablockRecordFilterValueArray(filter.value),
    }
  })
}

export const getDatablockRecordFilterValueArray = (
  datablockFilterValue: FilterValue | FilterValue[]
): FilterValue[] => {
  return Array.isArray(datablockFilterValue)
    ? datablockFilterValue
    : [datablockFilterValue]
}
