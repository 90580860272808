import {Cog6ToothIcon} from '@heroicons/react/20/solid'
import type {NestedSchemaField} from '@myadbox/nebula-service-api'
import {Button, ComplexModal as Modal} from '@myadbox/stellar-ui'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {ReactElement} from 'react'
import EditColumnsForm from '../EditColumnsForm'

interface Props {
  schemaId: string
  fields: NestedSchemaField[]
  trigger?: (open: () => void) => ReactElement
  refetchDatasets: () => void
}

export const EditColumnsModal = ({
  schemaId,
  fields,
  trigger,
  refetchDatasets,
}: Props) => {
  const {t} = useTranslation()

  const defaultTrigger = (open: () => void) => (
    <Button
      size="xs"
      slim
      variant="link"
      onClick={open}
      type="button"
      title={t(`settings.schemas.columnSettings.triggerBtn`)}
    >
      <Cog6ToothIcon width={18} />
    </Button>
  )

  return (
    <Modal
      maxWidth="md"
      trigger={({open}): ReactElement =>
        trigger?.(open) || defaultTrigger(open)
      }
    >
      {({close}) => (
        <>
          <Modal.Header>
            {t(`settings.schemas.columnSettings.header`)}
          </Modal.Header>
          <EditColumnsForm
            schemaId={schemaId}
            fields={fields}
            close={close}
            refetchDatasets={refetchDatasets}
          />
        </>
      )}
    </Modal>
  )
}

export default EditColumnsModal
