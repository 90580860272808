import {useFileReader} from '@myadbox/gatsby-theme-nebula/hooks'
import {NestedSchema, useDatasets} from '@myadbox/nebula-service-api'
import {
  ActionGroup,
  Banner,
  Button,
  Input,
  ComplexModal as Modal,
  Toast,
} from '@myadbox/stellar-ui'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {HTMLAttributes, useCallback, useEffect, useMemo, useState} from 'react'
import useAsyncEffect from 'use-async-effect'
import {
  convertCsvHeaders,
  downloadErrors,
  getColParser,
  prepareDataRecordsForUpsert,
  validateData,
} from '../helpers'
import {convertCsvToJson} from './helper'

const CSV_FILE_MIMES = `text/csv, text/plain, application/vnd.ms-excel`

interface Props extends HTMLAttributes<HTMLFormElement> {
  close: () => void
  refetchDatasets: () => void
  nestedSchema: NestedSchema
}

const CsvUploader = ({close, nestedSchema, refetchDatasets}: Props) => {
  const {t} = useTranslation()
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)

  const {upsertDatasets, upsertDatasetsResponse} = useDatasets()
  const [{result: csvData, error: readerError}, setFile] = useFileReader()

  const colParser = useMemo(
    () => getColParser(nestedSchema.descendants),
    [nestedSchema.descendants]
  )

  const headerConverter = useCallback(
    (fileLine: string) => {
      return convertCsvHeaders(fileLine, nestedSchema.descendants)
    },
    [nestedSchema.descendants]
  )

  useEffect(() => {
    if (!csvData) return

    convertCsvToJson({csv: csvData, colParser, headerConverter}).then(
      ({data, error}) => {
        if (error) {
          setError({message: t(`settings.schemas.csvUploader.${error}`)})
        } else {
          setData(data)
        }
      }
    )
  }, [csvData, t, colParser, headerConverter, setData, setError])

  useEffect(() => {
    readerError && setError({message: readerError})
  }, [readerError])

  const handleFileInput = (e): void => {
    setError(null)
    setData(null)
    setFile(e.target.files[0])
  }

  const handleSubmit = (e): void => {
    e.preventDefault()
    if (!data) return
    const errors = validateData(nestedSchema.descendants, data, t)
    if (errors) {
      setError({message: t`settings.schemas.csvUploader.dataErrorMsg`, errors})
    } else {
      upsertDatasets(
        nestedSchema.id,
        prepareDataRecordsForUpsert(nestedSchema.descendants, data)
      )
    }
  }

  useAsyncEffect(
    isMounted => {
      if (upsertDatasetsResponse.called && !!upsertDatasetsResponse.data) {
        Toast.show(
          <Toast intent="success">{t`settings.schemas.csvUploader.successMsg`}</Toast>
        )
        refetchDatasets()
        if (isMounted()) {
          close()
        }
      }
    },
    [upsertDatasetsResponse.data]
  )

  return (
    <>
      <Modal.Header>{t`settings.schemas.csvUploader.header`}</Modal.Header>
      <form name="csv-upload-form" onSubmit={handleSubmit}>
        <Modal.Body>
          <Input
            type="file"
            id="csvFile"
            name="csvFile"
            label="Upload .csv"
            onInput={handleFileInput}
            accept={CSV_FILE_MIMES}
          />
        </Modal.Body>
        <Modal.Footer>
          <ActionGroup>
            <Button type="button" variant="secondary" onClick={close}>
              {t`settings.cancel`}
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={
                error || !data || upsertDatasetsResponse?.loading || false
              }
              loading={upsertDatasetsResponse?.loading || false}
              loadingText={t`settings.uploading`}
            >
              {t`settings.upload`}
            </Button>
          </ActionGroup>
          {!!error && (
            <div className="col-span-full mt-4">
              <Banner intent="error" fadeIn>
                <div>
                  <span>{error.message}</span>
                  {!!error.errors && (
                    <Button
                      slim
                      size="sm"
                      variant="link"
                      className={`ml-2`}
                      onClick={() =>
                        downloadErrors(error.errors, nestedSchema.name)
                      }
                    >
                      {t`settings.schemas.csvUploader.dataErrorDownloadBtn`}
                    </Button>
                  )}
                </div>
              </Banner>
            </div>
          )}
        </Modal.Footer>
      </form>
    </>
  )
}

export default CsvUploader
