import {gql} from '@apollo/client'

export const EDITABLE_INFO = gql`
  fragment EditableInfo on Asset {
    name
    description
    notes
    terms {
      details
      expiry
    }
    datasets {
      id
      data
      nestedSchema {
        id
        title
        name
        descendants {
          title
          name
          type
        }
      }
    }
  }
`

export const COMMON_ATTRIBUTES = gql`
  fragment CommonAttributes on Asset {
    tags
    autoTags
    categories {
      id
      title
      parent
      active
      ancestors {
        id
        title
        parent
      }
    }
    datasets {
      id
      data
      nestedSchema {
        id
        title
        name
        descendants {
          title
          name
          type
        }
      }
    }
    terms {
      details
      expiry
    }
    approvalUrl
  }
`

export const ASSET_STATS = gql`
  fragment AssetStats on Asset {
    stats {
      views
      downloads
    }
  }
`

export const ASSET_VERSIONS = gql`
  fragment AssetVersions on Asset {
    versions {
      cloudinaryId
      content {
        preset
        width
        height
        unit
        fields
        outputSettings
        templateUrl
        secureUrl
      }
      previewId
      thumbnailId
      originalFilename
      originalFormat
      resourceType
      bytes
      width
      height
      pages
      addedById
      exportSizes {
        format
        quality
        size
      }
      active
      createdAt
    }
  }
`

export const ASSET_APPROVERS = gql`
  fragment AssetApprovers on Asset {
    approvers {
      approverId
      approverType
    }
  }
`
