import {Amplify} from '@aws-amplify/core'
import {useAppOptions} from '@myadbox/nebula-app-options'
import {useFeatureFlags} from '@myadbox/nebula-launchdarkly'
import {
  Account,
  blankAccount,
  useLoginAccount,
} from '@myadbox/nebula-service-api'
import {useLocation} from '@reach/router'
import {
  ReactElement,
  createContext,
  useContext,
  useDebugValue,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {ADMIN_URL} from '../../utils/helpers'

interface Props {
  children: ReactElement
}

export const CognitoContext = createContext(null)
CognitoContext.displayName = `CognitoContext`

const LANGUAGE_REGEX = `/[a-z]{2}-[A-Z]{2}`
export const CognitoProvider = ({children}: Props) => {
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const [isConfigured, setIsConfigured] = useState<boolean>(false)

  const {hasFeaturesInit} = useFeatureFlags()
  const pluginOptionsConfig = useAppOptions()
  const {protocol, hostname, pathname} = useLocation()
  const {account} = useLoginAccount()

  useEffect(() => {
    if (!hasFeaturesInit) return

    const nextIsAdmin = initAdmin(pathname)
    const configs = account?.configuration?.loginConfig

    if (configs && !isConfigured) {
      const {userPoolId, userPoolAppId, ssoEnabled, ssoConfig} = configs
      const {region, adminPoolId, adminPoolAppId} = pluginOptionsConfig
      const url = `${protocol}//${hostname}/`

      Amplify.configure({
        Auth: {
          region,
          userPoolId: nextIsAdmin ? adminPoolId : userPoolId,
          userPoolWebClientId: nextIsAdmin ? adminPoolAppId : userPoolAppId,
          oauth: ssoEnabled
            ? {
                domain: ssoConfig.domain,
                scope: ssoConfig?.scope?.split(`,`),
                redirectSignIn: url,
                redirectSignOut: `${url}logout/`,
                responseType: `token`,
              }
            : {},
        },
      })
      setIsConfigured(true)
    }

    setIsAdmin(nextIsAdmin)
  }, [
    hasFeaturesInit,
    pathname,
    account,
    isConfigured,
    isAdmin,
    pluginOptionsConfig,
    protocol,
    hostname,
  ])

  const contextValue = useMemo(
    () => ({isAdmin, isConfigured, account}),
    [isAdmin, isConfigured, account]
  )

  return (
    <CognitoContext.Provider value={contextValue}>
      {children}
    </CognitoContext.Provider>
  )
}
export const useCognito = () => {
  const {isAdmin, isConfigured, account} = useContext<{
    isAdmin: boolean
    isConfigured: boolean
    account: Account
  }>(CognitoContext) || {
    isAdmin: false,
    isConfigured: false,
    account: blankAccount,
  }
  useDebugValue(`isAdmin: ${isAdmin} isConfigured: ${isConfigured}`)
  return {isAdmin, isConfigured, account}
}

export const initAdmin = (pathname: string): boolean => {
  if (new RegExp(`^((${LANGUAGE_REGEX}(/)?)|/)$`).test(pathname)) {
    localStorage.removeItem(`isAdmin`)
  } else if (
    new RegExp(`^(${LANGUAGE_REGEX})?${ADMIN_URL}(/)?$`).test(pathname)
  ) {
    localStorage.setItem(`isAdmin`, `true`)
    return true
  } else {
    const isAdminLocal = localStorage.getItem(`isAdmin`)
    if (isAdminLocal === `true`) {
      return true
    }
  }
  return false
}

export default CognitoProvider
