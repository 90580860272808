import {MutationResult, OperationVariables, QueryResult} from '@apollo/client'

export type DisconnectSocialInput = {
  integrationId: string
}

export type DisconnectResponse = {
  success: boolean
  message: string
  deletedAt?: string
}
export type UseDisconnectSocialOutput = {
  disconnectSocial(input: DisconnectSocialInput): void
  disconnectResponse: MutationResult<{disconnectSocial: DisconnectResponse}>
}

export type ActiveIntegrationType =
  | `FACEBOOK`
  | `INSTAGRAM`
  | `GOOGLE_ADS`
  | `EMBED`
  | `CARSALES`

export const CarsalesSizes = {
  STANDARD_DISPLAY_DESKTOP_LONG_IMAGE: `300 × 600`,
  STANDARD_DISPLAY_MOBILE_SMALL_IMAGE: `300 × 100`,
  STANDARD_DISPLAY_DESKTOP_SHORT_IMAGE: `300 × 250`,
  STANDARD_DISPLAY_DESKTOP_WIDE_IMAGE: `728 × 90`,
} as const

export type CarsalesAdFormatId = keyof typeof CarsalesSizes
export interface CarsalesPayload {
  campaignId: string
  ctaUrl: string
  adName: string
  adFormatId: CarsalesAdFormatId | ``
}

export interface ExportSocialInput {
  id: string
  integrationId: string
  message?: string
  assetId: string
  type: ActiveIntegrationType
}

export interface CarsalesInput extends ExportSocialInput {
  carSales: CarsalesPayload
}

export type ExportResponse = {
  success: boolean
  message: string
  createdAt?: string
}
export type UseExportSocialOutput = {
  exportSocial(input: ExportSocialInput): void
  exportResponse: MutationResult<{createImagePost: ExportResponse}>
}

export type App = {
  id: string
  category: string
  name: string
}

export type User = {
  id: string
  name: string
  token: {
    expiresAt: string
  }
}

export type ConnectedIntegration = {
  integrationId: string
  apps: App[]
  user: User
}

export type UseConnectedIntegrationsOutput = {
  fetchConnectedIntegrations(): void
  fetchConnectedIntegrationsResults: QueryResult<
    {
      withIntegrations: ConnectedIntegration[]
    },
    OperationVariables
  >
}

export interface ExchangeTokenInput {
  authToken: string
  integrationId: string
  type: string
}

export type ExchangeTokenResponse = {
  success: boolean
  message: string
  deletedAt?: string
}
export interface UseExchangeTokenOutput {
  exchangeToken: (input: ExchangeTokenInput) => void
  exchangeTokenResponse: MutationResult<{
    exchangeToken: ExchangeTokenResponse
  }>
}
