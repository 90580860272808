// helper function
export const hideClassName = `
  until-md:max-h-0
  until-md:opacity-0
`
export const showClassName = `
  until-md:max-h-[4000px]
  until-md:opacity-100
`
export const getToggledClassName = (isShown: boolean): string => `
  until-lap:overflow-hidden
  duration-300
  transition-all
  ${isShown ? showClassName : hideClassName}
`

export const isBrowser = () => {
  return (
    typeof window !== `undefined` &&
    `localStorage` in window &&
    `location` in window
  )
}
