import {stripTrailingSlash} from '@myadbox/string-utils'
import {useLocation} from '@reach/router'
import {useEffect, useState} from 'react'

// Helper function to strip query parameters
const stripQueryParams = (url: string): string => {
  const [path] = url.split(`?`)
  return path
}

export const partiallyMatches = (path: string, to: string): boolean => {
  if (typeof path !== `string`) return false

  // Remove locale and query parameters
  // eslint-disable-next-line no-useless-escape
  const noLocalePath = path.replace(/^\/[a-z]{2}\-[A-Z]{2}/i, ``)
  const cleanPath = stripQueryParams(noLocalePath)
  const cleanTo = stripQueryParams(to)

  // Perform the match
  return stripTrailingSlash(cleanPath).startsWith(stripTrailingSlash(cleanTo))
}

export const usePartiallyActive = (to: string): boolean => {
  const {pathname} = useLocation()
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    setIsActive(partiallyMatches(pathname, to))
  }, [pathname, to])
  return isActive
}

export default usePartiallyActive
