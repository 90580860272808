import type {NestedSchemaField} from '@myadbox/nebula-service-api'
import {Table} from '@myadbox/stellar-ui'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import AddColumnModal from '../AddColumnModal'
import EditColumnsModal from '../EditColumnsModal'
import {ActionsWrapper, getReadableType} from './helpers'

type Props = {
  schemaId: string
  fields: NestedSchemaField[]
  refetchDatasets: () => void
  isGoogleSynced: boolean
}

const EditableDataTableHeaders = ({
  schemaId,
  fields,
  refetchDatasets,
  isGoogleSynced,
}: Props) => {
  const {t} = useTranslation()
  return (
    <Table.Head>
      <Table.Row>
        {fields.map(field => (
          <Table.HeadCell key={field.id}>
            {field.title}
            <br />
            <span
              className={`dark:text-ui-400 text-ui-600 titling-caps text-xs font-light capitalize tracking-normal`}
            >
              {getReadableType(field, t(`settings.schemas.usage.required`))}
            </span>
          </Table.HeadCell>
        ))}

        <Table.HeadCell>{t`settings.schemas.usage.header`}</Table.HeadCell>

        {!isGoogleSynced && (
          <Table.HeadCell
            className={`
            sticky
            right-0
            pl-2
          `}
            style={{width: `5rem`}}
          >
            <ActionsWrapper>
              <AddColumnModal schemaId={schemaId} order={fields.length} />
              <EditColumnsModal
                schemaId={schemaId}
                fields={fields}
                refetchDatasets={refetchDatasets}
              />
            </ActionsWrapper>
          </Table.HeadCell>
        )}
      </Table.Row>
    </Table.Head>
  )
}

export default EditableDataTableHeaders
