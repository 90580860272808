import {ErrorMessage, useField} from 'formik'
import {ChangeEvent, InputHTMLAttributes, ReactNode} from 'react'

import {FieldError, FormItem, ToggleInput} from '@myadbox/stellar-ui'

export interface ToggleInputFormikProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, `onClick`> {
  name: string
  label: string | ReactNode
  id: string
  className?: string
  labelHidden?: boolean
  details?: ReactNode
  onClick?: ({value}: {value: boolean}) => () => void
}

const ToggleInputFormik = ({
  name,
  label,
  id,
  labelHidden,
  details,
  onClick,
}: ToggleInputFormikProps) => {
  const [field, meta, helpers] = useField({name, type: `checkbox`})
  const {value} = meta
  const {setValue} = helpers

  const handler = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation()
    onClick ? onClick({value}) : setValue(!value)
  }

  return (
    <FormItem
      label={
        labelHidden ? null : (
          <FormItem.Label htmlFor={id}>{label}</FormItem.Label>
        )
      }
      details={details}
    >
      <ToggleInput
        id={id}
        on={value}
        {...field}
        onChange={handler} // Last to override field.onChange
      />

      <ErrorMessage
        name={name}
        component={FieldError}
        data-testid={`error-${name}`}
        className={`
          pt-1
        `}
      />
    </FormItem>
  )
}

export default ToggleInputFormik
