import {ErrorMessageStyler, FieldError} from '@myadbox/stellar-ui'
import {ErrorMessage, type ErrorMessageProps} from 'formik'
import type {ComponentProps} from 'react'

interface Props extends ErrorMessageProps {
  scaleErrorMessage?: boolean
}
export const DEFAULT_VARIANTS = [`background`, `absolute`, `scaled`] as const
export const NO_VARIANTS = []

export const ErrorMessageFormikStyled = ({
  children,
  scaleErrorMessage,
  ...props
}: Props) => {
  const variants: ComponentProps<typeof ErrorMessageStyler>[`variants`] =
    scaleErrorMessage ? [...DEFAULT_VARIANTS] : NO_VARIANTS
  return (
    <ErrorMessageStyler variants={variants}>
      <ErrorMessage {...props} component={FieldError} />
    </ErrorMessageStyler>
  )
}

export default ErrorMessageFormikStyled
