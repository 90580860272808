import DatasetsInSchemaTable from '@myadbox/gatsby-theme-mimosa/src/components/DatasetsInSchemaTable'
import {useProfiles} from '@myadbox/nebula-service-api'
import {ActionGroup, Button, ComplexModal as Modal} from '@myadbox/stellar-ui'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {Dispatch, useEffect, useState} from 'react'
import {EditUserContext, EditUserModalState} from '../EditUserModal'

interface UserDatasetSelectorProps {
  schema: string
  setContext: Dispatch<EditUserModalState>
  profileId: string
  amzCognitoPoolId: string
  datasetIds: string[]
}

export const UserDatasetSelector = ({
  schema,
  profileId,
  amzCognitoPoolId,
  setContext,
  datasetIds,
}: UserDatasetSelectorProps) => {
  const {t} = useTranslation()
  const {
    updateDatasets,
    updateUserProfileResponse: {loading, data, error},
  } = useProfiles()
  const [dataset, setSelectedDataset] = useState(``)

  useEffect(() => {
    if (data && !error) {
      setContext({
        context: EditUserContext.DEFAULT,
      })
    }
  }, [data, error, setContext])

  return (
    <>
      <Modal.Header>{t`settings.users.edit.datasetSelect.header`}</Modal.Header>
      <Modal.Body>
        <div
          className={`
            max-h-[--max-height-var-1]
            overflow-y-scroll
          `}
          style={{
            '--max-height-var-1': `75vh`,
          }}
        >
          <DatasetsInSchemaTable
            fieldName="datasetId"
            schemaId={schema}
            invalidSchemaMsg={t`settings.users.edit.datasetSelect.invalidSchemaMsg`}
            noDatasetsMsg={t`settings.users.edit.datasetSelect.noDatasetsMsg`}
            setSelected={datasetId => setSelectedDataset(datasetId.toString())}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ActionGroup>
          <Button
            variant="text"
            onClick={() =>
              setContext({
                context: EditUserContext.DEFAULT,
              })
            }
          >
            {t`settings.users.edit.datasetSelect.cancel`}
          </Button>
          <Button
            variant="primary"
            disabled={!dataset}
            loading={loading}
            onClick={() => {
              updateDatasets({
                profileId,
                amzCognitoPoolId,
                datasetIds: [...datasetIds, dataset],
              })
            }}
          >
            {t`settings.users.edit.datasetSelect.confirm`}
          </Button>
        </ActionGroup>
      </Modal.Footer>
    </>
  )
}

export default UserDatasetSelector
