import {useId} from 'react'
import ReactSelect, {GroupBase, Props} from 'react-select'
import Creatable from 'react-select/creatable'
import {FormItem} from '../FormItem'
import {NativeSelect, NativeSelectOption} from './NativeSelect/NativeSelect'
import {OurVirtualMenuList} from './OurMenuList/OurMenuList'
import {ourSelectComponents, usePortalTarget} from './helpers'
import {BaseSelectProps, OptionData} from './types'

export const styles = {
  menuPortal: (base = {}) => ({...base, zIndex: 9999}),
}

function Select<
  Option = OptionData,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  noOptionsLabel = `No options`,
  disabled,
  inlineActions,
  readOnly,
  label = `Select an option`,
  details,
  labelHidden,
  findCurrentByProperty,
  inModal,
  openDirection = `down`,
  virtualList,
  isSearchable = false,
  isClearable = false,
  isCreatable = false,
  id: inputId,
  placeholder = `Select...`,
  options = [],
  value,
  isMulti,
  components = {},
  variants = [],
  ...rest
}: Props<Option, IsMulti, Group> & BaseSelectProps) {
  const passProps = {
    placeholder: placeholder?.toString(),
    isSearchable,
    isClearable,
    inputId,
    styles,
    options,
    label,
    openDirection,
    virtualList,
    readOnly,
    variants,
    // @ts-ignore - TODO: awaiting PR merge to fix this in react-select https://github.com/JedWatson/react-select/pull/5860
    isAppleDevice: false,
    ...rest,
  }

  const {target} = usePortalTarget()
  const fallbackStableId = useId()

  const mergedComponents = {
    ...ourSelectComponents,
    MenuList: virtualList ? OurVirtualMenuList : ourSelectComponents?.MenuList,
    // override any of the above components if the user has passed in their own…
    ...components,
  }

  const SelectTag = isCreatable ? Creatable : ReactSelect

  const isometricId = `${inputId ?? fallbackStableId}-select`

  return (
    <FormItem
      label={
        labelHidden ? null : (
          <FormItem.Label htmlFor={inputId} dim={readOnly}>
            {label}
          </FormItem.Label>
        )
      }
      details={details}
    >
      <SelectTag
        {...passProps}
        unstyled
        id={isometricId}
        instanceId={isometricId}
        isDisabled={disabled}
        isMulti={isMulti}
        value={value}
        aria-label={label}
        // @ts-ignore - TODO: Fix it! This is just a hack
        isOptionDisabled={(option: OptionData): boolean =>
          option.disabled || false
        }
        // @ts-ignore - TODO: Fix it! This is just a hack
        components={mergedComponents}
        noOptionsMessage={() => noOptionsLabel}
        menuPortalTarget={inModal ? target : undefined}
        menuPosition={inModal ? `fixed` : `absolute`}
      />
      {inlineActions}
    </FormItem>
  )
}

Select.Native = NativeSelect
Select.NativeOption = NativeSelectOption
export default Select
