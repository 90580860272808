import {z} from 'zod'

export const dashboardUrlSchema = z.object({
  dashboardUrl: z
    .string()
    .url()
    .refine(
      async url => {
        try {
          const response = await fetch(`${url}/index.js`, {method: `HEAD`})
          return response.ok
        } catch {
          return false
        }
      },
      {
        message: `Invalid URL. The URL must point to a template source directory.`,
      }
    ),
})

export type DashboardUrlFormSchema = z.infer<typeof dashboardUrlSchema>
