import {LayoutAppHeader} from '@myadbox/nebula-layouts'
import {I18nextProvider} from 'react-i18next'
import useProfile from '../../../hooks/useProfile'
import i18n from '../../../locales/i18n'
import {HeaderProps} from '../HeaderLayout'
import Support from './Support/Support'

const IndexHeader = ({tours, ...props}: HeaderProps) => {
  const profile = useProfile()

  return (
    <LayoutAppHeader
      headerMenu={
        <I18nextProvider i18n={i18n}>
          <Support user={profile} tours={tours} />
        </I18nextProvider>
      }
      {...props}
    />
  )
}

export default IndexHeader
