import {Text} from '@myadbox/stellar-ui'
import {ReactNode} from 'react'

export interface Props {
  text: ReactNode
  children?: ReactNode
  prepend?: ReactNode
  append?: ReactNode
}

export const PageActionHeader = ({children, text, prepend, append}: Props) => {
  return (
    <div
      data-component="PageActionHeader"
      className={`
        mb-4
        flex
        items-center
        justify-between
      `}
    >
      <div className={`flex min-h-[31px] items-center`}>
        {prepend}
        {typeof text === `string` ? (
          <Text variant="subHeading">{text}</Text>
        ) : (
          text
        )}
        {append}
      </div>
      <div className="relative top-1 -mt-1 mb-1 flex min-h-[39px] gap-1 ">
        {children}
      </div>
    </div>
  )
}

export default PageActionHeader
