import {getToggledClassName} from '@myadbox/toggler-ui'
import classNames from 'classnames'
import fileSaver from 'file-saver'
import {ReactElement, cloneElement} from 'react'
import {CsvDataError} from './types'

export {getToggledClassName}

// Returns function to be used as Children.map callback
export const createLastChildClassName =
  (lastClassName: string, children) => (child: ReactElement, i: number) => {
    const {
      props: {className: passedClassName = ``},
    } = child

    const className = classNames(passedClassName, {
      [lastClassName]: i < children.length - 1,
    })

    return cloneElement(child, {
      ...child.props,
      className,
    })
  }

export const isEmptyObject = (obj: unknown): boolean =>
  obj && Object.keys(obj).length === 0 && obj.constructor === Object

export const isBrowser = () => {
  return (
    typeof window !== `undefined` &&
    `localStorage` in window &&
    `location` in window
  )
}

export const downloadErrorTextFile = (
  errors: CsvDataError,
  name: string
): void => {
  const data = new Blob([JSON.stringify(errors)], {
    type: `text/csv;charset=utf-8`,
  })
  const filename = `${name}.txt`
  fileSaver.saveAs(data, filename)
}
