import {InformationCircleIcon} from '@heroicons/react/24/outline'
import {
  Button,
  Card,
  CarsalesIcon,
  FacebookIcon,
  GoogleAdsIcon,
  InstagramIcon,
  Text,
} from '@myadbox/stellar-ui'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import SocialActiveModal from './SocialActiveModal'

interface SocialActiveCardProps {
  platform: string
  isConnected: boolean
  integrationId?: string
  isSubmitting: boolean
  handleDisconnect: (integrationId: string) => void
}

const Icon = ({platform, size}: {platform: string; size: number}) => {
  switch (platform) {
    case `instagram`:
      return <InstagramIcon size={size} />
    case `facebook`:
      return <FacebookIcon size={size} />
    case `googleAds`:
      return <GoogleAdsIcon size={size} />
    case `carsales`:
      return <CarsalesIcon size={size} />
    default:
      return <InformationCircleIcon />
  }
}

const SocialActiveCard: React.FC<SocialActiveCardProps> = ({
  platform,
  isConnected,
  integrationId,
  isSubmitting,
  handleDisconnect,
}) => {
  const {t} = useTranslation(`socialActiveConnections`)

  const [isModalOpen, setIsModalOpen] = useState(false)

  if (!isConnected) return null

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)
  const confirmDisconnect = () => {
    handleDisconnect(integrationId ?? ``)
    closeModal()
  }

  return (
    <>
      <Card>
        <div className="items center flex flex-row gap-3 p-5">
          <Icon size={40} platform={platform} />
          <div className="flex flex-row items-center">
            <Text variant="body">{t(`label.${platform}`)}</Text>
          </div>
          <div className="flex flex-1 flex-row justify-end">
            <Button
              variant="secondary"
              type="button"
              disabled={isSubmitting}
              onClick={openModal}
            >
              {t(`disconnectBtn`)}
            </Button>
          </div>
        </div>
      </Card>
      {isModalOpen && (
        <SocialActiveModal
          isOpen={isModalOpen}
          onClose={closeModal}
          onConfirm={confirmDisconnect}
          platform={platform}
        />
      )}
    </>
  )
}

export default SocialActiveCard
