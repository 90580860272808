import dayjs from 'dayjs'
import {SupportedLocales, localeMap, shortLocaleFormats} from '../../util/dates'

export const isSameDay = (date: Date, start: Date): boolean => {
  return dayjs(date).isSame(dayjs(start), `day`)
}

export const isSameTime = (time: Date, start: Date): boolean => {
  return dayjs(time).format(`HH:mm`) === dayjs(start).format(`HH:mm`)
}

export const getInitialStartDateTime = (
  startDateTime: string | Date,
  locale: SupportedLocales,
  newDate: Date,
  showTime: boolean
): Date => {
  if (typeof startDateTime === `string`) {
    const format = shortLocaleFormats[showTime ? `dateAndTime` : `date`][locale]
    return dayjs(startDateTime, format, localeMap[locale]).toDate()
  }
  return startDateTime
}
