import {useLazyQuery, useMutation, useQuery} from '@apollo/client'
import {useCallback} from 'react'
import {blankUser} from '../../../../placeholders'
import {FETCH_TEAMS_BY_ACCOUNT} from '../../../teams/queries'
import {deconstructFullname} from '../../helpers'
import {
  BULK_UPDATE_PROFILES,
  CREATE_USER_PROFILE,
  DEMOTE_TO_USER,
  DISABLE_USER_PROFILE,
  PROMOTE_TO_ADMIN,
  RESET_PASSWORD,
  SET_STATUS,
  UPDATE_AVATAR,
  UPDATE_USER_PROFILE,
  VERIFY_EMAIL,
} from '../../mutations'
import {
  FETCH_CURRENT_USER_PROFILE,
  FETCH_MORE_USERS,
  FETCH_USERS_BY_ACCOUNT,
  FETCH_USER_PROFILES,
  PROFILE_STATS,
  SEARCH_USERS,
} from '../../queries'
import {BulkUpdateProfilesMutator, UseProfilesOutput} from '../../types'
import {updateOnProfileCreate} from '../../updaters'

export const useProfiles = (): UseProfilesOutput => {
  const {data: dataCurrent} = useQuery(FETCH_CURRENT_USER_PROFILE)

  const [fetchAllProfiles, allProfilesResult] = useLazyQuery(
    FETCH_USERS_BY_ACCOUNT
  )

  const [lazySearchUsers, searchUsersResult] = useLazyQuery(SEARCH_USERS)
  const searchUsers = useCallback(
    (keywords: string) => {
      lazySearchUsers({variables: {keywords}})
    },
    [lazySearchUsers]
  )

  const [lazyFetchProfilesByIds, profilesByIdsResult] =
    useLazyQuery(FETCH_USER_PROFILES)
  const fetchProfilesByIds = useCallback(
    (userIds: string[]) => lazyFetchProfilesByIds({variables: {userIds}}),
    [lazyFetchProfilesByIds]
  )

  const [doUpdateUserProfile, updateUserProfileResponse] =
    useMutation(UPDATE_USER_PROFILE)
  const updateName = useCallback(
    ({profileId, amzCognitoPoolId, fullName}) => {
      const {givenName, familyName} = deconstructFullname(fullName)
      return doUpdateUserProfile({
        // TODO: fix incorrect assumption about given/family name order
        variables: {
          profileId,
          params: {
            amzCognitoPoolId,
            attributes: {givenName, familyName},
          },
        },
      })
    },
    [doUpdateUserProfile]
  )

  const [doBulkUpdateProfiles, bulkUpdateProfilesResponse] =
    useMutation(BULK_UPDATE_PROFILES)
  const bulkUpdateProfiles: BulkUpdateProfilesMutator = useCallback(
    params => {
      return doBulkUpdateProfiles({
        variables: {params},
        refetchQueries: [
          {
            query: FETCH_TEAMS_BY_ACCOUNT,
          },
        ],
      })
    },
    [doBulkUpdateProfiles]
  )

  const updateEmail = useCallback(
    ({profileId, amzCognitoPoolId, email}) => {
      return doUpdateUserProfile({
        variables: {
          profileId,
          params: {
            amzCognitoPoolId,
            attributes: {email},
          },
        },
      })
    },
    [doUpdateUserProfile]
  )

  const [doVerifyEmail, verifyEmailResponse] = useMutation(VERIFY_EMAIL)
  const verifyEmail = useCallback(
    (accessToken, code) => {
      return doVerifyEmail({
        variables: {accessToken, code},
      })
    },
    [doVerifyEmail]
  )

  const updateColourScheme = useCallback(
    ({profileId, amzCognitoPoolId, darkMode}) => {
      return doUpdateUserProfile({
        variables: {
          profileId,
          params: {
            amzCognitoPoolId,
            attributes: {darkMode},
          },
        },
      })
    },
    [doUpdateUserProfile]
  )

  const updateLanguage = useCallback(
    ({profileId, amzCognitoPoolId, language}) => {
      return doUpdateUserProfile({
        variables: {
          profileId,
          params: {
            amzCognitoPoolId,
            attributes: {language},
          },
        },
      })
    },
    [doUpdateUserProfile]
  )

  const updateTimezone = useCallback(
    ({profileId, amzCognitoPoolId, timezone}) => {
      return doUpdateUserProfile({
        variables: {
          profileId,
          params: {
            amzCognitoPoolId,
            attributes: {timezone},
          },
        },
      })
    },
    [doUpdateUserProfile]
  )

  const updateDatasets = useCallback(
    ({profileId, amzCognitoPoolId, datasetIds, keywords}) => {
      const refetchQueries = keywords
        ? [
            {
              query: SEARCH_USERS,
              variables: {keywords},
            },
          ]
        : [
            {
              query: FETCH_MORE_USERS,
            },
          ]
      return doUpdateUserProfile({
        variables: {
          profileId,
          params: {
            amzCognitoPoolId,
            attributes: {datasetIds},
          },
        },
        refetchQueries,
      })
    },
    [doUpdateUserProfile]
  )

  const [doSetStatus] = useMutation(SET_STATUS)
  const setStatus = useCallback(
    (profileId, amzCognitoPoolId, status) => {
      return doSetStatus({
        variables: {profileId, amzCognitoPoolId, status},
      })
    },
    [doSetStatus]
  )

  const [doCreateUserProfile, createUserProfileResult] =
    useMutation(CREATE_USER_PROFILE)
  const createUserProfile = useCallback(
    ({fullName, email, amzCognitoPoolId}) => {
      const {givenName, familyName} = deconstructFullname(fullName)
      return doCreateUserProfile({
        variables: {
          params: {
            amzCognitoPoolId,
            attributes: {givenName, familyName, email},
          },
        },
        update: updateOnProfileCreate(),
        refetchQueries: [{query: FETCH_USERS_BY_ACCOUNT, variables: {}}],
      })
    },
    [doCreateUserProfile]
  )

  const [doDisableUserProfile, disableUserProfileResult] =
    useMutation(DISABLE_USER_PROFILE)
  const disableUserProfile = useCallback(
    ({userId, transferredTo, amzCognitoPoolId, keywords}) => {
      const refetchQueries = keywords
        ? [
            {
              query: SEARCH_USERS,
              variables: {keywords},
            },
          ]
        : [
            {
              query: FETCH_MORE_USERS,
            },
          ]
      return doDisableUserProfile({
        variables: {
          params: {
            userId,
            amzCognitoPoolId,
            transferredTo,
          },
        },
        refetchQueries,
      })
    },
    [doDisableUserProfile]
  )

  const [doUpdateAvatar] = useMutation(UPDATE_AVATAR)
  const updateAvatar = useCallback(
    (profileId, avatarCloudinaryId, avatar) => {
      return doUpdateAvatar({
        variables: {profileId, avatarCloudinaryId, avatar},
      })
    },
    [doUpdateAvatar]
  )

  const [doResetPassword, resetPasswordResponse] = useMutation(RESET_PASSWORD)
  const resetPassword = useCallback(
    (userId, amzCognitoPoolId) => {
      return doResetPassword({
        variables: {userId, amzCognitoPoolId},
      })
    },
    [doResetPassword]
  )

  const [doPromoteToAdmin, promoteToAdminResponse] =
    useMutation(PROMOTE_TO_ADMIN)
  const promoteToAdmin = useCallback(
    profileId => {
      return doPromoteToAdmin({
        variables: {profileId},
      })
    },
    [doPromoteToAdmin]
  )

  const [doDemoteToUser, demoteToUserResponse] = useMutation(DEMOTE_TO_USER)
  const demoteToUser = useCallback(
    profileId => {
      return doDemoteToUser({
        variables: {profileId},
      })
    },
    [doDemoteToUser]
  )

  const [doFetchProfilesStats, fetchProfileStatsResults] =
    useLazyQuery(PROFILE_STATS)
  const fetchProfileStats = useCallback(() => {
    return doFetchProfilesStats()
  }, [doFetchProfilesStats])

  return {
    allProfilesResult,
    bulkUpdateProfiles,
    createUserProfile,
    createUserProfileResult,
    demoteToUser,
    demoteToUserResponse,
    disableUserProfile,
    disableUserProfileResult,
    fetchAllProfiles,
    fetchProfilesByIds,
    profilesByIdsResult,
    profile: dataCurrent?.profileByCurrentUser || blankUser,
    promoteToAdmin,
    promoteToAdminResponse,
    resetPassword,
    resetPasswordResponse,
    searchUsers,
    searchUsersResult,
    setStatus,
    updateName,
    updateEmail,
    updateColourScheme,
    updateLanguage,
    updateTimezone,
    updateAvatar,
    bulkUpdateProfilesResponse,
    verifyEmail,
    verifyEmailResponse,
    updateDatasets,
    updateUserProfileResponse,
    fetchProfileStats,
    fetchProfileStatsResults,
  }
}
