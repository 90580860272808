type Config = {
  publicPages: string[]
  supportedLanguages: string[]
}

const defaultConfig: Config = {publicPages: [], supportedLanguages: []}

export const createPublicPathChecker = (config = defaultConfig) => {
  const {publicPages, supportedLanguages} = config
  return (pathname: string) => {
    const page = pathname.replace(/\/$/, ``).split(`/`).pop()
    const oneOfThePages = publicPages.includes(page as string)
    const isBaseUrl =
      pathname === `/` || supportedLanguages.includes(page as string)
    return oneOfThePages || isBaseUrl
  }
}
