import {useLDClient} from 'launchdarkly-react-client-sdk'
import {ReactNode, createContext, useEffect, useMemo, useState} from 'react'

export const FeatureFlagsContext = createContext<{
  hasFeaturesInit: boolean
  featureFlags: Record<string, boolean>
} | null>(null)
FeatureFlagsContext.displayName = `FeatureFlagsContext`

interface Props<
  T = {userId?: string; email?: string; givenName?: string; familyName?: string}
> {
  children: ReactNode
  currentUser?: T
}

export const FeatureFlagsProvider = ({children, currentUser}: Props) => {
  const [hasFeaturesInit, setHasFeaturesInit] = useState(false)
  const [featureFlags, setFeatureFlags] = useState<Record<string, boolean>>({})
  const ldClient = useLDClient()

  useEffect(() => {
    if (!ldClient) return

    const user = currentUser?.userId
      ? {
          key: currentUser.userId,
          email: currentUser.email,
          firstName: currentUser.givenName,
          lastName: currentUser.familyName,
        }
      : {anonymous: true}

    ldClient
      .identify({
        ...user,
        custom: {
          accountName: window.location.hostname.split(`.`)[0],
        },
      })
      .then(flags => {
        setFeatureFlags(flags)
        setHasFeaturesInit(true)
      })
  }, [ldClient, currentUser])

  const providerValue = useMemo(
    () => ({hasFeaturesInit, featureFlags}),
    [hasFeaturesInit, featureFlags]
  )

  return (
    <FeatureFlagsContext.Provider value={providerValue}>
      {children}
    </FeatureFlagsContext.Provider>
  )
}
