import {InputFormik} from '@myadbox/nebula-formik'
import {useAccount, useProfiles} from '@myadbox/nebula-service-api'
import {
  ActionGroup,
  Banner,
  Button,
  ComplexModal as Modal,
  Toast,
} from '@myadbox/stellar-ui'
import {Form, Formik} from 'formik'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import useAsyncEffect from 'use-async-effect'
import {getValidationSchema} from './helpers'

interface FormValues {
  fullName: string
  email: string
}

interface Props {
  close: () => void
}

const INITIAL_VALUES: FormValues = {
  fullName: ``,
  email: ``,
}

const UserForm = ({close}: Props) => {
  const {t} = useTranslation()
  const {account} = useAccount()
  const {
    createUserProfile,
    createUserProfileResult: {called, data, error, loading},
  } = useProfiles()

  const amzCognitoPoolId = account?.configuration?.loginConfig?.userPoolId

  const handleSubmit = async (values: FormValues): Promise<void> => {
    await createUserProfile({...values, amzCognitoPoolId})
  }

  useAsyncEffect(
    isMounted => {
      if (called && data?.createUserProfile) {
        Toast.show(
          <Toast intent="success">
            {t(`settings.users.form.successMsg`, {
              fullName: data.createUserProfile.fullName,
            })}
          </Toast>
        )
        if (isMounted()) {
          close()
        }
      }
    },
    [data]
  )

  return (
    <Formik
      enableReinitialize
      initialValues={INITIAL_VALUES}
      validationSchema={getValidationSchema(t)}
      onSubmit={handleSubmit}
    >
      {({isSubmitting, dirty}) => (
        <>
          <Modal.Header>{t`settings.users.form.headers.create`}</Modal.Header>
          <Form name="user-form">
            <Modal.Body>
              <div
                className={`
                  grid
                  gap-8
                `}
              >
                <InputFormik
                  name="fullName"
                  label={t`settings.users.form.name`}
                  id="user-name"
                  placeholder={t`settings.users.form.namePlaceholder`}
                />
                <InputFormik
                  name="email"
                  label={t`settings.users.form.email`}
                  type="email"
                  id="user-email"
                  placeholder={t`settings.users.form.emailPlaceholder`}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <ActionGroup>
                <Button
                  type="button"
                  variant="secondary"
                  disabled={isSubmitting}
                  onClick={close}
                >{t`settings.users.form.cancel`}</Button>
                <Button
                  type="submit"
                  variant="primary"
                  disabled={isSubmitting || loading || !dirty}
                  loading={isSubmitting || loading}
                  loadingText={t`settings.users.form.creating`}
                >{t`settings.users.form.create`}</Button>
              </ActionGroup>
              {error && (
                <div className="col-span-full mt-4">
                  <Banner intent="error" fadeIn>
                    {error.message === `duplicateEmail`
                      ? t`settings.users.form.validation.email.duplicate`
                      : error.message}
                  </Banner>
                </div>
              )}
            </Modal.Footer>
          </Form>
        </>
      )}
    </Formik>
  )
}

export default UserForm
