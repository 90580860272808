import {
  useAccount,
  useAccountIntegrations,
  useIntegrations,
} from '@myadbox/nebula-service-api'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {useEffect} from 'react'

import {Banner} from '@myadbox/stellar-ui'
import {SettingsTabComponentName, SettingsViewProps} from '../helpers'
import IntegrationList from './IntegrationList'
import IntegrationView from './IntegrationView'

export const Integrations = ({viewType, id}: SettingsViewProps) => {
  const {t} = useTranslation()
  const {
    fetchIntegrations,
    fetchIntegrationsResults: {data, error, loading},
  } = useIntegrations()

  const {accountName, account} = useAccount()

  const {
    fetchAccountIntegrations,
    fetchAccountIntegrationsResults: {
      data: accountIntegrationsData,
      error: fetchAccountIntegrationsError,
    },
  } = useAccountIntegrations(accountName)

  const accountIntegrations =
    accountIntegrationsData?.accountIntegrations?.integrations ?? []
  useEffect(() => {
    fetchIntegrations()
    if (accountName) {
      fetchAccountIntegrations(accountName)
    }
  }, [
    accountName,
    fetchIntegrations,
    fetchAccountIntegrations,
    accountIntegrationsData,
  ])

  const {integrations} = data || {}
  if (!data || loading) return <div>{t`settings.integrations.loading`}</div>

  if (error || fetchAccountIntegrationsError)
    return (
      <div>
        {error && (
          <div className="mb-4">
            <Banner fadeIn intent="error">
              {t`settings.integrations.error`}
            </Banner>
          </div>
        )}
      </div>
    )

  const integration =
    id && integrations?.find(integration => integration.id === id)

  const accountIntegration = accountIntegrations?.find(
    i => i?.integrationId === integration?.id
  )
  return viewType === `view` ? (
    <IntegrationView
      integration={integration}
      accountIntegration={accountIntegration}
      accountId={account?.id}
    />
  ) : (
    <IntegrationList
      integrations={integrations}
      accountIntegrations={accountIntegrations ?? []}
      error={error}
    />
  )
}

Integrations.displayName = `Integrations` satisfies SettingsTabComponentName
export default Integrations
