import {useProfiles} from '@myadbox/nebula-service-api'
import {
  ActionGroup,
  Banner,
  Button,
  ComplexModal as Modal,
  Toast,
} from '@myadbox/stellar-ui'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {useEffect} from 'react'

interface Props {
  amzCognitoPoolId: string
  userId: string
  fullName: string
  close: () => void
  resetContext
}
const ResetConfirmation = ({
  amzCognitoPoolId,
  userId,
  fullName,
  close,
  resetContext,
}: Props) => {
  const {t} = useTranslation()
  const {
    resetPassword,
    resetPasswordResponse: {loading, data, error},
  } = useProfiles()

  useEffect(() => {
    if (data) {
      Toast.show(
        <Toast intent="success">
          {t(`settings.users.reset.success`, {user: fullName})}
        </Toast>
      )
      close()
    }
  }, [close, data, fullName, t])

  return (
    <>
      <Modal.Header>{t`settings.users.reset.header`}</Modal.Header>
      <Modal.Body>
        {t(`settings.users.reset.message`, {user: fullName})}
      </Modal.Body>
      <Modal.Footer>
        <ActionGroup>
          <Button
            type="button"
            variant="secondary"
            onClick={() => resetContext()}
          >{t`settings.users.reset.cancel`}</Button>
          <Button
            type="submit"
            variant="primary"
            onClick={() => resetPassword(userId, amzCognitoPoolId)}
            loading={loading}
            loadingText={t`settings.users.reset.loading`}
          >{t`settings.users.reset.approve`}</Button>
        </ActionGroup>
        {error && (
          <div className="col-span-full mt-4">
            <Banner intent="error" fadeIn>
              {error.message === `ssoUserRestriction`
                ? t`settings.users.reset.errors.ssoUserRestriction`
                : error.message}
            </Banner>
          </div>
        )}
      </Modal.Footer>
    </>
  )
}

export default ResetConfirmation
