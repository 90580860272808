import {
  Button,
  ErrorMessageStyler,
  FieldError,
  Input,
  ToastIntent,
} from '@myadbox/stellar-ui'

import {useTranslation} from 'gatsby-plugin-react-i18next'
import {Controller, useForm} from 'react-hook-form'

import {zodResolver} from '@hookform/resolvers/zod'
import {UseAccountOutput} from '@myadbox/nebula-service-api'
import {useEffect, useState} from 'react'
import {DashboardUrlFormSchema, dashboardUrlSchema} from './helper'

type Props = {
  accountData: UseAccountOutput
  onSuccess?: (message: string, intent?: ToastIntent) => void
}

const resolver = zodResolver(dashboardUrlSchema)

const DashboardUrlUpdateForm = ({accountData, onSuccess}: Props) => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const {t} = useTranslation(`settings`, {
    keyPrefix: `settings.account.dashboard`,
  })

  const {
    account,
    updateAccountConfiguration,
    updateAccountConfigurationResponse,
  } = accountData

  const dashboardUrl = account?.configuration?.dashboardUrl

  const {handleSubmit, control, formState} = useForm<DashboardUrlFormSchema>({
    defaultValues: {dashboardUrl},
    resolver,
  })

  const {loading, error, data: updateData} = updateAccountConfigurationResponse

  const successMessage = t(`successMessage`)
  const showSuccess =
    isSubmitted && !loading && !error && updateData.updateAccountConfiguration

  useEffect(() => {
    if (showSuccess) {
      onSuccess?.(successMessage)
      setIsSubmitted(false)
    }
  }, [successMessage, showSuccess, onSuccess])

  const handleOnSubmit = (data: DashboardUrlFormSchema) => {
    setIsSubmitted(true)
    updateAccountConfiguration(data)
  }

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <Controller
        name="dashboardUrl"
        control={control}
        render={({field}) => (
          <Input
            {...field}
            id={field.name}
            label={t`label`}
            inlineActions={
              <div className="shrink-0">
                <Button disabled={formState.isSubmitting}>{t`save`}</Button>
              </div>
            }
            endContent={
              formState.errors.dashboardUrl && (
                <ErrorMessageStyler>
                  <FieldError>
                    <span>{formState.errors.dashboardUrl.message}</span>
                  </FieldError>
                </ErrorMessageStyler>
              )
            }
          />
        )}
      />
    </form>
  )
}

export default DashboardUrlUpdateForm
