import {stripTrailingFileName} from '@myadbox/string-utils'
import {Cloudinary} from 'cloudinary-core'
import {Template} from '../../types'

export const getHostedUrl = (
  info: Template,
  cloudName: string
): string | null => {
  if (!info?.publicId) return null
  const cloudinaryRaw = new Cloudinary({
    cloud_name: cloudName,
    secure: true,
    resource_type: `raw`,
  })
  return stripTrailingFileName(cloudinaryRaw.url(info.publicId))
}
