import {CsvDataError} from '@myadbox/gatsby-theme-nebula/types'
import {BulkUpdateAssetParams} from '@myadbox/nebula-service-api'
import {startsWithSpecialChar} from '@myadbox/string-utils'
import {TFunction} from 'i18next'

const headersMap = {
  Name: `name`,
  Description: `description`,
  Tags: `tags`,
  'Expiry in UTC': `expiry`,
  Terms: `terms`,
  Categories: `categoryNames`,
  Notes: `notes`,
  'Asset ID': `id`,
  'Data Record Ids': `datasetIds`,
}

export const transformHeaders = (headerLine: string): string => {
  const headers = headerLine.split(`,`)

  const newHeaders = headers
    .map(h => headersMap[h.replace(/"/g, ``)] || h)
    .join(`,`)

  return newHeaders
}

export const MAX_ROWS_LIMIT = 1000 // ? Should this configurable via ENV?

export const validateData = (
  data: Record<string, string>[],
  t: TFunction
): CsvDataError => {
  const requiredFields = [`id`]
  const allFields = Object.values(headersMap)
  const errors = {}

  if (data.length > MAX_ROWS_LIMIT) {
    errors[`limit`] = `settings.account.assetData.csvUploader.validator.limit`
    return errors
  }

  data.forEach((row, index) => {
    const rowErrors = {}

    const fieldsDiff = requiredFields.filter(x => !Object.keys(row).includes(x))
    if (fieldsDiff.length) {
      rowErrors[index] =
        t`settings.account.assetData.csvUploader.validator.missing` +
        ` - ` +
        fieldsDiff
    }
    Object.keys(row).forEach(col => {
      const field = allFields.find(h => h === col)
      if (!field) {
        rowErrors[
          col
        ] = t`settings.account.assetData.csvUploader.validator.invalid`
        return
      }

      if (startsWithSpecialChar(row[col])) {
        rowErrors[
          col
        ] = t`settings.users.bulkActions.csvUploader.validator.startsWithSpecialChar`
        // eslint-disable-next-line array-callback-return
        return
      }

      const error =
        requiredFields.includes(field) && !row[col]
          ? t`settings.account.assetData.csvUploader.validator.required`
          : null

      if (error) {
        rowErrors[field] = error
      }
    })
    if (Object.keys(rowErrors).length) {
      errors[`row${index}`] = rowErrors
    }
  })
  return errors
}

export const convertAssetData = (
  assetData: Record<string, string>[]
): BulkUpdateAssetParams[] => {
  const data = []

  assetData.forEach(row => {
    const rowData = {}
    rowData[`id`] = row[`id`].trim()
    rowData[`name`] = row[`name`]?.trim()
    rowData[`description`] = row[`description`]?.trim()
    rowData[`tags`] = row[`tags`]?.split(`,`).filter(Boolean)
    rowData[`notes`] = row[`notes`]?.trim()
    rowData[`datasetIds`] = row[`datasetIds`]?.split(`,`).filter(Boolean)
    rowData[`categoryNames`] = row[`categoryNames`]?.split(`,`).filter(Boolean)

    if (
      row[`expiry`] ||
      row[`terms`] ||
      // in case of clearing terms/ expiry
      row[`expiry`] === `` ||
      row[`terms`] === ``
    ) {
      rowData[`terms`] = {}
      rowData[`terms`][`expiry`] =
        row[`expiry`]?.trim() === `` ? null : row[`expiry`]?.trim()
      rowData[`terms`][`details`] = row[`terms`]?.trim()
    }
    data.push(rowData)
  })

  return data
}
