import {Team} from '@myadbox/nebula-service-api'
import {Avatar, ComplexModal as Modal, Table, Text} from '@myadbox/stellar-ui'
import {useTranslation} from 'gatsby-plugin-react-i18next'

interface Props {
  userName?: string
  teams: Team[]
}

const UserTeamsModal = ({userName = `User`, teams = []}: Props) => {
  const {t} = useTranslation()

  const maxTeams = 4
  const visibleTeams = teams.slice(0, maxTeams)
  const hiddenTeamsNumber = teams.length - maxTeams
  const title = t(`settings.users.userTeams.header`, {userName})

  if (visibleTeams.length === 0) {
    return <Text color="inherit">{t`settings.users.userTeams.none`}</Text>
  }

  return (
    <Modal
      accessibleTitle={title}
      trigger={({open}) => (
        <button
          onClick={() => open()}
          className={`-my-1 ml-4 flex items-center`}
          aria-label={title}
          // button having min size fixes flakiness in CI e2e tests
          style={{
            minHeight: `1em`,
            minWidth: `1em`,
          }}
        >
          {visibleTeams.map(({id, avatar, name}, i) => (
            <div
              key={id}
              className={`-ml-4`}
              style={{
                zIndex: maxTeams - i,
              }}
            >
              <Avatar bordered size="sm" src={avatar} title={name} team />
            </div>
          ))}
          {hiddenTeamsNumber > 0 && (
            <div className={`whitespace-nowrap`}>
              <Text tag="span" variant="bodySmall" color="textSecondary">
                +{hiddenTeamsNumber}
              </Text>
            </div>
          )}
        </button>
      )}
    >
      <Modal.Header>{title}</Modal.Header>
      <Modal.Body>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.HeadCell>{t`settings.users.userTeams.name`}</Table.HeadCell>
              <Table.HeadCell>{t`settings.users.userTeams.members`}</Table.HeadCell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {teams.map(({id, avatar, name, userIds}) => (
              <Table.Row key={id}>
                <Table.Cell verticalAlign="middle">
                  <div className={`flex items-center gap-2`}>
                    <Avatar src={avatar} title={name} team />
                    {name}
                  </div>
                </Table.Cell>
                <Table.Cell verticalAlign="middle">
                  {userIds?.length}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Modal.Body>
    </Modal>
  )
}

export default UserTeamsModal
