import {cn} from '@myadbox/stellar-ui'
import {components, MenuProps} from 'react-select'

const upClassName = `!top-auto !bottom-100%`

export const OurMenu = ({className = ``, ...props}: MenuProps) => {
  return (
    <components.Menu
      {...props}
      className={cn(
        `
          bg-ui-50
          rounded-400
          dark:shadow-lg-dense
          dark:bg-ui-200
          absolute
          left-0
          !z-40
          w-full
          shadow-lg
        `,
        // @ts-ignore because this component is deprecated
        props.selectProps[`openDirection`] === `up` && upClassName,
        className
      )}
    />
  )
}
