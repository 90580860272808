import {createContext, useContext} from 'react'
import {raise} from '../../util'
import {ValidSizeClassname} from './types'

export const DropdownTriggerSizeContext = createContext<
  ValidSizeClassname | ``
>(``)

export function useTriggerSizeContext(ctx = DropdownTriggerSizeContext) {
  const sizeCls = useContext(ctx)

  return (
    sizeCls ??
    raise(`Must be used under \`DropdownTriggerSizeContext.Provider\``)
  )
}
