import {HTMLAttributes} from 'react'

type Props = HTMLAttributes<HTMLElement>

const SidebarInternal = ({className = ``, children, ...props}: Props) => {
  return (
    <aside
      id="sidebar"
      className={`
        ${className}
        until-md:bg-ui-200
        until-md:gap-4
        dark:until-md:bg-ui-100
        relative
        min-w-0
        grow-0
        md:basis-[--cell-min-width]
      `}
      {...props}
    >
      <div
        className={`
          lap:after:content
          lap:after:h-[4.5rem]
          box-content
          grid
          content-start
          gap-8
          overflow-y-auto
          px-4
          py-10
          md:fixed
          md:max-h-[calc(var(--viewport-height)-9.6rem)]
          md:w-[--cell-min-width]
          md:pl-8
          md:pr-4
        `}
      >
        {children}
      </div>
    </aside>
  )
}

export default SidebarInternal
