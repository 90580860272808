import {useIsMounted} from '@myadbox/bag-o-hooks'
import {useAppOptions} from '@myadbox/nebula-app-options'
import {TheWrap as Wrap} from '@myadbox/nebula-layouts'
import {Image} from '@myadbox/stellar-ui'
import {useCognito} from '../CognitoProvider'
import FormLayout from '../FormLayout'

type Props = {
  children: React.ReactNode
}

const TheLayout = ({children}: Props) => {
  const {primaryColor, loginBackgroundFileUrl} = useAppOptions()
  const {account, isConfigured} = useCognito()
  const isMounted = useIsMounted()
  const unmountedClassName = isMounted() && isConfigured ? `` : `hidden`
  const loginBackground =
    account?.configuration?.loginBackgroundUrl || loginBackgroundFileUrl
  return (
    <Wrap
      className={`
        border-l-6
        border-brand
        dark-mode
        relative
        bg-black
        py-8
        text-sm
        font-normal
      `}
      style={{
        '--primary-color': primaryColor,
      }}
    >
      {isConfigured && loginBackground && (
        <div
          className={`
            absolute
            top-0
            z-0
            h-full
            w-full
          `}
          /*
          TODO: Review after implementation
          style={{ opacity: `0.${10 - loginBackgroundOverlay}` }}
          */
        >
          <Image
            alt=""
            className={`
              animate-fade-in-slow
              pointer-events-none
              absolute
              object-cover
            `}
            style={{
              height: `100%`,
              // TODO: Review after implementation
              // objectPosition: loginBackgroundPosition,
            }}
            src={loginBackground}
          />
        </div>
      )}
      <FormLayout
        className={`
            ${unmountedClassName}
            relative
            z-20
          `}
      >
        {children}
      </FormLayout>
    </Wrap>
  )
}

export default TheLayout
