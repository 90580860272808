import {slugifyText} from '@myadbox/string-utils'
import {ElementType} from 'react'
import type {HasConditionalIcon} from '../ConditionalIcon/ConditionalIcon'
import {ConditionalIcon} from '../ConditionalIcon/ConditionalIcon'

export interface Props extends HasConditionalIcon {
  to: string
  passData?: NonNullable<unknown>
  label?: string
  isActive?: boolean
  as: ElementType
}

export const anchorClassName = `
  xl:py-6
  md:h-18
  hover:bg-ui-100
  items-center
  flex
  flex-col
  justify-center
  py-3
  p-2
  w-full
`

export const activeClassName = `
  hover:bg-ui-100
  bg-ui-100
  text-ui-500
`
export const inActiveIconClassName = `text-ui-400 dark:text-ui-300`

export const IconTab = ({
  to,
  passData = {},
  label = ``,
  isActive,
  defaultIcon,
  activeIcon,
  as,
}: Props) => {
  if (!to) return null
  const slug = slugifyText(label)
  const anchorClassNameAndActive = isActive
    ? `${anchorClassName} ${activeClassName}`
    : anchorClassName

  const Tag = as
  return (
    <div>
      <Tag
        to={to}
        title={label}
        aria-labelledby={`tab-label-${slug}`}
        className={anchorClassNameAndActive}
        state={passData}
      >
        <ConditionalIcon
          current={isActive}
          activeIcon={activeIcon}
          defaultIcon={defaultIcon}
          className={`
            h-5 w-5
            shrink-0
            md:h-6
            md:w-6
            ${isActive ? `` : inActiveIconClassName}
          `}
        />
        <span
          id={`tab-label-${slug}`}
          className={`
            text-ui-600
            dark:text-ui-400
            text-tiny
            titlecase
            block
            text-center
            font-normal
          `}
        >
          {label}
        </span>
      </Tag>
    </div>
  )
}

export default IconTab
