import {HTMLAttributes, ReactNode} from 'react'
import {LoaderIcon} from '../Icons/index'
import {StaticIcon} from '../StaticIcon'

interface Props extends HTMLAttributes<HTMLElement> {
  loading?: boolean
  onRemove?: (args: unknown) => void
  children: ReactNode
}

const Tag = ({children, onRemove, loading, ...props}: Props) => {
  return (
    <span
      className={`
        bg-ui-200
        rounded-400
        text-ui-700
        dark:text-ui-500
        leading-3.5
        relative
        inline-flex
        items-center
        px-2
        py-1.5
        text-xs
        font-light
      `}
      {...props}
    >
      {children}
      {onRemove && (
        <button
          type="button"
          onClick={onRemove}
          aria-label="Remove"
          disabled={loading}
          className={`
            hocus:bg-ui-300
            hocus:text-ui-900
            -my-1
            -mr-1.5
            ml-0.5
            rounded-full
            p-1
          `}
        >
          {loading ? (
            <LoaderIcon size={14} />
          ) : (
            <StaticIcon name="x" width={14} height={14} />
          )}
        </button>
      )}
    </span>
  )
}

export default Tag
