import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  TableHTMLAttributes,
  TdHTMLAttributes,
  createContext,
  useContext,
  useDebugValue,
  useState,
} from 'react'
import {cn} from '../../util'
import {Text} from '../Text/Text'
interface TableContext {
  contrast: boolean
  horizontal: boolean
}

type TableProps = Partial<TableContext> & TableHTMLAttributes<HTMLTableElement>

export const TablePropsContext = createContext<TableContext | null>(null)
TablePropsContext.displayName = `Table TablePropsContext`

export const DispatchContext = createContext<Dispatch<
  SetStateAction<TableContext>
> | null>(null)
DispatchContext.displayName = `Table DispatchContext`

export const TableProvider = ({
  children,
  initialState = {
    horizontal: false,
    contrast: false,
  },
}: PropsWithChildren<{initialState: TableContext}>) => {
  const [variants, setVariants] = useState(initialState)
  return (
    <TablePropsContext.Provider value={variants}>
      <DispatchContext.Provider value={setVariants}>
        {children}
      </DispatchContext.Provider>
    </TablePropsContext.Provider>
  )
}

const Table = ({
  children,
  horizontal = false,
  contrast = false,
  ...props
}: TableProps) => {
  const className = cn(
    `
      text-left
      text-sm
      w-full
    `,
    {
      'border-t-ui-300 dark:border-t-ui-200': contrast,
      'border-t-ui-200 dark:border-t-ui-50': !contrast,
      'table-fixed table border-t': horizontal,
    }
  )
  return (
    <TableProvider initialState={{contrast, horizontal}}>
      <table {...props} className={className}>
        {horizontal && (
          <colgroup data-testid="colgroup">
            <col width="30%" />
            <col width="70%" />
          </colgroup>
        )}
        {children}
      </table>
    </TableProvider>
  )
}

export const useTableProps = (): TableContext | null => {
  useDebugValue(`useTableVariant`)
  const ctx = useContext(TablePropsContext)
  return ctx
}

type TableHeadProps = {
  sticky?: boolean
} & TableHTMLAttributes<HTMLTableSectionElement>

const TableHead = ({
  children,
  className = ``,
  sticky,
  style = {},
  ...props
}: TableHeadProps) => {
  return (
    <thead
      className={`
        ${className}
        ${sticky ? `sticky` : ``}
      `}
      style={{
        ...style,
        insetBlockStart: 0,
      }}
      {...props}
    >
      {children}
    </thead>
  )
}

const TableBody = ({
  children,
}: TableHTMLAttributes<HTMLTableSectionElement>) => {
  return <tbody className={`w-full table-fixed`}>{children}</tbody>
}

const TableRow = ({
  children,
  className: passedClassName,
  ...props
}: TableHTMLAttributes<HTMLTableRowElement>) => {
  const tableProps = useTableProps()
  const className = cn(
    passedClassName,
    `border-b -mx-1 animate-fade-in top-px relative [&>*]:pr-2`,
    tableProps?.contrast
      ? `border-b-ui-300 dark:border-b-ui-200`
      : `border-b-ui-200 dark:border-b-ui-50`
  )
  return (
    <tr className={className} {...props}>
      {children}
    </tr>
  )
}

const alignments = {
  baseline: `align-baseline`,
  middle: `align-middle`,
  top: `align-top`,
  bottom: `align-bottom`,
} as const

type VerticalAlign = keyof typeof alignments
type TableCellProps = {
  verticalAlign?: VerticalAlign
  padding?: `tight` | `normal`
} & TdHTMLAttributes<HTMLTableCellElement>

const paddings = {
  tight: `py-0.5`,
  normal: `py-2`,
} as const

const TableCell = ({
  children,
  className = ``,
  verticalAlign = `baseline`,
  padding = `normal`,
  ...props
}: TableCellProps) => {
  const verticalAlignClassName =
    alignments[verticalAlign] || alignments.baseline
  const paddingClassName = paddings[padding] || paddings.normal
  return (
    <td
      className={`
        ${className}
        ${verticalAlignClassName}
        ${paddingClassName}
        overflow-auto
        break-words
      `}
      {...props}
    >
      <div className={`max-h-16`}>{children}</div>
    </td>
  )
}

type TableHeadCellProps = {
  variant?: `loud` | `quiet`
} & TableHTMLAttributes<HTMLTableCellElement>

const TableHeadCellLoud = ({
  children,
  className = ``,
  ...props
}: TableHeadCellProps) => {
  return (
    <th
      className={`${className} whitespace-nowrap py-3 align-baseline`}
      {...props}
    >
      <Text variant="caption" tag="span" className={`-my-px`}>
        {children}
      </Text>
    </th>
  )
}

const TableHeadCellQuiet = ({
  children,
  className = ``,
  ...props
}: TableHeadCellProps) => {
  return (
    <th
      className={`${className} text-ui-600 dark:text-ui-500 py-0.5 pr-4 align-baseline text-xs font-light leading-[21px]`}
      {...props}
    >
      {children}
    </th>
  )
}

const cellVariants = {
  loud: TableHeadCellLoud,
  quiet: TableHeadCellQuiet,
}
const TableHeadCell = ({
  variant = `loud`,
  children,
  ...props
}: TableHeadCellProps) => {
  const Component = cellVariants[variant] || cellVariants.loud
  return <Component {...props}>{children}</Component>
}

Table.Head = TableHead
Table.HeadCell = TableHeadCell
Table.Body = TableBody
Table.Row = TableRow
Table.Cell = TableCell

export default Table
export {Table}
