import {AccountBilling, SendInvoiceMutator} from '@myadbox/nebula-service-api'
import {
  ActionGroup,
  Button,
  ComplexModal as Modal,
  Toast,
} from '@myadbox/stellar-ui'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {useEffect} from 'react'
import {hasBillingSetup} from '../helpers'

export type SendInvoiceConfirmationModalProps = {
  account: AccountBilling
  createAndSendInvoice: SendInvoiceMutator
  createAndSendInvoiceData: string
  loading: boolean
  error: string
}

const SendInvoiceConfirmationModal = (
  props: SendInvoiceConfirmationModalProps
) => {
  const {t} = useTranslation()
  const {
    account,
    createAndSendInvoice,
    createAndSendInvoiceData,
    loading,
    error,
  } = props

  const handleInvoicing = (close: () => void) => {
    createAndSendInvoice()
    close()
  }

  useEffect(() => {
    if (!loading && !error && createAndSendInvoiceData)
      Toast.show(
        <Toast intent="success">
          {t(`settings.billing.accountBilling.confirmation.successMessage`)}
        </Toast>
      )
  }, [loading, error, createAndSendInvoiceData, t])

  return (
    <Modal
      trigger={({open}) => (
        <Button
          variant="primary"
          disabled={
            !hasBillingSetup(account.billing) || account.billing.autoBilling
          }
          onClick={open}
          size="sm"
        >
          {t`settings.billing.accountBilling.generateInvoice`}
        </Button>
      )}
    >
      {({close}) => (
        <>
          <Modal.Header>{t`settings.billing.accountBilling.confirmation.header`}</Modal.Header>
          <Modal.Body>{t`settings.billing.accountBilling.confirmation.message`}</Modal.Body>
          <Modal.Footer>
            <ActionGroup>
              <Button type="button" variant="text" onClick={close}>
                {t`settings.billing.accountBilling.confirmation.cancel`}
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={(): void => handleInvoicing(close)}
                loading={loading}
              >
                {t`settings.billing.accountBilling.confirmation.submit`}
              </Button>
            </ActionGroup>
          </Modal.Footer>
        </>
      )}
    </Modal>
  )
}

export default SendInvoiceConfirmationModal
