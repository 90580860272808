import type {UserSession} from '@myadbox/nebula-auth-utils'
import {useContext, useDebugValue} from 'react'
import {UserSessionContext} from './UserSessionProvider'

export const useUserSession = () => {
  const userSession = useContext<UserSession>(UserSessionContext)

  if (!userSession) {
    throw new Error(`useUserSession must be used within a UserSessionProvider`)
  }

  useDebugValue(`User session: ${userSession}`)

  return userSession
}

export default useUserSession
