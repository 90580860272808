import {Asset} from '@myadbox/nebula-service-api'
import {ComplexModal as Modal} from '@myadbox/stellar-ui'
import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import withTranslationProvider from '../../../locales/withTranslationProvider'
import {toPascalCase} from '../../../utils/helpers'
import SocialsIntegrationsConnect from '../SocialsIntegrationsConnect'
import SocialsIntegrationsForm from '../SocialsIntegrationsForm'
import {useSocialsIntegrations, useSocialsModalStore} from '../hooks'

const SocialsIntegrationsModal = ({assets}: {assets: Asset[]}) => {
  const contentState = useSocialsModalStore(state => state.contentState)
  const setContentState = useSocialsModalStore(state => state.setContentState)
  const isModalOpen = useSocialsModalStore(state => state.isModalOpen)
  const reset = useSocialsModalStore(state => state.reset)
  const integrationType = useSocialsModalStore(state => state.integrationType)

  const {connectedIntegration} = useSocialsIntegrations()

  const {t} = useTranslation(`socialsIntegrations`)

  useEffect(() => {
    const expiryDate = connectedIntegration
      ? new Date(connectedIntegration.user.token.expiresAt).getTime()
      : Date.now() - 1000
    if (connectedIntegration && expiryDate >= Date.now())
      setContentState(`connected`)
    else setContentState(`connect`)
  }, [connectedIntegration, setContentState])

  const renderModal = () => {
    if (contentState === `connect`)
      return <SocialsIntegrationsConnect assets={assets} />
    if (contentState === `connected`)
      return <SocialsIntegrationsForm assets={assets} />
  }
  return (
    <Modal
      controls={{isOpen: isModalOpen, onClose: reset}}
      maxWidth="md"
      id="default-integrations-modal"
    >
      <Modal.Header>
        {t(`header.${toPascalCase(integrationType)}`)}
      </Modal.Header>
      {renderModal()}
    </Modal>
  )
}

export default withTranslationProvider(SocialsIntegrationsModal)
