import {FormEvent, HTMLAttributes, useEffect, useRef, useState} from 'react'

import {ClipboardCheck, ClipboardCopy} from '@myadbox/stellar-ui'
import CodeInline from './CodeInline/CodeInline'

const fieldClassName = `truncate min-w-0`

interface CopyDetailProps extends HTMLAttributes<HTMLElement> {
  copyText?: string
  title?: string
}

const CopyDetail = ({children, copyText, title, ...props}: CopyDetailProps) => {
  const inputRef = useRef(null)
  const timerRef = useRef(null)
  const [isCopied, setIsCopied] = useState(false)

  const handleClick = (e: FormEvent): void => {
    e.preventDefault()
    if (isCopied) return
    clearTimeout(timerRef.current)
    inputRef.current.select()
    document?.execCommand(`copy`)
    window?.getSelection()?.removeAllRanges()
    setIsCopied(true)
    timerRef.current = setTimeout(() => {
      setIsCopied(false)
    }, 2000)
  }

  useEffect(() => (): void => clearTimeout(timerRef.current), [])

  if (!children) return null
  return (
    <CodeInline
      onClick={handleClick}
      className={`
        ${isCopied ? `text-ui-800` : `text-inherit`}
        relative
        pl-5
      `}
      title={title}
      {...props}
    >
      {isCopied ? (
        <>
          <ClipboardCheck
            className={`
              absolute
              left-0
              top-[50%]
              inline-block
              h-4
              w-4
              translate-y-[-50%]
              text-green-400
            `}
          />
          <span className={fieldClassName}>Copied to clipboard!</span>
        </>
      ) : (
        <>
          <ClipboardCopy
            className={`
              absolute
              left-0
              top-[50%]
              inline-block
              h-4
              w-4
              translate-y-[-50%]
              opacity-50
              group-hover:opacity-100
            `}
          />
          <button
            aria-label={title}
            type="button"
            className={`${fieldClassName}`}
          >
            {children}
          </button>
          <input
            aria-label="Copy text"
            ref={inputRef}
            id="copy-asset-id-text"
            className={`
              sr-only
            `}
            value={`${copyText || children}`}
            autoCorrect="off"
            spellCheck={false}
            size={25}
            readOnly
          />
        </>
      )}
    </CodeInline>
  )
}

export default CopyDetail
