import {Button} from '@myadbox/stellar-ui'
import {useTranslation} from 'react-i18next'
import withTranslationProvider from '../../locales/withTranslationProvider'
import DefaultModal from './DefaultModal'
import ErrorModal from './ErrorModal'
import useGoogleSheetsModalStore from './hooks/useGoogleSheetsModalStore'

const GoogleSheetsAuthModal = ({integrationsId}: {integrationsId: string}) => {
  const {t} = useTranslation(`googleSheetIntegrations`)
  const modalState = useGoogleSheetsModalStore(state => state.modalState)
  const setGoogleModalOpen = useGoogleSheetsModalStore(
    state => state.setModalOpen
  )
  const setIntegrationsId = useGoogleSheetsModalStore(
    state => state.setIntegrationsId
  )
  const renderModal = () => {
    if (modalState === `default`) {
      return <DefaultModal />
    } else if (modalState === `error`) {
      return <ErrorModal />
    }
  }
  return (
    <>
      <Button
        variant="primary"
        onClick={() => {
          setGoogleModalOpen()
          setIntegrationsId(integrationsId)
        }}
      >
        {t(`button.enable`)}
      </Button>
      {renderModal()}
    </>
  )
}

export default withTranslationProvider(GoogleSheetsAuthModal)
