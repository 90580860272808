import {InputHTMLAttributes, ReactElement, ReactNode} from 'react'
import ReactCurrencyInput, {
  CurrencyInputProps as ReactCurrencyInputProps,
} from 'react-currency-input-field'
import {FormItem} from '../FormItem'

// @ts-ignore - this needs fixing
type onChangeParams = Parameters<ReactCurrencyInputProps[`onValueChange`]>

export interface CurrencyInputProps
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    `size` | `defaultValue` | `step`
  > {
  id: string
  label: string | ReactNode
  details?: ReactNode
  name: string
  className?: string
  htmlSize?: number
  variant?: `sm` | `md`
  inlineActions?: ReactElement
  labelHidden?: boolean
  readOnly?: boolean
  // @ts-ignore - this needs fixing
  currency: ReactCurrencyInputProps[`intlConfig`][`currency`]
  // @ts-ignore - this needs fixing
  locale: ReactCurrencyInputProps[`intlConfig`][`locale`]
  allowDecimals?: boolean
  onValueChange?: ({
    value,
    name,
    values,
  }: {
    value?: onChangeParams[0]
    name?: onChangeParams[1]
    values?: onChangeParams[2]
  }) => void
  defaultValue?: ReactCurrencyInputProps[`defaultValue`]
  step?: ReactCurrencyInputProps[`step`]
}

// NOTE: inputs must retain `form-input` class
export const inputClasses = `
  focus:shadow-outline
  focus:outline-none
  rounded-400
  border
  block
  form-input
  duration-150
  ease-in-out
  transition-default
  w-full
`

export const enabledClassName = `
  focus:bg-white
  hover:bg-white
  hocus:text-ui-1000
  bg-ui-50
  text-ui-800
  dark:hocus:bg-ui-200
  dark:bg-ui-200
  dark:border-ui-200
`

export const disabledClassName = `
  bg-ui-200
  border-ui-200
  text-ui-500
  dark:text-ui-300
  dark:bg-ui-50
  dark:border-ui-50
  cursor-not-allowed
`

export const sizesClassName = {
  md: `
    sm:text-sm
    sm:leading-5
    px-3
    py-2
  `,
  sm: `
    sm:text-xs
    sm:leading-5
    px-2
    py-1
  `,
}

export const getCurrencyInputClassName = (
  props: Partial<CurrencyInputProps>
) => {
  return `
    ${inputClasses}
    ${sizesClassName[props.variant || `md`]}
    ${props.className || ``}
    ${props.disabled || props.readOnly ? disabledClassName : enabledClassName}
  `
}

const CurrencyInput = ({
  label,
  details,
  name,
  id,
  labelHidden,
  inlineActions,
  variant = `md`,
  readOnly,
  currency = `AUD`,
  locale = `en-AU`,
  allowDecimals = false,
  maxLength = 6,
  onValueChange,
  className,
  ...props
}: CurrencyInputProps) => {
  if (!(name && label && id)) return null
  const classNames = `
    ${getCurrencyInputClassName({...props, readOnly, variant, className})}
  `

  return (
    <FormItem
      label={
        labelHidden ? null : (
          <FormItem.Label htmlFor={id} dim={readOnly}>
            {label}
          </FormItem.Label>
        )
      }
      details={details}
    >
      <div
        className={`
          flex
          gap-4
        `}
      >
        {/* @ts-ignore - this needs fixing */}
        <ReactCurrencyInput
          onValueChange={(value, name, values) =>
            // @ts-ignore - this needs fixing
            onValueChange({value, name, values})
          }
          allowDecimals={allowDecimals}
          allowNegativeValue={false}
          type="text"
          name={name}
          id={id}
          className={classNames}
          maxLength={maxLength}
          inputMode="numeric"
          intlConfig={{
            locale,
            currency,
          }}
          readOnly={readOnly}
          aria-label={labelHidden ? `${label}` : null}
          {...props}
        />
        {inlineActions}
      </div>
    </FormItem>
  )
}

CurrencyInput.getCurrencyInputClassName = getCurrencyInputClassName
export default CurrencyInput
