import {
  CloudinaryUploadWidget,
  WidgetOptions,
} from '@myadbox/cloudinary-upload-widget'
import {useAppOptions} from '@myadbox/nebula-app-options'
import {useLoginAccount} from '@myadbox/nebula-service-api'
import {useI18next} from 'gatsby-plugin-react-i18next'
import {useMemo} from 'react'

type Props = {
  className?: string
  showWarning?: boolean
  options?: Omit<WidgetOptions, `folder` | `cloudName`>
  banner?: React.ReactNode
  triggerClassname?: string
} & Omit<React.ComponentProps<typeof CloudinaryUploadWidget>, `options`>

/**
 * A wrapper around the CloudinaryUploadWidget component that adds specific features for nebula
 */
const CloudinaryUploader = ({
  className,
  triggerClassname,
  showWarning = false,
  ...props
}: Props) => {
  const {cloudName} = useAppOptions()
  const {account} = useLoginAccount()
  const {language} = useI18next()
  const accountName = account?.accountName
  const uploadPreset = account?.configuration?.aiTagsEnabled
    ? process.env.GATSBY_CLOUDINARY_UPLOAD_AI_TAGGING_PRESET
    : process.env.GATSBY_CLOUDINARY_UPLOAD_PRESET

  const options = useMemo(
    () => ({
      ...props.options,
      multiple: props.options?.multiple ?? true,
      cropping: props.options?.cropping ?? false,
      resourceType: props.options?.resourceType ?? `auto`,
      cloudName,
      language,
      folder: accountName,
      uploadPreset,
    }),
    [props.options, cloudName, language, accountName, uploadPreset]
  )

  return (
    <div className={className}>
      <CloudinaryUploadWidget
        className={triggerClassname}
        options={options}
        successHandler={props.successHandler}
        closeHandler={props.closeHandler}
        trigger={props?.trigger}
        createTriggerHandlers={props.createTriggerHandlers}
      />
      {props.banner}
    </div>
  )
}

export default CloudinaryUploader
