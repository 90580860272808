import {CheckCircleIcon} from '@heroicons/react/20/solid'
import {CheckIcon} from '@heroicons/react/24/outline'
import {Asset} from '@myadbox/nebula-service-api'
import {
  ActionGroup,
  Button,
  FormItem,
  Input,
  Modal,
  RadioButton,
  Text,
  TextArea,
} from '@myadbox/stellar-ui'
import {useEffect, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {default as withTranslationProvider} from '../../../locales/withTranslationProvider'
import {toTitleCase} from '../../../utils/helpers'
import {useSocialsIntegrations, useSocialsModalStore} from '../hooks'
import {SocialsIntegrationFormValues} from '../types'
import {
  disableForCarsales,
  findAdFormatId,
  inLinkages,
  isCarsales,
  isGoogleAds,
  transformPayload,
  urlRegex,
} from './helpers'

const SocialsIntegrationsForm = ({assets}: {assets: Asset[]}) => {
  const {t} = useTranslation(`socialsIntegrations`)
  const integrationType = useSocialsModalStore(state => state.integrationType)
  const reset = useSocialsModalStore(state => state.reset)
  const {connectedIntegration, exportSocial, exportResponse} =
    useSocialsIntegrations()
  const {data, loading, error} = exportResponse
  const [pageId, setPageId] = useState<string>(``)

  const {
    control,
    formState: {isValid},
    handleSubmit,
    watch,
    register,
    setValue,
  } = useForm<SocialsIntegrationFormValues>({
    defaultValues: {
      page: ``,
      asset: assets[0],
      message: ``,
      user: `Sesimi User`,
      carSales: {
        campaignId: ``,
        ctaUrl: ``,
        adName: ``,
        adFormatId: ``,
      },
    },
  })

  useEffect(() => {
    if (connectedIntegration) {
      setValue(`page`, connectedIntegration?.apps[0]?.id)
      setValue(`user`, connectedIntegration?.user?.name)
    }
  }, [connectedIntegration, setValue])

  useEffect(() => {
    if (isCarsales(integrationType))
      setValue(`carSales.adFormatId`, findAdFormatId(assets[0]))
  }, [assets, integrationType, setValue])

  useEffect(() => {
    if (pageId === ``) setPageId(connectedIntegration?.apps[0]?.id ?? ``)
  }, [connectedIntegration, pageId])

  useEffect(() => {
    if (data?.createImagePost && !error) {
      setTimeout(reset, 1500)
      window.location.reload()
    }
  }, [data, error, reset])

  const onSubmit = async (values: SocialsIntegrationFormValues) => {
    exportSocial(
      transformPayload(values, integrationType, connectedIntegration)
    )
  }

  const renderForm = () =>
    !isCarsales(integrationType) ? (
      <>
        <div>
          <Text variant="bodySmall" color="textSecondary">
            {t(`form.loginAs`)}
          </Text>
          <Text>{connectedIntegration?.user?.name ?? `Sesimi User`}</Text>
        </div>
        <FormItem
          label={
            <FormItem.Label>
              {t(`form.pages.${isGoogleAds(integrationType)}`)}
            </FormItem.Label>
          }
        >
          <div>
            {connectedIntegration?.apps.map(app => (
              <label
                key={app.id}
                className="flex flex-row items-center gap-2 py-1"
              >
                <Controller
                  name="page"
                  control={control}
                  render={({field}) => (
                    <RadioButton
                      name="page"
                      id={app.id}
                      value={app.id}
                      checked={watch(`page`) === app.id}
                      disabled={inLinkages(assets[0], app.id, integrationType)}
                      onChange={e => {
                        field.onChange(e.target.value)
                        setPageId(app.id)
                      }}
                      required
                    />
                  )}
                />
                <Text>
                  {app.name}
                  {isGoogleAds(integrationType) && ` (${app.id})`}
                </Text>
                {inLinkages(assets[0], app.id, integrationType) && (
                  <CheckCircleIcon width={20} height={20} />
                )}
              </label>
            ))}
          </div>
        </FormItem>
        {isGoogleAds(integrationType) ? (
          <div />
        ) : (
          <Controller
            name="message"
            control={control}
            render={({field}) => (
              <TextArea
                placeholder={`Message`}
                id="message"
                name="message"
                label={t(`form.enterMessage.${isGoogleAds(integrationType)}`)}
                onChange={e => setValue(`message`, e.target.value)}
              />
            )}
          />
        )}
      </>
    ) : (
      <>
        {!disableForCarsales(assets[0], integrationType) && (
          <Text className="text-red-500">{t(`form.disableForCarsales`)}</Text>
        )}
        <Input
          id="campaignId"
          label="Campaign ID"
          type="number"
          {...register(`carSales.campaignId`, {
            required: true,
            maxLength: 19,
            minLength: 19,
          })}
        />
        <Input
          id="ctaUrl"
          label="Call to Action URL"
          type="text"
          {...register(`carSales.ctaUrl`, {
            required: true,
            pattern: {
              value: urlRegex,
              message: `Enter a valid URL`,
            },
          })}
        />
        <Input
          id="adName"
          label="Ad Name"
          type="text"
          {...register(`carSales.adName`, {required: true})}
        />
      </>
    )

  return (
    <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
      <Modal.Content>
        <div className="flex flex-col gap-4">{renderForm()}</div>
      </Modal.Content>
      <Modal.Footer>
        <div className="flex flex-col gap-4">
          {inLinkages(assets[0], pageId, integrationType) && (
            <Text>{t(`form.inLinkages`)}</Text>
          )}
          {error && (
            <div className="mb-2 text-red-600">
              <Text color="inherit">{error.message}</Text>
            </div>
          )}
          <ActionGroup>
            {!loading && data?.createImagePost ? (
              <div
                className={`
          flex
          items-center
          justify-center
          gap-1
          text-green-600
        `}
              >
                <CheckIcon width={24} height={24} />
                <Text>{data.createImagePost.message}</Text>
              </div>
            ) : (
              <>
                <Button variant="secondary" type="button" onClick={reset}>
                  {t(`form.cancel`)}
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  id={`socials-integrations-submit`}
                  disabled={
                    loading ||
                    inLinkages(assets[0], pageId, integrationType) ||
                    !isValid ||
                    !disableForCarsales(assets[0], integrationType)
                  }
                  loading={loading}
                  loadingText={t`form.updating`}
                >
                  {t(`form.share.${isGoogleAds(integrationType)}`, {
                    integration: toTitleCase(String(integrationType)),
                  })}
                </Button>
              </>
            )}
          </ActionGroup>
        </div>
      </Modal.Footer>
    </form>
  )
}

export default withTranslationProvider(SocialsIntegrationsForm)
