import {gql} from '@apollo/client'
import {ASSET_VERSIONS} from './fragments'

export const ASSETS_BY_IDS = gql`
  ${ASSET_VERSIONS}
  query AssetsByIds($assetIds: [String!]!) @api(name: "gateway") {
    assetsByIds(assetIds: $assetIds) {
      id
      createdAt
      type
      name
      tags
      autoTags
      description
      notes
      derivedFrom {
        id
        name
      }
      accesses {
        id
        name
        userId
        teamId
        accessLevel
        validFrom
        validTo
      }
      createdBy {
        userId
        fullName
      }
      linkages {
        type
        apps {
          appId
          refAssetId
          cloudinaryId
        }
      }
      terms {
        details
        expiry
      }
      datasets {
        id
        data
        nestedSchema {
          id
          title
          name
          descendants {
            title
            name
            type
          }
        }
      }
      categories {
        id
        title
        parent
        active
        ancestors {
          id
          title
          parent
        }
      }
      terms {
        details
        expiry
      }
      stats {
        views
        downloads
      }
      ...AssetVersions
      approvers {
        approverId
        approverType
      }
      approvalUrl
    }
  }
`

export const ASSET_URL_WITH_COLOR_PROFILE = gql`
  query AssetUrlWithColorProfile($assetId: String!, $colorProfile: String!)
  @api(name: "assets") {
    assetUrlWithColorProfile(assetId: $assetId, colorProfile: $colorProfile)
  }
`

export const BULK_ASSETS_EXPORT = gql`
  query BulkAssetsExport @api(name: "assets") {
    bulkAssetsExport
  }
`

export const ASSET_STATS_OBJ = gql`
  query Stats @api(name: "assets") {
    stats
  }
`

export const ASSET_TAGS = gql`
  query AssetTags @api(name: "assets") {
    assetTags
  }
`

export const SHARE_LINK = gql`
  query ShareLink($id: String!) @api(name: "assets") {
    shareLink(id: $id) {
      id
      assetIds
    }
  }
`
