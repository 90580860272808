import {Dispatch, useEffect, useRef, useState} from 'react'

// doing this is suspect in React, it causes hydration mismatch
const isBrowser = () => {
  return (
    typeof window !== `undefined` &&
    `localStorage` in window &&
    `location` in window
  )
}

export const useLocalStorage = <T>(
  key: string,
  defaultValue: T,
  {serialize = JSON.stringify, deserialize = JSON.parse} = {}
): [T, Dispatch<T>] => {
  const [state, setState] = useState(() => {
    if (!isBrowser()) return defaultValue

    const savedValue = window?.localStorage?.getItem(key)
    const deserialisedValue = savedValue ? deserialize(savedValue) : null
    if (deserialisedValue) return deserialisedValue

    if (defaultValue instanceof Function) return defaultValue()
    return defaultValue
  })

  const prevKeyRef = useRef(key)

  useEffect(() => {
    if (!key) return

    const valueInLocalStorage = window.localStorage.getItem(key)
    const newValue = valueInLocalStorage
      ? deserialize(valueInLocalStorage)
      : defaultValue
    // setState(newValue)
    if (newValue) setState(newValue)
  }, [defaultValue, deserialize, key])

  useEffect(() => {
    if (key && prevKeyRef.current !== key) {
      window.localStorage.removeItem(prevKeyRef.current)
    }
    if (preventSettingNull<T>(key, state, defaultValue)) return
    prevKeyRef.current = key
    window.localStorage.setItem(key, serialize(state || defaultValue))
  }, [key, state, serialize, defaultValue])

  return [state, setState]
}

export const preventSettingNull = <T>(
  key: string,
  state: T,
  defaultValue: T
) => {
  return !key || !(state || defaultValue)
}
