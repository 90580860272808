import type {CognitoAuthToken} from '@myadbox/nebula-auth-utils'

export type Environment = `development` | `local` | `production` | `uat`

export const PROD_STARGATE_URL = `https://stargate-nebula.netlify.app/`

const WEBPACK_STARGATE_URL = `https://stargate-webpack.netlify.app/`

// using this in React is almost always a bad idea
export const isBrowser = () => {
  return (
    typeof window !== `undefined` &&
    `localStorage` in window &&
    `location` in window
  )
}

const TITAN_PORT = 3099

export const allowedStargateUrls = [
  PROD_STARGATE_URL,
  WEBPACK_STARGATE_URL,
  `http://localhost/`,
  `http://localhost:9000/`,
  `http://localhost:4200/`,
  `http://localhost:8888/`,
] as const

export type AllowedStargateUrl = (typeof allowedStargateUrls)[number]

export const isAllowedStargateUrl = (
  url: string
): url is AllowedStargateUrl => {
  return allowedStargateUrls.some(
    allowedUrl => typeof url === `string` && url.startsWith(allowedUrl)
  )
}

export const getStargateUrl = (requestedUrl = ``): AllowedStargateUrl => {
  if (isAllowedStargateUrl(requestedUrl)) {
    return requestedUrl
  }

  return PROD_STARGATE_URL
}

/**
 * @deprecated Use `Environment` instead.
 */
type DeprecatedEnvironment = `dev` | `prod`

export const stargateUrl: Record<
  Environment | DeprecatedEnvironment,
  AllowedStargateUrl
> = {
  uat: PROD_STARGATE_URL,
  development: PROD_STARGATE_URL,
  local: PROD_STARGATE_URL,
  production: PROD_STARGATE_URL,
  dev: PROD_STARGATE_URL,
  prod: PROD_STARGATE_URL,
} as const

type GetCoreAppUrlParams<T = NonNullable<unknown>> = {
  domain: string
  product: string
  token: T
  lang?: string
}

export const getCoreAppUrl = ({
  domain,
  product,
  token,
  lang = `en`,
}: GetCoreAppUrlParams<CognitoAuthToken>): string => {
  if (!domain) return `/`
  if (!token) return `${domain}/${product}`

  const params = buildParams(token, product, lang)
  return new URL(`${domain}/nebula_login?${params}`).href
}

type GetTitanAppUrlParams = {
  protocol?: string
  hostname?: string
  jwt: string
  locale: string
  returnToUrl?: string
}

export const getTitanAppUrl = ({
  protocol = `https:`,
  hostname = `localhost`,
  jwt,
  locale,
  returnToUrl = ``,
}: GetTitanAppUrlParams): string => {
  const params: {
    accessToken: string
    lng: string
    returnToUrl?: string
  } = {
    accessToken: jwt,
    lng: locale,
  }

  if (returnToUrl) params.returnToUrl = returnToUrl

  const searchParams = new URLSearchParams(params).toString()

  if (isLocalhost(hostname))
    return new URL(
      `${protocol}//${hostname}:${TITAN_PORT}/validate?${searchParams}`
    ).href

  const accountName = hostname.split(`.`)[0]
  const primaryDomain = hostname.split(`.`).slice(-2).join(`.`)

  return new URL(
    `${protocol}//${accountName}.compliance.${primaryDomain}/validate?${searchParams}`
  ).href
}

const getLanguageLocale = (language: string): string => language.split(`-`)[0]

export function isLocalhost(hostname: string): boolean {
  const LOCAL_DOMAINS = [`localhost`, `127.0.0.1`]
  return LOCAL_DOMAINS.includes(hostname)
}

export const buildParams = (
  token: CognitoAuthToken,
  product: string,
  language: string
): string => {
  if (!token) return ``

  const lang = getLanguageLocale(language)

  const params = new URLSearchParams({
    access_token: token.accessToken?.jwtToken || ``,
    refresh_token: token.refreshToken?.token || ``,
    exp: token.accessToken?.payload?.exp?.toString() || ``,
    redirect_url: `/${product}`,
    lang,
  })

  return params.toString().replace(/%2F/g, `/`)
}
