import {Squares2X2Icon, TableCellsIcon} from '@heroicons/react/24/outline'
import {SEO} from '@myadbox/gatsby-theme-nebula'
import {useProfile} from '@myadbox/gatsby-theme-nebula/hooks'
import {PageActionHeader} from '@myadbox/nebula-layouts'
import {useDatasets, useProfiles} from '@myadbox/nebula-service-api'
import {
  Banner,
  Button,
  Input,
  ComplexModal as Modal,
  TableEmptyState,
  TableLoadingState,
} from '@myadbox/stellar-ui'
import {useI18next} from 'gatsby-plugin-react-i18next'
import {useDeferredValue, useEffect, useMemo, useState} from 'react'
import SchemaForm from '../SchemaForm'
import SchemaCardList from './SchemaCardList'
import SchemaTableList from './SchemaTableList'
import {filterSchemas, getProfileMap, getUsernameById} from './helpers'

const SchemaList = () => {
  const {t} = useI18next()
  const {timezone} = useProfile()
  const [isTableView, setIsTableView] = useState(false)
  const [search, setSearch] = useState(``)

  const deferredSearch = useDeferredValue(search)

  const {
    fetchSchemas,
    fetchSchemasResult: {loading, error, data},
  } = useDatasets()

  const {
    fetchProfilesByIds,
    profilesByIdsResult: {
      loading: profilesLoading,
      error: profilesError,
      data: profilesData,
    },
  } = useProfiles()

  useEffect(() => {
    fetchSchemas(true)
  }, [fetchSchemas])

  useEffect(() => {
    if (!data?.nestedSchemas) return

    const createdByUserIds = data.nestedSchemas.map(
      schema => schema.createdById
    )
    fetchProfilesByIds(createdByUserIds)
  }, [data?.nestedSchemas, fetchProfilesByIds])

  const profileMap = useMemo(
    () => getProfileMap(profilesData?.profilesByUserIds),
    [profilesData?.profilesByUserIds]
  )

  const filteredSchemas = filterSchemas(data?.nestedSchemas, deferredSearch)

  const ListComponent = isTableView ? SchemaTableList : SchemaCardList

  return (
    <>
      <SEO title="Schemas" />
      <PageActionHeader text={t`settings.schemas.subHeading`}>
        <Button
          size="sm"
          slim
          variant="text"
          onClick={() => setIsTableView(!isTableView)}
          title="Toggle view"
        >
          {isTableView ? (
            <Squares2X2Icon width={18} height={18} />
          ) : (
            <TableCellsIcon width={18} height={18} />
          )}
        </Button>
        <Input
          type="search"
          id="schema-search"
          name="schema-search"
          label={t`settings.schemas.list.searchLabel`}
          placeholder={t`settings.schemas.list.searchPlaceholder`}
          labelHidden
          onChange={e => setSearch(e.target.value)}
        />
        <Modal
          maxWidth="md"
          trigger={({open}) => (
            <Button variant="primary" onClick={open}>
              {t`settings.schemas.createBtnLabel`}
            </Button>
          )}
        >
          {({close}) => <SchemaForm close={close} />}
        </Modal>
      </PageActionHeader>

      {!loading && (
        <ListComponent
          schemas={filteredSchemas}
          getUsernameById={getUsernameById(profileMap)}
          timeZone={timezone}
          profilesLoading={profilesLoading}
          profilesError={profilesError}
        />
      )}

      {loading && <TableLoadingState />}
      {error && (
        <div className={`my-4`}>
          <Banner intent="error">{t`settings.error`}</Banner>
        </div>
      )}
      {!loading && data?.nestedSchemas && data.nestedSchemas.length < 1 && (
        <TableEmptyState
          icon={<TableCellsIcon width={36} height={36} />}
          text={t`settings.schemas.emptyState`}
        />
      )}
    </>
  )
}

export default SchemaList
