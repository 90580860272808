import {cn} from '@myadbox/stellar-ui'
import {ReactNode} from 'react'

export const MainSidebar = ({
  children,
  className = ``,
}: {
  children: ReactNode
  className?: string
}) => {
  return (
    <div
      className={cn(
        `
        grid
        md:grid-cols-[calc(var(--cell-min-width)_+_2.25rem)_1fr]
      `,
        className
      )}
    >
      {children}
    </div>
  )
}
