import {Text} from '@myadbox/stellar-ui'
import {HTMLAttributes, ReactNode} from 'react'

interface Props extends HTMLAttributes<HTMLElement> {
  innerClassName?: string
  isMulti?: boolean
  children: ReactNode
}

// const verticalPadding = `
//   xl:py-18
//   md:py-6
//   py-4
// `

const Stage = ({
  children,
  className = ``,
  isMulti = false,
  innerClassName = ``,
}: Props) => {
  return (
    <aside
      className={`
        ${className}
        bg-ui-300
        dark:bg-ui-0
        relative
        z-0
        flex
        h-full
        min-h-0
        min-w-0
        overflow-y-auto
      `}
    >
      <div
        className={`
          ${innerClassName}
          ${isMulti ? `m-auto` : `justify-center`}
          flex
          w-full
          flex-col
        `}
      >
        {children || (
          <Text variant="headingLarge" color="textSecondary">
            Preview unavailable
          </Text>
        )}
      </div>
    </aside>
  )
}

export default Stage
