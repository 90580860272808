import {cn} from '@myadbox/stellar-ui'
import {NoticeProps, components} from 'react-select'

export const OurNoOptionsMessage = ({
  className = ``,
  ...props
}: NoticeProps) => {
  return (
    <components.NoOptionsMessage
      {...props}
      className={cn(
        `
          px-3
          py-2
          text-center
          text-sm
          font-normal
        `,
        className
      )}
    />
  )
}
