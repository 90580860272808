import {CodeBracketIcon} from '@heroicons/react/20/solid'
import {useFeatureFlags} from '@myadbox/nebula-launchdarkly'
import {
  AccountIntegration,
  ActiveIntegrationType,
  useAccount,
  useAccountIntegrations,
} from '@myadbox/nebula-service-api'
import {
  CarsalesIcon,
  FacebookIcon,
  GoogleAdsIcon,
  InstagramIcon,
} from '@myadbox/stellar-ui'
import {ReactNode, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import withTranslationProvider from '../../../locales/withTranslationProvider'
import {toPascalCase, toTitleCase} from '../../../utils/helpers'
import SocialsIntegrationsTrigger from '../SocialsIntegrationsTrigger'
import {useSocialsModalStore} from '../hooks'

interface IntegrationItem {
  type: ActiveIntegrationType
  icon: ReactNode
}

const availableIntegrations: IntegrationItem[] = [
  {type: `FACEBOOK`, icon: <FacebookIcon size={20} />},
  {type: `INSTAGRAM`, icon: <InstagramIcon size={20} />},
  {type: `GOOGLE_ADS`, icon: <GoogleAdsIcon size={20} />},
  {type: `CARSALES`, icon: <CarsalesIcon size={20} />},
  {
    type: `EMBED`,
    icon: (
      <div className="size-5">
        <CodeBracketIcon />
      </div>
    ),
  },
]

const SocialsIntegrationsList = ({isSingleVideo}: {isSingleVideo: boolean}) => {
  const {t} = useTranslation(`socialsIntegrations`)
  const {accountName} = useAccount()
  const {
    fetchAccountIntegrations,
    fetchAccountIntegrationsResults: {data: accountIntegrationsData},
  } = useAccountIntegrations(accountName)
  const {integrations} = accountIntegrationsData?.accountIntegrations ?? {}

  useEffect(() => {
    if (accountName) fetchAccountIntegrations(accountName)
  }, [accountName, fetchAccountIntegrations])

  const checkIfEnabled = (type: string, integrations?: AccountIntegration[]) =>
    !!integrations?.some(
      integration =>
        integration?.integration?.name.includes(toTitleCase(type)) &&
        integration.enabled
    )

  const {
    featureFlags: {
      showGoogleAdsConnection,
      showEmbedCopy,
      showCarsalesConnection,
    },
  } = useFeatureFlags()

  const shouldIncludeIntegration = (type: string) => {
    if (isSingleVideo) return type === `EMBED`
    if (!showEmbedCopy && type === `EMBED`) return false
    if (!showGoogleAdsConnection && type === `GOOGLE_ADS`) return false
    if (!showCarsalesConnection || !checkIfEnabled(`CARSALES`, integrations)) {
      return type !== `CARSALES`
    }
    return true
  }

  const integrationList = availableIntegrations.filter(({type}) =>
    shouldIncludeIntegration(type)
  )

  const setModalOpen = useSocialsModalStore(state => state.setModalOpen)
  const setIntegrationType = useSocialsModalStore(
    state => state.setIntegrationType
  )

  return (
    <>
      {integrationList.map(({type, icon}) => (
        <SocialsIntegrationsTrigger
          key={type}
          text={t(`list.${toPascalCase(type)}`)}
          renderIcon={icon}
          handleSelect={() => {
            setModalOpen()
            setIntegrationType(type)
          }}
        />
      ))}
    </>
  )
}

export default withTranslationProvider(SocialsIntegrationsList)
