import {Button, ComplexModal as Modal, Text} from '@myadbox/stellar-ui'
import React from 'react'
import {useTranslation} from 'react-i18next'

interface ModalComponentProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  platform: string
}

const SocialActiveModal: React.FC<ModalComponentProps> = ({
  isOpen,
  onClose,
  onConfirm,
  platform,
}) => {
  const {t} = useTranslation(`socialActiveConnections`)
  const platformLabel = t(`label.${platform}`)
  return (
    <Modal
      controls={{isOpen, onClose}}
      maxWidth="md"
      id="disconnect-integrations-modal"
    >
      <Modal.Header>
        {t(`modal.confirmDisconnect`, {platform: platformLabel})}
      </Modal.Header>
      <Modal.Body>
        <Text variant="body">
          {t(`modal.confirmDisconnectMessage`, {platform: platformLabel})}
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex flex-row justify-end gap-3">
          <Button variant="secondary" type="button" onClick={onClose}>
            {t(`modal.cancelBtn`)}
          </Button>
          <Button variant="primary" type="button" onClick={onConfirm}>
            {t(`modal.confirmBtn`)}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default SocialActiveModal
