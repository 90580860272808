import {useDatasets} from '@myadbox/nebula-service-api'
import {
  ActionGroup,
  Banner,
  Button,
  ComplexModal as Modal,
  Text,
  Toast,
} from '@myadbox/stellar-ui'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {useEffect} from 'react'

interface Props {
  datasetId: string
  close: () => void
  schemaId: string
  isUsed: boolean
  usageText?: string
  refetchDatasets: () => void
}

export const DeleteDatasetMessage = ({
  close,
  datasetId,
  schemaId,
  isUsed,
  usageText,
  refetchDatasets,
}: Props) => {
  const {t} = useTranslation()
  const {
    deleteDataset,
    deleteDatasetResult: {data, error, loading},
  } = useDatasets()

  useEffect(() => {
    if (data) {
      refetchDatasets()
      close()
    }
    if (error) Toast.show(<Toast intent="error">{error.message}</Toast>)
  }, [data, error, close, refetchDatasets])

  return (
    <>
      <Modal.Header>{t`settings.schemas.removeDatasetsTitle`}</Modal.Header>
      <Modal.Body>
        <Text>{t`settings.schemas.removeDatasetsMsg`}</Text>
        <Text>{t`settings.schemas.removeDatasetsConfirmation`}</Text>
        <div className="pt-4">
          {isUsed ? (
            <Banner hasIcon={false} intent="warning" size="sm">
              {t(`settings.schemas.removeDatasetsUsage`, {usage: usageText})}
            </Banner>
          ) : (
            <Banner hasIcon={false} size="sm">
              {t(`settings.schemas.removeDatasetsUnused`, {usage: usageText})}
            </Banner>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ActionGroup>
          <Button
            type="button"
            variant="secondary"
            onClick={(): void => {
              close()
            }}
          >
            {t`settings.cancel`}
          </Button>
          <Button
            type="submit"
            variant="danger"
            loading={loading}
            loadingText={t`settings.deleting`}
            disabled={loading}
            onClick={() => {
              deleteDataset(datasetId)
            }}
          >
            {t`settings.removeConfirm`}
          </Button>
        </ActionGroup>
      </Modal.Footer>
    </>
  )
}

export default DeleteDatasetMessage
