import {getSegmentsWithCommandsAndOffsets} from './helpers'

type Props = {
  innerRadius?: number //percentage
  total?: number
  viewBox?: number
  radius?: number
  segments: Segment[]
  autoComplete?: boolean
  autoCompleteColour?: string
  children?: React.ReactNode
}

export interface Segment {
  value: number
  value2?: number | string
  colour?: string
  label: string
}

export interface SegmentWithCommands extends Segment {
  commands: string
  offset: number
}

export const pieDefaultColours = [
  `#2185d0`,
  `#1fb5ad`,
  `#795ece`,
  `#f2e073`,
  `#e16e6a`,
] as const

export const Pie = ({
  autoComplete = true,
  autoCompleteColour = `#4C5566`,
  innerRadius = 75,
  segments,
  total,
  viewBox = 100,
  radius = 50,
  children,
}: Props) => {
  const segmentsTotalValue = segments.reduce((prev, {value}) => prev + value, 0)
  const totalValue = total ?? segmentsTotalValue
  const innerRadiusPercentage = (innerRadius / 100) * radius
  const pieSegments =
    autoComplete && segmentsTotalValue < totalValue
      ? [
          ...segments,
          {
            value: totalValue - segmentsTotalValue,
            colour: autoCompleteColour,
            label: ``,
          },
        ]
      : [...segments]

  const segmentsWithCommands = getSegmentsWithCommandsAndOffsets(
    pieSegments,
    radius,
    viewBox,
    totalValue,
    innerRadiusPercentage
  )

  return (
    <div
      className={`
        relative
      `}
    >
      <svg
        viewBox={`0 0 ${viewBox} ${viewBox}`}
        style={{transformOrigin: `center`}}
        role="graphics-object"
      >
        {segmentsWithCommands.map((segment, index) => (
          <path
            role="graphics-symbol"
            aria-roledescription="segment"
            aria-label={segment.value.toString()}
            style={{transformOrigin: `center`}}
            key={`${index}-${segment.value.toString()}`}
            fill={segment.colour || pieDefaultColours[index]}
            d={segment.commands}
            transform={`rotate(${segment.offset})`}
          />
        ))}
      </svg>
      {children && (
        <div
          className={`
            absolute
            inset-0
            flex
            items-center
            justify-center
          `}
        >
          {children}
        </div>
      )}
    </div>
  )
}

export default Pie
