import {useLazyQuery} from '@apollo/client'
import {useCallback} from 'react'
import {FETCH_NESTEDSCHEMA_BY_NAME} from '../../queries'
import {UseNestedSchemasOutput} from '../../types'

export const useNestedSchemas = (): UseNestedSchemasOutput => {
  const [lazyFetchSchemaByName, fetchSchemaByNameResult] = useLazyQuery(
    FETCH_NESTEDSCHEMA_BY_NAME
  )
  const fetchSchemaByName = useCallback(
    (name: string) => lazyFetchSchemaByName({variables: {name}}),
    [lazyFetchSchemaByName]
  )

  return {
    fetchSchemaByName,
    fetchSchemaByNameResult,
  }
}
