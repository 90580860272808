import {CSSProperties, HTMLAttributes} from 'react'

export interface GridLayoutProps extends HTMLAttributes<HTMLElement> {
  tag?: AllowedTag
  extraStyle?: CSSProperties
}

type AllowedTag = `div` | `ul` | `ol`

const GridLayout = ({
  tag = `div`,
  children,
  className = ``,
  extraStyle = {},
  ...props
}: GridLayoutProps) => {
  const Container = tag
  const baseStyles = {
    '--grid-template-columns-var-1': `repeat(var(--repeat-type-1, auto-fill), minmax(var(--cell-min-width, min(14rem, 19vw)), 1fr))`,
    '--grid-template-columns-var-2': `repeat(auto-fit, minmax(8rem, 0.5fr))`, // using auto-fit on small screens to allow single asset results to fit available width. It's not as cool on larger screens.
    gridAutoRows: `min-content`,
  }
  return (
    <Container
      {...props}
      className={`
        ${className}
        grid
        grid-cols-[--grid-template-columns-var-2]
        gap-4
        md:gap-4
        lg:grid-cols-[--grid-template-columns-var-1]
        lg:gap-6
      `}
      style={{
        ...baseStyles,
        ...extraStyle,
      }}
    >
      {children}
    </Container>
  )
}

export default GridLayout
