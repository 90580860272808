import {Billing, Invoice} from '@myadbox/nebula-service-api'
import {Button, Table} from '@myadbox/stellar-ui'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {ReactNode} from 'react'
import {getReadableDate} from '../helpers'

export type BillingHistoryTableProps = {
  loading: boolean
  invoices: Invoice[]
  billing: Billing
}

const BillingHistoryTable = (props: BillingHistoryTableProps) => {
  const {loading, invoices, billing} = props
  const {t} = useTranslation()
  if (!invoices) {
    return null
  }

  return (
    <Table contrast data-testid="invoices">
      <Table.Head>
        <Table.Row>
          <Table.HeadCell>{t`settings.billing.invoiceTable.number`}</Table.HeadCell>
          <Table.HeadCell>{t`settings.billing.invoiceTable.amountDue`}</Table.HeadCell>
          <Table.HeadCell>{t`settings.billing.invoiceTable.amountRemaining`}</Table.HeadCell>
          <Table.HeadCell>{t`settings.billing.invoiceTable.created`}</Table.HeadCell>
          <Table.HeadCell>{t`settings.billing.invoiceTable.dueDate`}</Table.HeadCell>
          <Table.HeadCell>{t`settings.billing.invoiceTable.status`}</Table.HeadCell>
          <Table.HeadCell>{t`settings.billing.invoiceTable.invoicePdf`}</Table.HeadCell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {!loading &&
          invoices.map(
            ({
              invoiceNumber,
              amountDue,
              amountRemaining,
              created,
              dueDate,
              status,
              invoicePdf,
            }: Invoice): ReactNode => {
              return (
                <Table.Row key={invoiceNumber}>
                  <Table.Cell>{invoiceNumber}</Table.Cell>
                  <Table.Cell>
                    {t(`settings.billing.currency.${billing.currency}`, {
                      amount: amountDue / 100,
                    })}
                  </Table.Cell>
                  <Table.Cell>
                    {t(`settings.billing.currency.${billing.currency}`, {
                      amount: amountRemaining / 100,
                    })}
                  </Table.Cell>
                  <Table.Cell>{getReadableDate(created)}</Table.Cell>
                  <Table.Cell>{getReadableDate(dueDate)}</Table.Cell>
                  <Table.Cell>
                    {t(`settings.billing.invoiceStatus.${status}`)}
                  </Table.Cell>
                  <Table.Cell className="text-right">
                    <Button variant="link" href={invoicePdf} size="sm">
                      {t`settings.billing.invoiceTable.viewInvoice`}
                    </Button>
                  </Table.Cell>
                </Table.Row>
              )
            }
          )}
      </Table.Body>
    </Table>
  )
}

export default BillingHistoryTable
