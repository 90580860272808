import {isArkaroolaPage} from '@myadbox/nebula-routes'
import CognitoProvider from './src/components/CognitoProvider'
import Layout from './src/components/TheLayout'

export const wrapPageElement = ({
  element,
  props: {
    location: {pathname},
  },
}) => {
  if (isArkaroolaPage(pathname)) {
    return (
      <CognitoProvider>
        <Layout>{element}</Layout>
      </CognitoProvider>
    )
  }
}
