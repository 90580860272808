import {
  FieldError,
  TextArea as StellarTextArea,
  TextAreaProps,
} from '@myadbox/stellar-ui'
import {useField} from 'formik'
import {ErrorMessageFormikStyled} from '../ErrorMessageFormikStyled'

const TextAreaFormik = ({
  name,
  scaleErrorMessage,
  // INFO: Preserve default legacy values just in case
  noResize = true,
  size = `md`,
  ...props
}: Omit<TextAreaProps, `autoHeight`> & {
  autoHeight?: boolean
  scaleErrorMessage?: boolean
}) => {
  const [field] = useField(name)

  return (
    <StellarTextArea
      noResize={noResize}
      size={size}
      endContent={
        <ErrorMessageFormikStyled
          scaleErrorMessage={scaleErrorMessage}
          name={name}
          component={FieldError}
          data-testid={`error-${name}`}
          className={`pt-1`}
        />
      }
      {...field}
      {...props}
    />
  )
}

export default TextAreaFormik
