import {
  InputFormik,
  SelectFormik,
  SelectFormikTypes,
  ToggleInputFormik,
} from '@myadbox/nebula-formik'
import {Team, TeamInput, User, useTeams} from '@myadbox/nebula-service-api'
import {
  ActionGroup,
  Avatar,
  Banner,
  Button,
  ComplexModal as Modal,
  Text,
  Toast,
  UserCard,
} from '@myadbox/stellar-ui'
import {Form, Formik} from 'formik'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {ReactNode, useEffect, useMemo, useState} from 'react'
import {UserOption} from '../../../../utils/types'
import {getUserOptions} from '../../../../utils/userOptions'
import {
  applyDisabledBool,
  blankTeam,
  generateOptions,
  generateUserMap,
  getValidationSchema,
  removeDuplicates,
} from '../helpers'

type Props = {
  users: User[]
  team?: Team
  isNew?: boolean
  close: () => void
}

const TeamModalForm = ({users, team = blankTeam, isNew, close}: Props) => {
  const {t} = useTranslation()

  const {
    createTeam,
    createTeamResponse: {
      loading: createLoading,
      error: createError,
      data: createData,
    },
    updateTeam,
    updateTeamResponse: {
      loading: updateLoading,
      error: updateError,
      data: updateData,
    },
  } = useTeams()

  const userOptions: UserOption[] = generateOptions(users)
  const [options, setOptions] = useState<UserOption[]>(userOptions)

  useEffect(() => {
    if (team.userIds) {
      setOptions(options => applyDisabledBool(options, team.userIds))
    }
  }, [team.userIds, team.userIds.length])

  useEffect(() => {
    if (!createData?.createTeam) return

    Toast.show(
      <Toast intent="success">
        {t(`settings.teams.createSuccess`, {
          name: createData.createTeam.name,
        })}
      </Toast>
    )
    close()
  }, [close, createData, t])

  useEffect(() => {
    if (!updateData?.updateTeam) return
    Toast.show(
      <Toast intent="success">
        {t(`settings.teams.updateSuccess`, {
          name: updateData.updateTeam.name,
        })}
      </Toast>
    )
  }, [t, updateData])

  const handleSubmit = async (values: TeamInput): Promise<void> => {
    const {userIds, ...rest} = values
    const uniqueUserIds = removeDuplicates(values.userIds)
    const newUserIds = uniqueUserIds.filter(
      userId => !initialValues.userIds.includes(userId)
    )

    const removedUserIds = initialValues.userIds.filter(
      value => !uniqueUserIds.includes(value)
    )

    if (isNew) {
      await createTeam({...rest, newUserIds})
    } else {
      await updateTeam(team.id, {...rest, newUserIds, removedUserIds})
    }
  }

  const handleSelectChange = (
    option: SelectFormikTypes.SelectOption,
    values: TeamInput,
    setFieldValue: (arg1, arg2) => void
  ): void => {
    setFieldValue(`userIds`, [...values.userIds, option.id])
    setOptions(applyDisabledBool(options, [...team.userIds, option.id]))
  }

  const handleUserRemove = (
    user: User,
    values: TeamInput,
    setFieldValue: (arg1, arg2) => void
  ): void => {
    const filteredValues = values.userIds.filter(
      (userId: string) => userId !== user.userId
    )
    setFieldValue(`userIds`, filteredValues)
    setOptions(applyDisabledBool(options, filteredValues))
  }

  const initialValues = isNew
    ? {
        name: ``,
        userIds: [],
        private: false,
      }
    : {
        name: team.name,
        userIds: team.userIds,
        private: team.private,
      }

  const error = createError || updateError
  const loading = createLoading || updateLoading

  const validationSchema = getValidationSchema(t)

  const userMap = useMemo(() => generateUserMap(users), [users])

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({values, dirty, isSubmitting, setFieldValue}): ReactNode => {
        const isEmptyTeam = values.userIds?.length < 1

        return (
          <Form name="team-form">
            <Modal.Body>
              <div className={`grid gap-4`}>
                <div>
                  <label htmlFor="name">
                    <Text variant="bodySmall">{t`settings.teams.teamNameCol`}</Text>
                  </label>
                  <div className={`mt-1 flex w-full items-center`}>
                    <div>
                      <Avatar team src={team.avatar} title={team.name} />
                    </div>
                    <div className={`ml-2 w-full`}>
                      <InputFormik
                        name="name"
                        id="name"
                        label={t`settings.teams.teamNameCol`}
                        placeholder={t`settings.teams.teamNamePlaceholder`}
                        labelHidden
                      />
                    </div>
                  </div>
                </div>
                <ToggleInputFormik
                  label={t`settings.teams.privateLabel`}
                  name="private"
                  id="private"
                />
                <SelectFormik
                  name="userIds"
                  label={t`settings.teams.addUsers`}
                  id="userIds"
                  placeholder={t`settings.teams.addUsersPlaceholder`}
                  isSearchable
                  options={getUserOptions(
                    options,
                    t`settings.teams.alreadyAdded`
                  )}
                  onChange={(option: UserOption): void =>
                    handleSelectChange(option, values, setFieldValue)
                  }
                  inModal
                  virtualList={{
                    maxMenuHeight: 130,
                    itemHeight: 50,
                  }}
                />
              </div>
              <ul
                data-testid="user-list"
                className={`mt-4 list-none overflow-y-auto`}
                style={{maxHeight: 200}}
              >
                {isEmptyTeam ? (
                  <li>
                    <Banner
                      hasIcon={false}
                    >{t`settings.teams.emptyTeam`}</Banner>
                  </li>
                ) : (
                  values.userIds.map(userId => {
                    const user = userMap[userId]
                    if (!user) return null

                    return (
                      <li key={user.userId}>
                        <UserCard
                          name={user.fullName}
                          email={user.email}
                          img={user.avatar}
                          onRemove={(): void =>
                            handleUserRemove(user, values, setFieldValue)
                          }
                        />
                      </li>
                    )
                  })
                )}
              </ul>
            </Modal.Body>
            <Modal.Footer>
              <ActionGroup>
                <Button
                  type="button"
                  variant="secondary"
                  disabled={loading || isSubmitting}
                  onClick={(): void => {
                    close()
                  }}
                >
                  {t`settings.cancel`}
                </Button>
                <div style={{minWidth: `12ch`}}>
                  <Button
                    loading={loading || isSubmitting}
                    disabled={!dirty || loading || isSubmitting}
                    loadingText={t`settings.loading`}
                    type="submit"
                    variant="primary"
                  >
                    {isNew
                      ? t`settings.teams.createTeam`
                      : t`settings.teams.updateTeam`}
                  </Button>
                </div>
              </ActionGroup>
              {error && <Banner intent="error">{error?.message}</Banner>}
            </Modal.Footer>
          </Form>
        )
      }}
    </Formik>
  )
}

export default TeamModalForm
