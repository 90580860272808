import {AppOptionsProvider, useAppOptions} from '@myadbox/nebula-app-options'
import {graphql, useStaticQuery} from 'gatsby'

type PluginOptions = ReturnType<typeof useAppOptions>

export const GatsbyPluginsOptionsAdapter = ({children}) => {
  const pluginOptions = usePluginOptionsQuery()
  return (
    <AppOptionsProvider initialValue={pluginOptions}>
      {children}
    </AppOptionsProvider>
  )
}

export const usePluginOptionsQuery = (): PluginOptions => {
  const data = useStaticQuery(graphql`
    {
      allSitePlugin(
        filter: {
          name: {
            regex: "/gatsby-theme-(nebula|chevron|mimosa|acrux|arkaroola|gacrux|imai)/i"
          }
        }
      ) {
        nodes {
          pluginOptions
        }
      }
    }
  `)
  return mergedPluginOptions(data?.allSitePlugin?.nodes)
}

export const mergedPluginOptions = (nodes = []): PluginOptions =>
  nodes.reduce(
    (accumulated, node) => ({...accumulated, ...node.pluginOptions}),
    {}
  )

export const usePluginOptions = (): PluginOptions => {
  return useAppOptions()
}
