import Arrow, {ArrowProps} from './Arrow/Arrow'

const noteClassName = `
  text-ui-600
  text-xs
  font-light
  leading-5
  text-center
`

export interface PaginationArrowProps {
  left?: Partial<ArrowProps>
  right?: Partial<ArrowProps>
  className?: string
  style?: React.CSSProperties
}

interface Props {
  arrowProps?: PaginationArrowProps
  className?: string
  page?: number
  text?: {
    [key: string]: string
  }
  totalPages?: number
  muted?: boolean
  isFirstPage?: boolean
  isLastPage?: boolean
}

const defaultArrowProps = {left: {}, right: {}}

const Pagination = ({
  arrowProps = defaultArrowProps,
  className = ``,
  page = 1,
  muted,
  text = {
    showingPage: `Showing page`,
    of: `of`,
  },
  totalPages = 1,
  isFirstPage = page === 1,
  isLastPage = page === totalPages,
}: Props) => {
  const {left, right, ...rest} = arrowProps

  const passedArrowProps: {left: ArrowProps; right: ArrowProps} = {
    left: {
      direction: `left`,
      tabIndex: isFirstPage ? -1 : null,
      disabled: isFirstPage,
      ...left,
    },
    right: {
      direction: `right`,
      tabIndex: isLastPage ? -1 : null,
      disabled: isLastPage,
      ...right,
    },
  }

  return (
    <nav
      className={`
        ${className}
        ${muted ? `opacity-50` : ``}
        flex
        items-center
        justify-center
      `}
    >
      <div
        className={`
          until-md:justify-between
          z-40
          mx-20
          flex
          w-full
          shrink-0
          items-center
          justify-center
          self-stretch
        `}
      >
        <Arrow
          {...{
            ...passedArrowProps.left,
            ...rest,
          }}
        />
        <p className={`${noteClassName}`}>
          {text.showingPage}
          {` `}
          <b className={`text-ui-600 font-medium`}>{page}</b>
          {` `}
          {text.of}
          {` `}
          <b className={`text-ui-600 font-medium`}>{totalPages}</b>
        </p>
        <Arrow
          {...{
            ...passedArrowProps.right,
            ...rest,
          }}
        />
      </div>
    </nav>
  )
}

export default Pagination
