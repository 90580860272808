import {FieldError, FormItem, RangeInput} from '@myadbox/stellar-ui'
import {Field, useField} from 'formik'
import {ComponentProps} from 'react'
import {ErrorMessageFormikStyled} from '../ErrorMessageFormikStyled/ErrorMessageFormikStyled'
import './range-overrides.css'

const RangeInputFormik = ({
  label,
  details,
  name,
  id,
  labelHidden,
  readOnly,
  scaleErrorMessage,
  className,
  ...props
}: ComponentProps<typeof RangeInput>) => {
  const [field] = useField(name)
  if (!(name && label && id)) return null

  return (
    <FormItem
      label={
        labelHidden ? null : (
          <FormItem.Label htmlFor={id} dim={readOnly}>
            {label}
          </FormItem.Label>
        )
      }
      details={details}
    >
      <div
        className={`
          flex
          items-center
          gap-2
        `}
      >
        <Field
          className={`
            ${RangeInput.getInputClassName({...props, readOnly, className})}
          `}
          id={id}
          aria-label={label}
          type="range"
          style={RangeInput.inputStyles}
          {...field}
          {...props}
        />
        <div
          className={`
            dark:text-ui-500
            dark:bg-ui-200
            ctext-ui-700
            bg-ui-200
            flex
            items-center
            justify-center
            rounded-sm
            px-1
            text-xs
          `}
          style={{
            minWidth: `3em`,
          }}
        >
          {field.value}
        </div>
      </div>

      <ErrorMessageFormikStyled
        name={name}
        component={FieldError}
        data-testid={`error-${name}`}
      />
    </FormItem>
  )
}

export default RangeInputFormik
