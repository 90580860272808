import {useContext} from 'react'
import {FeatureFlagsContext} from './FeatureFlagsProvider'
type UseFeatureFlagsType = {
  hasFeaturesInit: boolean
  featureFlags: Record<string, boolean | string>
}
const useFeatureFlags = (): UseFeatureFlagsType => {
  const {hasFeaturesInit, featureFlags} = useContext(FeatureFlagsContext) || {
    hasFeaturesInit: false,
    featureFlags: {},
  }
  return {hasFeaturesInit, featureFlags}
}

export default useFeatureFlags
