import {CheckIcon} from '@heroicons/react/24/outline'
import {CheckboxFormik, InputFormik} from '@myadbox/nebula-formik'
import {NestedSchemaField, useDatasets} from '@myadbox/nebula-service-api'
import {
  ActionGroup,
  Banner,
  Button,
  ComplexModal as Modal,
  Text,
} from '@myadbox/stellar-ui'
import {FieldArray, Form, Formik, FormikProps} from 'formik'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {HTMLAttributes, ReactNode, useEffect, useState} from 'react'
import type {
  RequiredSchemaFieldFormInput,
  SchemaFieldsFormValues,
} from '../../types'
import ColumnsList from './ColumnsList'
import {
  defaultInitialValues,
  emptySchemaField,
  formValuesGenerator,
  getValidationSchema,
  handleSubmit,
} from './helpers'

interface Props extends HTMLAttributes<HTMLElement> {
  schemaId: string
  fields: NestedSchemaField[]
  close?: () => void
  refetchDatasets: () => void
}

const EditColumnsForm = ({
  schemaId,
  fields = [],
  close,
  refetchDatasets,
}: Props) => {
  const {t} = useTranslation()

  const [requiredFields, setRequiredFields] = useState<
    RequiredSchemaFieldFormInput[]
  >([])
  const {
    upsertSchemaFields,
    upsertSchemaFieldsResult: {error, data, loading},
  } = useDatasets()

  const initialValues = fields.length
    ? formValuesGenerator(fields)
    : defaultInitialValues

  useEffect(() => {
    if (data && !error) {
      setTimeout(() => {
        setRequiredFields([])
        refetchDatasets()
        close()
      }, 1000)
    }
  }, [data, close, error, refetchDatasets])

  const validationSchema = getValidationSchema(t)

  const columnsClassName = `
    grid
    grid-cols-[--grid-template-columns-var-1]
    gap-2
    items-center
  `

  const columnsStyle = {
    gridRowGap: `1rem`,
    '--grid-template-columns-var-1': `16px 2fr 1fr 3rem 25px`,
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values =>
        handleSubmit({
          values,
          initialValues,
          close,
          setRequiredFields,
          hasFields: fields.length > 0,
          upsertSchemaFields,
          schemaId,
        })
      }
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({dirty, values}: FormikProps<SchemaFieldsFormValues>): ReactNode => (
        <Form
          className={`
            row-gap:800
            grid
          `}
        >
          <Modal.Body>
            <div className={`grid gap-2`}>
              {requiredFields.length > 0 ? (
                requiredFields.map((field, index) => (
                  <div key={index}>
                    {field.type === `checkbox` ? (
                      <CheckboxFormik
                        name={`fields.${field.index}.defaultValue`}
                        id={`fields.${field.index}.defaultValue`}
                        required={field.required}
                      />
                    ) : (
                      <InputFormik
                        name={`fields.${field.index}.defaultValue`}
                        id={`fields.${field.index}.defaultValue`}
                        label={t(`settings.schemas.fieldDefaultValue`, {
                          title: field.title,
                        })}
                        required={field.required}
                        type={field.type}
                      />
                    )}
                  </div>
                ))
              ) : (
                <>
                  <div className={columnsClassName} style={columnsStyle}>
                    <div></div>
                    <Text variant="bodySmall">{t`settings.schemas.fieldTitle`}</Text>
                    <Text variant="bodySmall">{t`settings.schemas.fieldType`}</Text>
                    <Text variant="bodySmall">{t`settings.schemas.fieldRequired`}</Text>
                    <div></div>
                  </div>

                  <FieldArray name="fields">
                    {(arrayHelpers): ReactNode => (
                      <>
                        <ColumnsList
                          fields={values.fields}
                          arrayHelpers={arrayHelpers}
                          columnsClassName={columnsClassName}
                          columnsStyle={columnsStyle}
                        />
                        <button
                          type="button"
                          onClick={(): void =>
                            arrayHelpers.push(emptySchemaField)
                          }
                          aria-label={t`settings.schemas.addField`}
                          className={`
                            hover:bg-ui-300
                            rounded-400
                            dark:hover:bg-ui-200
                            col-span-full
                            border
                            border-dashed
                            p-3
                            text-sm
                            transition-all
                            duration-150
                          `}
                        >
                          + {t`settings.schemas.addField`}
                        </button>
                      </>
                    )}
                  </FieldArray>

                  {error && (
                    <div className="col-span-full mt-4">
                      <Banner intent="error" fadeIn>
                        {error.message === `duplicateSchema`
                          ? t`settings.schemas.helpers.validation.title.duplicate`
                          : error.message}
                      </Banner>
                    </div>
                  )}
                </>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <ActionGroup>
              {!error && data ? (
                <div
                  className={`
                    flex
                    items-center
                    justify-center
                    gap-1
                    text-green-600
                  `}
                >
                  <CheckIcon width={24} height={24} />
                  <Text>{t(`settings.schemas.update.successMsg`)}</Text>
                </div>
              ) : (
                <>
                  <Button variant="secondary" type="button" onClick={close}>
                    {t`settings.cancel`}
                  </Button>
                  <Button
                    variant="primary"
                    disabled={loading || !dirty}
                    loading={loading}
                    loadingText={t`settings.updating`}
                  >
                    {t`settings.update`}
                  </Button>
                </>
              )}
            </ActionGroup>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  )
}

export default EditColumnsForm
