import {Bars3BottomLeftIcon} from '@heroicons/react/24/outline'
import {styles} from '@myadbox/stellar-ui'
import {useContext} from 'react'
import {MobileMenuContext} from '../../contexts/MenuContext'

const MenuOpenButton = () => {
  const {openMenu} = useContext(MobileMenuContext)
  return (
    <button
      aria-haspopup="menu"
      className={`
        ${styles.iconInteractionColours}
        text-ui-500
        border-ui-300
        self-stretch
        border-r
        px-4
        md:hidden
        md:px-8
      `}
      aria-label="Open sidebar"
      onClick={openMenu}
    >
      <Bars3BottomLeftIcon className={`mx-auto h-6 w-6`} />
    </button>
  )
}

export default MenuOpenButton
