import {Category} from '@myadbox/nebula-service-api'
import {Button, ComplexModal as Modal} from '@myadbox/stellar-ui'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {ReactNode} from 'react'
import CategoryForm from './CategoryForm'

interface Props {
  category?: Category
  categories: Category[]
}

const defaultCategory: Category = {
  id: ``,
  title: ``,
  parent: null,
  active: false,
}

const CategoriesModal = ({category, categories = []}: Props) => {
  const {t} = useTranslation()

  const currentCategory = category || defaultCategory
  const isNew = category === undefined

  return (
    <Modal
      accessibleTitle={t`settings.categories.createCategory`}
      trigger={({open}): ReactNode =>
        isNew ? (
          <Button variant="primary" onClick={open}>
            {t`settings.categories.createCategory`}
          </Button>
        ) : (
          <Button variant="link" slim onClick={open}>
            {t`settings.edit`}
          </Button>
        )
      }
    >
      {({close}): ReactNode => (
        <CategoryForm
          close={close}
          category={currentCategory}
          categories={categories}
          isNew={isNew}
        />
      )}
    </Modal>
  )
}

export default CategoriesModal
