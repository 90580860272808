import {
  AccountIntegration,
  AccountIntegrationInput,
  useGoogleIntegrations,
} from '@myadbox/nebula-service-api'
import {ActionGroup, Button, ComplexModal as Modal} from '@myadbox/stellar-ui'
import {navigate} from 'gatsby'
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next'
import {useEffect} from 'react'
import {ExportAppList} from '../helpers'

export type ConfirmationModalProps = {
  updateAccountIntegration: (input: AccountIntegrationInput) => void
  loading?: boolean
  accountIntegration: AccountIntegration
  accountId?: string
  label?: string
  srcId?: string
}

const ConfirmationModal = ({
  accountIntegration,
  loading,
  updateAccountIntegration,
  accountId,
  label,
  srcId,
}: ConfirmationModalProps) => {
  const {t} = useTranslation()
  const {enabled, integration} = accountIntegration || {}
  const {
    deleteGoogleSheetsByAccountId,
    deleteGoogleSheetsResponse: {data, loading: deleteLoading},
  } = useGoogleIntegrations()

  const handleIntegrationConnection = (close: () => void) => {
    updateAccountIntegration({
      values: {
        ...(accountIntegration?.values || {}),
        updatedAt: new Date().toISOString(),
      },
      integrationId: srcId,
      enabled: !enabled,
    })
    if (label === ExportAppList.GoogleSheets && enabled) {
      deleteGoogleSheetsByAccountId(accountId)
    }
    close()
  }

  useEffect(() => {
    if (data && !deleteLoading) navigate(`../`)
  }, [data, deleteLoading])

  return (
    <Modal
      trigger={({open}) => (
        <Button
          variant={enabled ? `secondary` : `primary`}
          disabled={deleteLoading}
          aria-label={
            enabled
              ? t`settings.integrations.connectModal.disconnect`
              : t`settings.integrations.connectModal.connect`
          }
          onClick={open}
          size="sm"
        >
          {enabled
            ? t`settings.integrations.connectModal.disconnect`
            : t(`settings.integrations.connectModal.connect`, {
                integrationName: integration?.name ?? label,
              })}
        </Button>
      )}
    >
      {({close}) => (
        <>
          <Modal.Header>
            <Trans
              i18nKey={
                enabled
                  ? t`settings.integrations.connectModal.disConnectHeader`
                  : t`settings.integrations.connectModal.connectHeader`
              }
              values={{integrationName: integration?.name ?? label}}
            />
          </Modal.Header>
          <Modal.Body>
            <Trans
              i18nKey={
                enabled
                  ? t`settings.integrations.connectModal.disConnectMessage`
                  : t`settings.integrations.connectModal.connectMessage`
              }
              values={{integrationName: integration?.name ?? label}}
            />
          </Modal.Body>
          <Modal.Footer>
            <ActionGroup>
              <Button type="button" variant="text" onClick={close}>
                {t`settings.integrations.connectModal.cancel`}
              </Button>
              <Button
                type="submit"
                variant="primary"
                aria-label={
                  enabled
                    ? t`settings.integrations.connectModal.disconnectBtn`
                    : t`settings.integrations.connectModal.connectBtn`
                }
                onClick={(): void => handleIntegrationConnection(close)}
                loading={loading}
              >
                {enabled
                  ? t`settings.integrations.connectModal.disconnectBtn`
                  : t`settings.integrations.connectModal.connectBtn`}
              </Button>
            </ActionGroup>
          </Modal.Footer>
        </>
      )}
    </Modal>
  )
}

export default ConfirmationModal
