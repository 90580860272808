import {useGoogleIntegrations} from '@myadbox/nebula-service-api'
import {ComplexModal as Modal} from '@myadbox/stellar-ui'
import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {
  GoogleIntegrationsForm,
  GoogleIntegrationsSynced,
  useGoogleModalStore,
} from '.'
import withTranslationProvider from '../../locales/withTranslationProvider'

const GoogleIntegrationsModal = ({
  id,
  refetch,
}: {
  id: string
  refetch: () => void
}) => {
  const contentState = useGoogleModalStore(state => state.contentState)
  const setContentState = useGoogleModalStore(state => state.setContentState)
  const isModalOpen = useGoogleModalStore(state => state.isModalOpen)
  const setGoogleModalOpen = useGoogleModalStore(state => state.setModalOpen)
  const {t} = useTranslation(`googleIntegrations`)
  const {
    fetchGoogleSheetBySchemaId,
    fetchBySchemaIdResult: {data},
  } = useGoogleIntegrations()
  const {fetchGoogleSheetBySchemaId: integration} = data ?? {}

  useEffect(() => {
    fetchGoogleSheetBySchemaId(id)
  }, [fetchGoogleSheetBySchemaId, id])

  useEffect(() => {
    if (Object.keys(integration ?? {}).length > 0) setContentState(`connected`)
    else setContentState(`connect`)
  }, [integration, setContentState])

  const renderModal = () => {
    if (contentState === `connect`)
      return <GoogleIntegrationsForm id={id} refetch={refetch} />
    if (contentState === `connected`)
      return <GoogleIntegrationsSynced id={id} />
  }
  return (
    <Modal
      controls={{isOpen: isModalOpen, onClose: setGoogleModalOpen}}
      maxWidth="md"
      id="default-integrations-modal"
    >
      <Modal.Header>{t(`connect.header`)}</Modal.Header>
      {renderModal()}
    </Modal>
  )
}

export default withTranslationProvider(GoogleIntegrationsModal)
