import {cn} from '@myadbox/stellar-ui'
import {ControlProps} from 'react-select'
import {getIsVariant} from '../util'

export const controlBaseClassName = `
  flex
  items-center
  relative
  rounded-400
  border
  duration-150
  ease-in-out
  transition-default
  cursor-pointer
`
export const controlFocusClassName = `shadow-outline`
export const controlInlineClassName = `
  border-transparent
  bg-transparent
  text-ui-600
  text-xs
  sm:text-xs

  leading-3.5
  sm:leading-3.5
  focus:shadow-outline-blue
  focus:outline-none
  hover:bg-ui-300
  dark:hover:bg-ui-300

  dark:bg-transparent
  dark:border-transparent
`
const controlClassName = `
  sm:text-sm
  sm:leading-5
  w-full
  focus:shadow-outline
  focus:outline-none
  hocus:text-ui-1000
`
const controlClassNameBaseColours = `
  bg-ui-50
  dark:bg-ui-200
  dark:border-ui-200
  focus:bg-white
  hover:bg-white
  dark:hocus:bg-ui-200
`
const controlReadOnlyClassName = `
  bg-ui-200
  border-ui-200
  text-ui-500
  dark:text-ui-300
  dark:bg-ui-50
  dark:border-ui-50
`

export const OurControl = ({
  children,
  className = ``,
  innerProps,
  innerRef,
  selectProps,
  isFocused,
}: ControlProps) => {
  // @ts-ignore because this component is deprecated
  const isReadOnly = selectProps[`readOnly`]
  const isInline = getIsVariant(`inline`, selectProps)
  return (
    <div
      {...innerProps}
      ref={innerRef}
      className={cn(
        controlBaseClassName,
        controlClassName,
        controlClassNameBaseColours,
        isReadOnly && controlReadOnlyClassName,
        isFocused && controlFocusClassName,
        isInline && controlInlineClassName,
        className
      )}
    >
      {children}
    </div>
  )
}
