import {Dataset, useDatasets, useProfiles} from '@myadbox/nebula-service-api'
import {Select, Tag, type OptionValue} from '@myadbox/stellar-ui'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {ReactNode, useEffect, useState} from 'react'

interface UserSchemaSelectorProps {
  onChange: (option: OptionValue) => void
  datasetIds?: string[]
  details?: ReactNode
  profileId: string
  amzCognitoPoolId: string
  datasets: Dataset[]
}

const UserSchemaSelector = ({
  onChange,
  datasets = [],
  datasetIds = [],
  profileId,
  amzCognitoPoolId,
}: UserSchemaSelectorProps) => {
  const {t} = useTranslation()
  const {
    fetchSchemas,
    fetchSchemasResult: {data: schemasData},
  } = useDatasets()

  const {updateDatasets} = useProfiles()
  const [datasetBeingRemoved, setDatasetBeingRemoved] = useState<string>(``)

  const schemas = schemasData?.nestedSchemas || []
  const schemaOptions = schemas.map(({title, id}) => ({
    label: title,
    value: id,
  }))
  const hasDatasets = datasets.length > 0

  useEffect(() => {
    /**
     * NOTE:`true` fetches only parent schemas
     * to show in the dropdown
     */
    fetchSchemas(true)
  }, [fetchSchemas])

  const handleRemove = (id: string) => {
    const newDatasetIds = datasetIds.filter(dataset => dataset !== id)
    updateDatasets({
      profileId,
      amzCognitoPoolId,
      datasetIds: newDatasetIds,
    })
    setDatasetBeingRemoved(id)
  }

  return (
    <Select
      onChange={option => onChange(option.value)}
      id="datasetIds"
      name="datasetIds"
      placeholder={t`settings.users.edit.datasetSelect.placeholder`}
      labelHidden
      isSearchable
      virtualList={{
        maxMenuHeight: 90,
        itemHeight: 40,
      }}
      label="datasetIds"
      options={schemaOptions}
      inlineActions={
        hasDatasets ? (
          <ul className={`mt-1`}>
            {datasets.map(({id, nestedSchema: {title: schemaTitle}}) => (
              <li key={id}>
                <Tag
                  onRemove={(): void => handleRemove(id)}
                  loading={datasetBeingRemoved === id}
                >
                  {schemaTitle}
                </Tag>
              </li>
            ))}
          </ul>
        ) : null
      }
    />
  )
}

export default UserSchemaSelector
