import {styles} from '@myadbox/stellar-ui'
import {slugifyText} from '@myadbox/string-utils'
import {
  ButtonHTMLAttributes,
  Dispatch,
  MouseEvent,
  ReactNode,
  createContext,
  useContext,
  useDebugValue,
  useEffect,
  useReducer,
  useState,
} from 'react'

type TogglerTargetProps = {
  label: string
  focusTargetId: string
}

type TogglerProps = TogglerTargetProps & ButtonHTMLAttributes<HTMLElement>

interface FinalTogglerProps {
  [`aria-labelledby`]: string
  [`aria-expanded`]: boolean
  className: string
  onClick(event: MouseEvent<HTMLButtonElement>): void
}

interface FinalTargetProps {
  [`aria-hidden`]: boolean
  tabIndex?: -1
}

interface TogglerHook {
  on: boolean
  props: {
    togglerProps: FinalTogglerProps
    targetProps: FinalTargetProps
  }
}

export const StateContext = createContext<AllToggles>({})
export const DispatchContext = createContext<Dispatch<string> | null>(null)

StateContext.displayName = `Toggler StateContext`
DispatchContext.displayName = `Toggler DispatchContext`

type AllToggles = Record<string, boolean>

export const reducer = (state: AllToggles, label: string): AllToggles => {
  return {
    ...state,
    [label]: Boolean(!state[label]),
  }
}

export const TogglerProvider = ({
  children,
  initialState = {},
}: {
  children: ReactNode
  initialState?: AllToggles
}): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  )
}

export const useToggler = (
  label: string,
  props: {togglerProps?: ButtonHTMLAttributes<HTMLElement>} = {}
): TogglerHook => {
  useDebugValue(`useToggler ${label}`)

  const togglerProps = props[`togglerProps`] || {}

  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)

  const isToggledOn = Boolean(state[label])
  const [on, setOn] = useState(isToggledOn)

  useEffect(() => {
    setOn(isToggledOn)
  }, [isToggledOn])

  const getTogglerProps = (
    label: string,
    {className: suppliedClassName = ``, ...togglerProps}
  ): FinalTogglerProps => {
    const labelId = `${slugifyText(label)}-toggle-label-id`
    const className = `${styles.iconButtonClassName} ${suppliedClassName}`
    const onClick = (e: MouseEvent): void => {
      dispatch?.(label)
      togglerProps.onClick?.(e)
    }

    return {
      ...togglerProps,
      'aria-labelledby': labelId,
      'aria-expanded': on,
      className,
      onClick,
    }
  }

  const getTargetProps = (): FinalTargetProps => {
    return {
      'aria-hidden': !on,
      tabIndex: on ? -1 : undefined,
    }
  }

  return {
    on,
    props: {
      togglerProps: getTogglerProps(label, togglerProps),
      targetProps: getTargetProps(),
    },
  }
}

export const Toggler = ({
  label,
  focusTargetId,
  children,
  ...props
}: TogglerProps) => {
  const {
    on,
    props: {togglerProps},
  } = useToggler(label, {togglerProps: props})

  return (
    <>
      <button type="button" {...togglerProps}>
        {children}
        <span className={`sr-only`} id={togglerProps[`aria-labelledby`]}>
          {on ? `Hide` : `Show`} {label}
        </span>
      </button>
      {on && focusTargetId && (
        <a href={`#${focusTargetId}`} className={`sr-only`}>
          focus toggled content
        </a>
      )}
    </>
  )
}

export default Toggler
