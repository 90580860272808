import {StaticIcon} from '@myadbox/stellar-ui'
import {GatsbyLinkProps, Link} from 'gatsby-link'
import {ButtonHTMLAttributes} from 'react'

export interface ArrowProps
  // eslint-disable-next-line quotes
  extends Omit<GatsbyLinkProps<HTMLElement>, 'to'>,
    Omit<
      ButtonHTMLAttributes<HTMLElement>,
      keyof GatsbyLinkProps<HTMLElement>
    > {
  to?: string
  href?: string
  direction: `left` | `right`
  disabled: boolean
  tabIndex: number
}

export const disabledClassName = `opacity-25 pointer-events-none`

export const defaultClassName = `
  items-center
  self-stretch
  text-ui-500
  flex
  px-2
`

export const firstOrderClassName = `order:first`
export const lastOrderClassName = `order:last`

export const translations = {
  previous: `Previous`,
  next: `Next`,
}

const Arrow = ({
  direction = `left`,
  disabled,
  to,
  href,
  ...passedProps
}: ArrowProps) => {
  const Tag = (href && `a`) || (to && Link) || `button`
  if (Tag === `button`) passedProps[`type`] = `button`

  const dirProps = {
    left: {
      'aria-label': translations.previous,
      title: translations.previous,
      orderClassName: firstOrderClassName,
      children: (
        <StaticIcon name="chevron-left" className={`h-6 w-6 md:h-7 md:w-7`} />
      ),
      ...passedProps,
    },

    right: {
      'aria-label': translations.next,
      title: translations.next,
      orderClassName: lastOrderClassName,
      children: (
        <StaticIcon name="chevron-right" className={`h-6 w-6 md:h-7 md:w-7`} />
      ),
      ...passedProps,
    },
  }
  const {orderClassName, className, ...props} = dirProps[direction]
  return (
    <>
      {/* @ts-ignore because this TS brain-buster beat us */}
      <Tag
        className={`
          ${className || defaultClassName}
          ${disabled ? disabledClassName : ``}
          ${orderClassName}
        `}
        to={to}
        href={href}
        {...props}
      />
    </>
  )
}

export default Arrow
