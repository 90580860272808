import {checkboxClassName, type CheckboxProps} from '@myadbox/stellar-ui'
import {Field} from 'formik'

const CheckboxFormik = ({id, name, className, ...props}: CheckboxProps) => {
  return (
    <Field
      name={name}
      type="checkbox"
      className={`${checkboxClassName} ${className}`}
      id={id}
      {...props}
    />
  )
}

export default CheckboxFormik
