import {NestedSchema} from '@myadbox/nebula-service-api'
import {Button, Dropdown, ComplexModal as Modal} from '@myadbox/stellar-ui'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {HTMLAttributes, ReactNode} from 'react'
import CsvUploader from '../CsvUploader'

interface Props extends HTMLAttributes<HTMLElement> {
  nestedSchema: NestedSchema
  refetchDatasets: () => void
  isOpen: boolean
  onClose: () => void
}

interface TriggerProps {
  onSelect: () => void
  disabled?: boolean
  isListItem?: boolean
}

export const CsvUploaderModalTrigger = ({
  onSelect,
  disabled,
  isListItem = false,
}: TriggerProps) => {
  const {t} = useTranslation()
  return isListItem ? (
    <Dropdown.Item
      onSelect={onSelect}
      disabled={disabled}
      aria-label={`Upload .csv`}
    >
      {t`settings.schemas.csvUploader.btnLabel`}
    </Dropdown.Item>
  ) : (
    <Button
      variant="primary"
      onClick={onSelect}
      disabled={disabled}
      className={`
      whitespace-nowrap
    `}
    >
      {t`settings.schemas.csvUploader.btnLabel`}
    </Button>
  )
}

const CsvUploaderModal = ({
  nestedSchema,
  refetchDatasets,
  isOpen,
  onClose,
}: Props) => {
  const {t} = useTranslation()

  return (
    <Modal
      controls={{isOpen: isOpen, onClose: onClose}}
      accessibleTitle={t`settings.schemas.csvUploader.title`}
    >
      {({close}): ReactNode => (
        <CsvUploader
          close={close}
          nestedSchema={nestedSchema}
          refetchDatasets={refetchDatasets}
        />
      )}
    </Modal>
  )
}

export default CsvUploaderModal
