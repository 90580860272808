import {Dataset} from '@myadbox/nebula-service-api'
import {
  ActionGroup,
  Button,
  ComplexModal as Modal,
  Table,
  Text,
} from '@myadbox/stellar-ui'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {getFormattedDatasetsFromUser} from '../helpers'

interface Props {
  datasets: Dataset[]
  fullName: string
}

export const UserDatasetsList = ({datasets = [], fullName}: Props) => {
  const {t} = useTranslation()
  const formattedDatasets = getFormattedDatasetsFromUser(datasets)

  if (datasets.length === 0) {
    return <Text color="inherit">{t`settings.users.datasets.none`}</Text>
  }

  return (
    <Modal
      trigger={({open}) => (
        <div className={`-ml-2`}>
          <Button
            onClick={open}
            size="sm"
            slim
            title={t`settings.users.datasets.view`}
            type="button"
            variant="text"
          >
            {t`settings.users.datasets.view`}
          </Button>
        </div>
      )}
      maxWidth="md"
    >
      {({close}) => (
        <>
          <Modal.Header>
            {t(`settings.users.datasets.header`, {fullName})}
          </Modal.Header>
          <Modal.Body>
            <>
              {formattedDatasets.map(({name, title, rows}) => (
                <div
                  className={`
                    mb-2
                  `}
                  key={`dataset-${name}`}
                >
                  <div className={`mb-2`}>
                    <Text variant="heading">{title}</Text>
                  </div>
                  <Table aria-labelledby={`${name}-heading`} horizontal>
                    <Table.Body>
                      {rows.map(({label, value, type}) => (
                        <Table.Row key={`${label}`}>
                          <Table.HeadCell variant="quiet">
                            {label}:
                          </Table.HeadCell>
                          <Table.Cell padding="tight">
                            {(typeof value === `object` && (
                              <pre aria-label="complex value">
                                {JSON.stringify(value, null, 2)}
                              </pre>
                            )) ||
                              (type === `uri` && (
                                <a
                                  href={value}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {value}
                                </a>
                              )) ||
                              value}
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </div>
              ))}
            </>
          </Modal.Body>
          <Modal.Footer>
            <ActionGroup>
              <Button
                variant="primary"
                onClick={close}
              >{t`settings.users.datasets.ok`}</Button>
            </ActionGroup>
          </Modal.Footer>
        </>
      )}
    </Modal>
  )
}

export default UserDatasetsList
