import {MagnifyingGlassIcon} from '@heroicons/react/24/outline'
import {FormEvent} from 'react'
import {useTranslation} from 'react-i18next'

export interface SearchFieldProps {
  changeHandler?(
    event: FormEvent<HTMLInputElement> & {target: {value: string}}
  ): void
  disabled?: boolean
  defaultValue?: string
  id: string
  labelText?: string
  value?: string
  variant?: `transparent` | `rounded`
}

const transparentStyles = `px-3 md:px-7`
const roundedStyles = `border border-ui-200 bg-ui-200 rounded-[1.875rem] px-3.5 py-2`

const SearchFieldMain = ({
  changeHandler,
  defaultValue,
  disabled,
  labelText,
  id,
  value,
  variant = `transparent`,
}: SearchFieldProps) => {
  const {t} = useTranslation(`layout`)
  const label = labelText || t`search`
  return (
    <div
      className={`
        flex
        w-full
        md:ml-0
        ${disabled ? `pointer-events-none opacity-80` : ``}
      `}
    >
      <label
        htmlFor={id}
        className={`
          focus-within:text-ui-600
          text-ui-400
          relative
          flex
          w-full
          gap-2
          ${variant === `transparent` ? transparentStyles : ``}
          ${variant === `rounded` ? roundedStyles : ``}
        `}
      >
        <span className={`sr-only`}>{label}</span>
        <div className={`pointer-events-none flex items-center`}>
          <MagnifyingGlassIcon width={24} className={`h-6 w-6`} />
        </div>
        <input
          id={id}
          autoComplete="off"
          className={`
            placeholder:text-ui-500
            focus:placeholder:text-ui-400
            text-ui-900
            block
            h-full
            w-full
            bg-transparent
            py-2
            focus:outline-none
            md:text-sm
          `}
          placeholder={label}
          disabled={disabled}
          type="search"
          onChange={changeHandler}
          value={value}
          defaultValue={defaultValue}
        />
      </label>
    </div>
  )
}

export default SearchFieldMain
