import {supportedLanguages} from '@myadbox/nebula-languages'
import {createPublicPathChecker} from '../utils'

const NONAUTH_PAGES = [`forgot`, `reset`, `logout`, `admin`]

export const isArkaroolaPage = createPublicPathChecker({
  publicPages: NONAUTH_PAGES,
  supportedLanguages: Object.keys(supportedLanguages),
})

export const isExternalPage = (pathname: string): boolean => {
  return Boolean(new RegExp(/\/external\/.+(\/)?$/).exec(pathname))
}

export const isPublicPage = (pathname: string): boolean => {
  return isArkaroolaPage(pathname) || isExternalPage(pathname)
}
