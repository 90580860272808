/* eslint-disable @typescript-eslint/no-unused-vars */
import {ApolloQueryResult, FetchResult, MutationResult} from '@apollo/client'
import {Asset} from '../assets/types'

export const accessLevels = {
  Owner: `owner`,
  Collaborator: `collaborator`,
  Viewer: `viewer`,
  Publisher: `publisher`,
} as const

export type AccessLevel = (typeof accessLevels)[keyof typeof accessLevels]

type AccessCommon = {
  id: string
  name?: string
  accessLevel: AccessLevel
  validFrom?: string | Date
  validTo?: string | Date
}

export type UserAccess = AccessCommon & {
  userId: string
  teamId?: never
}

export type TeamAccess = AccessCommon & {
  userId?: never
  teamId: string
}

export type Access = UserAccess | TeamAccess

export type Share = Access
export type SessionAccess = Access & {shortId: string}

export interface ShareUpsertContext {
  assetIds: string[]
  assetUrl: string
  message?: string
  isTemplate?: boolean
}

export interface ShareUpdateContext {
  assetIds: string[]
  assetUrl: string
}

export interface AccessInput extends Omit<Access, `id`> {
  id?: string
}

export type UpsertSharesMutator = (
  shares: AccessInput[],
  context: ShareUpsertContext
) => Promise<FetchResult<Access[]>>

export type UpdateSharesMutator = (
  shares: Share[],
  context: ShareUpdateContext
) => Promise<FetchResult<Access[]>>

export interface UseAccessOutput {
  fetchAccess(accessIds: string[]): void
  fetchAccessResults: ApolloQueryResult<{access: Access[]}>
  upsertShares: UpsertSharesMutator
  upsertSharesResponse: MutationResult<{upsertAccess: Access[]}>
  updateShares: UpdateSharesMutator
  updateSharesResponse: MutationResult<{updateAccess: Access[]}>
}

export type ExternalShareAccessLevel = `VIEW` | `DOWNLOAD`

export interface ExternalShareInput {
  assetIds: string[]
  emails: string[]
  expiry: Date
  accessLevel: ExternalShareAccessLevel
  message?: string
}
export interface ExternalShare {
  id: string
  assetIds: string[]
  email: string
  expiry: Date
  accessLevel: ExternalShareAccessLevel
  deleted: boolean
}
export interface ExternalShareAccount {
  email: string
  data: ExternalShare[]
}

export type AddExternalSharesMutator = (
  input: ExternalShareInput
) => Promise<FetchResult<ExternalShare>>

export type DeleteExternalSharesMutator = (
  id: string,
  assetIds: string[]
) => Promise<FetchResult<ExternalShare>>

export interface UseExternalShareOutput {
  addExternalShares: AddExternalSharesMutator
  addExternalSharesResponse: MutationResult<{
    addExternalShares: ExternalShare[]
  }>
  fetchExternalShares: (assetIds: string[]) => void
  fetchExternalSharesResponse: ApolloQueryResult<{
    getExternalShares: ExternalShare[]
  }>
  deleteExternalShare: DeleteExternalSharesMutator
  deleteExternalShareResponse: MutationResult<{
    deleteExternalShare: ExternalShare
  }>
  verifyShareToken: (token: string) => void
  verifyShareTokenResponse: ApolloQueryResult<{
    verifyShareToken: {
      share: ExternalShare
      assets: Asset[]
    }
  }>
}
