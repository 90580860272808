import {Button, ComplexModal as Modal} from '@myadbox/stellar-ui'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {DeleteUserModalContent} from './DeleteUserModalContent'

export type DeleteUserModalProps = {
  avatar: string
  fullName: string
  keywords?: string
  profileId: string
  userId: string
  userPoolId: string
}

export const DeleteUserModal = (props: DeleteUserModalProps) => {
  const {t} = useTranslation()

  return (
    <Modal
      accessibleTitle={t`settings.users.delete.header`}
      maxWidth="md"
      trigger={({open}) => (
        <Button
          name="remove"
          onClick={open}
          size="md"
          slim
          type="button"
          variant="link"
        >
          {t`settings.users.delete.remove`}
        </Button>
      )}
    >
      {({close}) => <DeleteUserModalContent close={close} {...props} />}
    </Modal>
  )
}

export default DeleteUserModal
