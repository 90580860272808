import {CheckIcon, XMarkIcon} from '@heroicons/react/24/outline'
import {Checkbox, Table} from '@myadbox/stellar-ui'

export const CheckboxCell = ({
  rowData,
  name,
  type,
  isEditing,
  checkboxName,
}) => {
  return (
    <Table.Cell>
      {type === `boolean` ? (
        isEditing ? (
          <Checkbox checked={Boolean(rowData[name])} name={checkboxName} />
        ) : (
          <span className={`relative -mb-1 block`}>
            {rowData[name] ? (
              <CheckIcon width={18} title="true" />
            ) : (
              <XMarkIcon width={18} title="false" />
            )}
          </span>
        )
      ) : (
        <p>{rowData[name] && rowData[name].toString()}</p>
      )}
    </Table.Cell>
  )
}
