import {isEqual as _isEqual, omit as _omit} from 'lodash'
import {ComponentProps, useEffect, useState} from 'react'
import {components} from 'react-select'
import Select from 'react-select/base'
import {OurInput as Input} from './Input'
import {OurMenu as Menu} from './Menu'
import {OurClearIndicator as ClearIndicator} from './OurClearIndicator'
import {OurControl as Control} from './OurControl'
import {OurDropdownIndicator as DropdownIndicator} from './OurDropdownIndicator'
import {OurMenuList as MenuList} from './OurMenuList'
import {OurMultiValueContainer as MultiValueContainer} from './OurMultiValueContainer'
import {OurMultiValueLabel as MultiValueLabel} from './OurMultiValueLabel'
import {OurMultiValueRemove as MultiValueRemove} from './OurMultiValueRemove'
import {OurNoOptionsMessage as NoOptionsMessage} from './OurNoOptionsMessage/OurNoOptionsMessage'
import {OurOption as Option} from './OurOption'
import {OurValueContainer as ValueContainer} from './OurValueContainer'
import {OurSingleValue as SingleValue} from './SingleValue'

import {OptionData, OptionValue, SelectOption, ValidOption} from './types'

export const ourSelectComponents: ComponentProps<typeof Select>[`components`] =
  {
    ...components,
    ClearIndicator,
    Control,
    DropdownIndicator,
    Input,
    Menu,
    MenuList,
    MultiValueContainer,
    MultiValueLabel,
    MultiValueRemove,
    NoOptionsMessage,
    // @ts-ignore because our strictness has increased. Please fix this.
    Option,
    SingleValue,
    ValueContainer,
  }

export const createCurrentFinder =
  (value: OptionValue<OptionData>, keyToMatch?: string) =>
  <T extends {value: OptionValue<OptionData>}>(item: T): boolean => {
    const shouldMatchNested = typeof item.value === `object`

    if (shouldMatchNested) {
      // checking if matching makes sense to avoid false matches when comparing undefined with undefined
      const requestedMatchersExist =
        // @ts-ignore because our strictness has increased. Please fix this.
        item.value?.[keyToMatch] && value && value[keyToMatch]
      // provide support for older templates that haven't adopted adding a unique `id` property to object values, but likely do have `name`
      const fallbackMatcher = `name`
      const fallbackMatchersExist =
        // @ts-ignore because our strictness has increased. Please fix this.
        item.value?.[fallbackMatcher] && value?.[fallbackMatcher]

      const matcher = requestedMatchersExist
        ? keyToMatch
        : fallbackMatchersExist
        ? fallbackMatcher
        : null
      // @ts-ignore because our strictness has increased. Please fix this.
      return matcher ? item.value?.[matcher] === value[matcher] : false
    }

    return item.value === value
  }

export const getMultiValue = (
  value: OptionValue[] = [],
  options: OptionData[],
  keyToMatch?: string
) =>
  value.map(value =>
    options.find(
      // @ts-ignore because it has beaten me for now
      createCurrentFinder(value, keyToMatch)
    )
  )

export const getCurrentValue = ({
  // @ts-ignore because our strictness has increased. Please fix this.
  options, // @ts-ignore because our strictness has increased. Please fix this.
  value, // @ts-ignore because our strictness has increased. Please fix this.
  isMulti, // @ts-ignore because our strictness has increased. Please fix this.
  findCurrentByProperty,
}) =>
  isMulti && typeof value === `object`
    ? getMultiValue(
        value as string[],
        // @ts-ignore because it works, but the typings aren't quite right
        options,
        findCurrentByProperty
      )
    : options.find(
        // @ts-ignore because it works, but the typings aren't quite right
        createCurrentFinder(value as string, findCurrentByProperty)
      )

export const MODAL_ID = `react-aria-modal-dialog`

export const usePortalTarget = () => {
  const [target, setTarget] = useState<HTMLElement | null>(null)

  useEffect(() => {
    const target = document.getElementById(MODAL_ID)
    setTarget(target)
  }, [])

  return {target}
}

export const matchOptionValue = <T extends ValidOption>(
  options: T[],
  value: unknown
): T | null => {
  // @ts-ignore because our strictness has increased. Please fix this.
  return options?.find(o => o.value === value)
}

export const isValueInOptions = (
  options: ComponentProps<typeof Select>[`options`],
  value: unknown,
  isMulti: boolean,
  childFieldKeys = []
): boolean => {
  const compareValues = (optionValue: unknown, value: unknown) => {
    return typeof optionValue === `object` && typeof value === `object`
      ? _isEqual(
          _omit(optionValue, childFieldKeys),
          _omit(value, childFieldKeys)
        )
      : optionValue === value
  }

  return isMulti
    ? Array.isArray(value) &&
        value.every(val =>
          // @ts-ignore because our strictness has increased. Please fix this.
          options.some((option: SelectOption) =>
            compareValues(option.value, val)
          )
        )
    : // @ts-ignore because our strictness has increased. Please fix this.
      options.some((option: SelectOption) => compareValues(option.value, value))
}
