import {ApolloError} from '@apollo/client'
import {toLocaleTimezone} from '@myadbox/gatsby-theme-nebula/utils'
import {NestedSchema} from '@myadbox/nebula-service-api'
import {
  Button,
  ComplexModal as Modal,
  Table,
  TableScrollWrap,
} from '@myadbox/stellar-ui'
import {Link, useI18next} from 'gatsby-plugin-react-i18next'
import {ReactElement, ReactNode} from 'react'
import {tableHelpers} from '../../../../../utils/tableHelpers'
import SchemaDelete from '../SchemaDelete/SchemaDelete'

type Props = {
  schemas: NestedSchema[]
  getUsernameById?: (id: string) => string
  timeZone?: string
  profilesLoading?: boolean
  profilesError?: ApolloError
}

const SchemaTableList = ({
  schemas,
  profilesLoading,
  profilesError,
  timeZone,
  getUsernameById,
}: Props) => {
  const {t, language} = useI18next()
  return (
    <TableScrollWrap minWidth={600}>
      <Table contrast>
        <Table.Head>
          <Table.Row>
            <Table.HeadCell>{t`settings.schemas.titleCol`}</Table.HeadCell>
            <Table.HeadCell>{t`settings.schemas.datasetsCol`}</Table.HeadCell>
            <Table.HeadCell>
              {t`settings.schemas.dateCreatedCol`}
            </Table.HeadCell>
            <Table.HeadCell>{t`settings.schemas.createdByCol`}</Table.HeadCell>
            <Table.HeadCell style={{width: tableHelpers.actionsWidth}}>
              {t`settings.schemas.actionsCol`}
            </Table.HeadCell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {schemas &&
            schemas.map((schema): ReactNode => {
              // TODO: use the actual locale
              const formattedDate = toLocaleTimezone(schema.createdAt, {
                timeZone,
                locale: language,
                year: `numeric`,
                month: `numeric`,
                day: `numeric`,
              })
              const formattedName = getUsernameById(schema.createdById)
              return (
                <Table.Row key={schema.id}>
                  <Table.Cell>
                    <div className={``}>
                      <Link
                        to={`/settings/schemas/${schema.id}`}
                        className={`
                          text-blue-600
                          dark:text-blue-400
                        `}
                      >
                        {schema.title}
                      </Link>
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    <div className={``}>{schema.datasetsCount}</div>
                  </Table.Cell>
                  <Table.Cell>
                    <div className={``}>{formattedDate}</div>
                  </Table.Cell>
                  <Table.Cell>
                    <div
                      title={formattedName}
                      className={`
                          max-w-[--max-width-var-1]
                          overflow-hidden
                          whitespace-nowrap
                        `}
                      style={{
                        '--max-width-var-1': `20ch`,
                        textOverflow: `ellipsis`,
                      }}
                    >
                      {(profilesLoading && (
                        <span
                          className={`
                              bg-ui-100
                              text-xs
                              text-transparent
                            `}
                        >
                          {t`settings.schemas.nameLoading`}
                        </span>
                      )) ||
                        (profilesError && t`settings.errored`) ||
                        formattedName}
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    <div
                      className={`
                          text-ui-400
                          dark:text-ui-300
                          -ml-3
                        `}
                    >
                      <Modal
                        accessibleTitle={t`settings.schemas.removeTitle`}
                        trigger={({open}): ReactElement => (
                          <Button variant="link" slim onClick={open}>
                            {t`settings.remove`}
                          </Button>
                        )}
                      >
                        {({close}): ReactElement => (
                          <SchemaDelete close={close} schema={schema} />
                        )}
                      </Modal>
                    </div>
                  </Table.Cell>
                </Table.Row>
              )
            })}
        </Table.Body>
      </Table>
    </TableScrollWrap>
  )
}

export default SchemaTableList
