import {JwtToken} from '@myadbox/nebula-auth-utils'
import {hasAuthTokenExpired, parseToken} from '../hooks/useUserSession/helpers'
import {isBrowser} from './helpers'

export const setAuthData = <T extends {token: string}>(data: T): void => {
  if (!isBrowser()) return

  // Set Auth Tokens
  localStorage.setItem(`nf_jwt`, data.token)
}

export const clearAuthData = (): void => {
  if (!isBrowser()) return

  // Remove cognito tokens
  const cognitoKeys = Object.keys(localStorage).filter(key =>
    key.toLowerCase().startsWith(`cognito`)
  )
  cognitoKeys.forEach(key => localStorage.removeItem(key))

  // Remove auth tokens
  localStorage.removeItem(`nf_jwt`)
}

export const isValidSession = (session: string): boolean => {
  const payload = parseToken<JwtToken>(session)

  return !hasAuthTokenExpired(payload)
}
