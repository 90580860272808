import {CsvDataError} from '@myadbox/gatsby-theme-nebula/types'
import {BulkUpdateProfileParams} from '@myadbox/nebula-service-api'
import {startsWithSpecialChar} from '@myadbox/string-utils'
import {TFunction} from 'i18next'

const headersMap = {
  ID: `id`,
  Teams: `teamNames`,
  'Data records ids': `datasetIds`,
}

export const transformHeaders = (headerLine: string): string => {
  const headers = headerLine.split(`,`)

  const newHeaders = headers
    .map(h => headersMap[h.replace(/"/g, ``)] || h)
    .join(`,`)
  return newHeaders
}

export const validateData = (
  data: Record<string, string>[],
  t: TFunction
): CsvDataError => {
  const requiredFields = [`id`]
  const errors = {}
  data.forEach((row, index) => {
    const rowErrors = {}

    const fieldsDiff = requiredFields.filter(x => !Object.keys(row).includes(x))
    if (fieldsDiff.length) {
      rowErrors[
        index
      ] = `${t`settings.users.bulkActions.csvUploader.validator.missing`} - ${fieldsDiff}`
    }
    Object.keys(row).map(col => {
      const field = Object.values(headersMap).find(h => h === col)

      if (!field) {
        rowErrors[
          col
        ] = t`settings.users.bulkActions.csvUploader.validator.invalid`
        // eslint-disable-next-line array-callback-return
        return
      }

      if (startsWithSpecialChar(row[col])) {
        rowErrors[
          col
        ] = t`settings.users.bulkActions.csvUploader.validator.startsWithSpecialChar`
        // eslint-disable-next-line array-callback-return
        return
      }

      const error =
        requiredFields.includes(field) && !row[col]
          ? t`settings.users.bulkActions.csvUploader.validator.required`
          : null
      if (error) {
        rowErrors[field] = error
      }
    })
    if (Object.keys(rowErrors).length) {
      errors[`row${index}`] = rowErrors
    }
  })
  return errors
}

export const convertUserData = (
  userData: Record<string, string>[]
): BulkUpdateProfileParams[] => {
  const data = []

  userData.forEach(row => {
    const rowData = {}
    rowData[`teamNames`] = row[`teamNames`]
      ?.split(`,`)
      .map(t => t.trim())
      .filter(Boolean)
    rowData[`datasetIds`] = row[`datasetIds`]?.split(`,`).filter(Boolean)
    rowData[`id`] = row[`id`]
    data.push(rowData)
  })

  return data
}
