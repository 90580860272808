import {CheckIcon} from '@heroicons/react/24/outline'
import {
  ActiveIntegrationType,
  Asset,
  ExchangeTokenInput,
  useExchangeToken,
} from '@myadbox/nebula-service-api'
import {
  ActionGroup,
  Button,
  CarsalesIcon,
  FacebookIcon,
  GoogleAdsIcon,
  InstagramIcon,
  ComplexModal as Modal,
  Text,
} from '@myadbox/stellar-ui'
import {useTranslation} from 'react-i18next'
import {useClientMetaAuth} from '../../../hooks'
import withTranslationProvider from '../../../locales/withTranslationProvider'
import {
  carSalesRedirectSource,
  redirectSource as metaRedirect,
  paramsToObject,
  toTitleCase,
} from '../../../utils/helpers'
import {redirectSource as googleRedirect} from '../../GoogleSheetsAuthModal/helpers'
import {useSocialsIntegrations, useSocialsModalStore} from '../hooks'
import {RefetchFunction} from '../types'
import SocialsEmbed from './SocialsEmbed'

const metaAppId = process.env.GATSBY_META_CLIENT_ID || ``
const googleAppId = process.env.GATSBY_GOOGLE_ADS_CLIENT_ID || ``
const apiBaseUrl = process.env.GATSBY_SERVICES_API_GATEWAY || ``

export const IntegrationIcon = ({type}: {type: ActiveIntegrationType}) => {
  switch (type) {
    case `FACEBOOK`:
      return <FacebookIcon size={34} />
    case `INSTAGRAM`:
      return <InstagramIcon size={34} />
    case `GOOGLE_ADS`:
      return <GoogleAdsIcon size={34} />
    case `CARSALES`:
      return <CarsalesIcon size={34} />
    default:
      return
  }
}

export const handleReturnMessageBuilder = ({
  selectedExportIntegrationId,
  activeIntegrationType,
  exchangeToken,
  refetch,
}: {
  selectedExportIntegrationId: string
  activeIntegrationType: ActiveIntegrationType
  exchangeToken: (input: ExchangeTokenInput) => void
  refetch: RefetchFunction
}) => {
  const redirectSources: Record<ActiveIntegrationType, string> = {
    GOOGLE_ADS: googleRedirect,
    FACEBOOK: metaRedirect,
    INSTAGRAM: metaRedirect,
    CARSALES: carSalesRedirectSource,
    EMBED: ``,
  }

  return async (event: {
    origin: string
    data: {
      payload: string
      source: string
    }
  }) => {
    if (event.origin !== window.location.origin) return

    const expectedSource = redirectSources[activeIntegrationType]

    if (!expectedSource || event.data.source !== expectedSource) return

    const params = paramsToObject(event.data.payload)
    const token = [`GOOGLE_ADS`, `CARSALES`].includes(activeIntegrationType)
      ? event.data.payload
      : [`FACEBOOK`, `INSTAGRAM`].includes(activeIntegrationType) &&
        params?.access_token

    if (!token) return

    exchangeToken({
      integrationId: selectedExportIntegrationId,
      type: String(activeIntegrationType),
      authToken: token,
    })
    await refetch()
    window.location.reload()
  }
}

const SocialsIntegrationsConnect = ({assets}: {assets: Asset[]}) => {
  const {t} = useTranslation(`socialsIntegrations`)
  const {exchangeToken, exchangeTokenResponse} = useExchangeToken()
  const {data, loading, error} = exchangeTokenResponse

  const integrationType = useSocialsModalStore(state => state.integrationType)
  const isGoogleAds = integrationType === `GOOGLE_ADS`
  const isEmbed = integrationType === `EMBED`
  const reset = useSocialsModalStore(state => state.reset)

  const {refetch, selectedIntegration, connectedIntegration} =
    useSocialsIntegrations()

  const handleReturnMessage = handleReturnMessageBuilder({
    selectedExportIntegrationId: selectedIntegration?.id ?? ``,
    activeIntegrationType: integrationType,
    exchangeToken,
    refetch,
  })

  const appId = isGoogleAds ? googleAppId : metaAppId
  const {handleMetaLogin} = useClientMetaAuth(handleReturnMessage, appId)

  const handleConnect = () => {
    // istanbul ignore next
    handleMetaLogin(integrationType, apiBaseUrl)
  }

  const expiryDate = connectedIntegration
    ? new Date(connectedIntegration.user.token.expiresAt).getTime()
    : Date.now() - 1000
  const hasExpired = expiryDate <= Date.now() ? `getToken` : `resetToken`

  return (
    <>
      <Modal.Body>
        <div className="flex flex-col gap-4">
          {isEmbed ? (
            <SocialsEmbed assets={assets} />
          ) : (
            <>
              <IntegrationIcon type={integrationType} />
              <Text>
                {t(`connect.${hasExpired}.body`, {
                  integration: toTitleCase(integrationType),
                })}
              </Text>
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {error && (
          <div className="mb-2 text-red-600">
            <Text color="inherit">{error.message}</Text>
          </div>
        )}
        <ActionGroup>
          {!loading && data ? (
            <div
              className={`
                    flex
                    items-center
                    justify-center
                    gap-1
                    text-green-600
                  `}
            >
              <CheckIcon width={24} height={24} />
              <Text>{t`connect.connected`}</Text>
            </div>
          ) : (
            <>
              <Button variant="secondary" onClick={reset}>
                {t(isEmbed ? `embed.close` : `connect.cancel`)}
              </Button>
              {!isEmbed && (
                <Button
                  variant="primary"
                  onClick={handleConnect}
                  disabled={loading}
                  loading={loading}
                  loadingText={t`connect.updating`}
                >
                  {t(`connect.${hasExpired}.connect`, {
                    integration: toTitleCase(String(integrationType)),
                  })}
                </Button>
              )}
            </>
          )}
        </ActionGroup>
      </Modal.Footer>
    </>
  )
}

export default withTranslationProvider(SocialsIntegrationsConnect)
