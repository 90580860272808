import {
  Button,
  ComplexModal as Modal,
  type ModalTriggerProps,
} from '@myadbox/stellar-ui'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {ReactNode} from 'react'
import {BulkUploadModalContent} from './BulkUploadModalContent'

export const BulkUploadModal = () => {
  const {t} = useTranslation()

  return (
    <Modal
      accessibleTitle={t`settings.account.assetData.csvUploader.title`}
      trigger={BulkUploadModalTrigger}
      maxWidth="md"
    >
      {({close}): ReactNode => (
        <>
          <Modal.Header>{t`settings.account.assetData.csvUploader.header`}</Modal.Header>
          <BulkUploadModalContent close={close} />
        </>
      )}
    </Modal>
  )
}

const BulkUploadModalTrigger = ({open}: ModalTriggerProps): ReactNode => {
  const {t} = useTranslation()
  return (
    <Button variant="secondary" onClick={open} disabled={false} size="sm">
      {t`settings.account.assetData.csvUploader.uploadBtn`}
    </Button>
  )
}

export default BulkUploadModal
