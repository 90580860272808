import {
  ActionGroup,
  Button,
  ComplexModal as Modal,
  Text,
} from '@myadbox/stellar-ui'
import {useTranslation} from 'react-i18next'
import useGoogleModalStore from '../hooks/useGoogleSheetsModalStore'

const ErrorModal = () => {
  const {t} = useTranslation(`googleSheetIntegrations`)
  const {isModalOpen, setModalOpen} = useGoogleModalStore(state => state)
  const handleCancel = () => setModalOpen()
  return (
    <Modal
      controls={{isOpen: isModalOpen, onClose: setModalOpen}}
      maxWidth="md"
      id="default-integrations-modal"
    >
      <Modal.Header>{t(`errorModal.title`)}</Modal.Header>
      <Modal.Body>
        <div className="flex flex-col gap-4">
          <Text>{t(`errorModal.description`)}</Text>
          <ActionGroup>
            <Button variant="secondary" onClick={handleCancel}>
              {t(`button.back`)}
            </Button>
          </ActionGroup>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ErrorModal
