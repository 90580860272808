import {Text} from '@myadbox/stellar-ui'
import {HTMLAttributes} from 'react'

interface FooterProps extends HTMLAttributes<HTMLElement> {
  pageNum?: string
}

const Footer = ({pageNum}: FooterProps) => (
  <div
    className={`
      pointer-events-auto
      flex
      items-center
      justify-between
      p-4
    `}
  >
    <Text variant="heading" color="inherit">
      {pageNum}
    </Text>
  </div>
)

export default Footer
