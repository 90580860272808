import {CurrencyInput, FieldError, FormItem} from '@myadbox/stellar-ui'
import {ErrorMessage, useField} from 'formik'

import {ComponentProps} from 'react'
import ReactCurrencyInput from 'react-currency-input-field'

export const CurrencyInputFormik = ({
  label,
  details,
  name,
  id,
  labelHidden,
  inlineActions,
  variant = `md`,
  readOnly,
  currency = `AUD`,
  locale = `en-AU`,
  allowDecimals = false,
  maxLength = 6,
  onValueChange,
  className,
  ...props
}: ComponentProps<typeof CurrencyInput>) => {
  const [field, , helper] = useField(name)

  if (!(name && label && id)) return null

  const classNames = `
    ${CurrencyInput.getCurrencyInputClassName({
      ...props,
      readOnly,
      variant,
      className,
    })}
  `
  const {value, ...restField} = field

  const defaultOnValueChange = ({value}: {value: string | undefined}) =>
    helper.setValue(value)
  const onChange = onValueChange || defaultOnValueChange

  return (
    <FormItem
      label={
        labelHidden ? null : (
          <FormItem.Label htmlFor={id} dim={readOnly}>
            {label}
          </FormItem.Label>
        )
      }
      details={details}
    >
      <div
        className={`
          flex
          gap-4
        `}
      >
        {/* @ts-ignore - this needs fixing */}
        <ReactCurrencyInput
          {...restField}
          onValueChange={(value, name, values) =>
            onChange({value, name, values})
          }
          defaultValue={value}
          allowDecimals={allowDecimals}
          allowNegativeValue={false}
          type="text"
          name={name}
          id={id}
          className={classNames}
          maxLength={maxLength}
          inputMode="numeric"
          intlConfig={{
            locale,
            currency,
          }}
          readOnly={readOnly}
          aria-label={labelHidden ? `${label}` : null}
        />
        {inlineActions}
      </div>
      <div>
        <ErrorMessage
          name={name}
          component={FieldError}
          data-testid={`error-${name}`}
        />
      </div>
    </FormItem>
  )
}
