import {DatePicker, FieldError} from '@myadbox/stellar-ui'
import {ErrorMessage, useField} from 'formik'
import {ComponentProps} from 'react'

const DatePickerFormik = ({
  name,
  locale,
  onChange,
  ...props
}: ComponentProps<typeof DatePicker>) => {
  const [, meta, helpers] = useField(name)
  const {value} = meta
  const {setValue, setTouched} = helpers

  const defaultOnChange = ({date}: {date: Date | null}) => {
    setValue(date)
    setTouched(true)
  }

  return (
    <div>
      <DatePicker
        name={name}
        {...props}
        startDateTime={value}
        onChange={onChange || defaultOnChange}
        locale={locale}
      />
      <ErrorMessage
        name={name}
        component={FieldError}
        data-testid={`error-${name}`}
        className={`pt-1`}
      />
    </div>
  )
}

export default DatePickerFormik
