import {styles} from '@myadbox/stellar-ui'
import {
  useMemo,
  useRef,
  useState,
  type ReactElement,
  type ReactNode,
} from 'react'
import DefaultHeader from './Header'
import {SidebarOffscreen, SidebarStatic} from './Sidebars'
import {TheWrap as Wrap} from './Wrap'
import {MobileMenuContext} from './contexts/MenuContext'
import {useChangeBlocker} from './hooks/useChangeBlocker'
import type {LogoType} from './types'

export interface LayoutProps extends LogoType {
  hideHead?: boolean
  hideSide?: boolean
  Header?: ReactElement
  contentPad?: boolean
  mainInnerClassName?: string
  children?: ReactNode
  MobileMenu?: ReactNode
  DesktopMenu?: ReactNode
}

export const wrapClassName = `
  text-ui-600
  bg-ui-200
  dark:bg-ui-0
  dark:text-ui-400
  font-normal
`

const LayoutApp = ({
  children,
  contentPad = true,
  hideHead = false,
  hideSide = false,
  mainInnerClassName = ``,
  Logo,
  Header = <DefaultHeader />,
  MobileMenu = <div />,
  DesktopMenu = <div />,
}: LayoutProps) => {
  const [offscreenMenuShown, setOffscreenMenuShown] = useState(false)

  const closeMenu = useRef((): void => setOffscreenMenuShown(false))
  const openMenu = useRef((): void => setOffscreenMenuShown(true))
  const mobileMenuHelpers = useMemo(
    () => ({
      closeMenu: closeMenu.current,
      openMenu: openMenu.current,
    }),
    [closeMenu, openMenu]
  )

  useChangeBlocker()

  return (
    <MobileMenuContext.Provider value={mobileMenuHelpers}>
      <Wrap className={wrapClassName}>
        <div className={`flex h-screen overflow-hidden`}>
          {!hideSide && (
            <>
              <SidebarOffscreen
                expanded={offscreenMenuShown}
                Logo={Logo}
                Menu={MobileMenu}
              />
              <SidebarStatic Logo={Logo} Menu={DesktopMenu} />
            </>
          )}
          <div className={`flex w-0 flex-1 flex-col overflow-hidden`}>
            {!hideHead && Header}

            <main
              className={`
                relative
                flex
                flex-1
                flex-col
                overflow-y-auto
                focus:outline-none
              `}
            >
              <div
                data-testid="mainInner"
                className={`
                    ${contentPad ? styles.mainSpacing : ``}
                    ${mainInnerClassName}
                    flex
                    flex-1
                    flex-col
                  `}
              >
                {children}
              </div>
            </main>
          </div>
        </div>
      </Wrap>
    </MobileMenuContext.Provider>
  )
}

export default LayoutApp
