import {useMutation} from '@apollo/client'
import {useLocation} from '@reach/router'
import {useCallback, useEffect, useState} from 'react'
import {
  TOGGLE_WHITE_LABEL,
  UPDATE_ACCOUNT_CONFIGURATION,
  UPDATE_ACCOUNT_IMAGE_CONFIG,
  UPDATE_AI_TAGS_CONFIG,
} from '../../mutations'
import type {AccountConfiguration, UseAccountOutput} from '../../types'
import {updateConfiguration} from '../../updaters'
import {useFetchAccount} from '../useFetchAccount'

export const useAccount = (): UseAccountOutput => {
  const {hostname} = useLocation()
  const [isEnterprise, setIsEnterprise] = useState(false)
  const [accountName, setAccountName] = useState<string>(
    hostname?.split(`.`)[0]
  )

  const account = useFetchAccount(accountName)
  useEffect(() => {
    if (!account) return
    if (account.accountName !== accountName) {
      setAccountName(account.accountName)
    }
    if (account?.configuration) {
      setIsEnterprise(account.configuration.subscriptionType === `ENTERPRISE`)
    }
  }, [account, accountName])

  const [doUpdateAccountImageConfig, updateAccountImageConfigResponse] =
    useMutation(UPDATE_ACCOUNT_IMAGE_CONFIG)

  const updateAccountImageConfig = useCallback(
    inputParam => {
      return doUpdateAccountImageConfig({
        variables: {
          imageConfigInput: inputParam,
        },
      })
    },
    [doUpdateAccountImageConfig]
  )

  const [doUpdateAccountConfiguration, updateAccountConfigurationResponse] =
    useMutation(UPDATE_ACCOUNT_CONFIGURATION)

  const updateAccountConfiguration = useCallback(
    (input: AccountConfiguration) => {
      return doUpdateAccountConfiguration({
        variables: {configuration: input},
      })
    },
    [doUpdateAccountConfiguration]
  )

  const [doUpdateAITagsConfig, updateAITagsConfigResponse] = useMutation(
    UPDATE_AI_TAGS_CONFIG
  )
  const updateAITagsConfig = useCallback(
    (aiTagsEnabled: boolean) => {
      return doUpdateAITagsConfig({
        variables: {aiTagsEnabled},
        update: updateConfiguration(
          accountName,
          `aiTagsEnabled`,
          `updateAITagsConfig`
        ),
      })
    },
    [accountName, doUpdateAITagsConfig]
  )

  const [doToggleWhiteLabel, toggleWhiteLabelResponse] =
    useMutation(TOGGLE_WHITE_LABEL)
  const toggleWhiteLabel = useCallback(() => {
    return doToggleWhiteLabel({
      update: updateConfiguration(
        accountName,
        `isWhiteLabelled`,
        `toggleWhiteLabel`
      ),
    })
  }, [accountName, doToggleWhiteLabel])

  return {
    account,
    accountName,
    isEnterprise,
    storageLimit: account?.billing?.limits?.storage,
    toggleWhiteLabel,
    toggleWhiteLabelResponse,
    updateAITagsConfig,
    updateAITagsConfigResponse,
    updateAccountImageConfig,
    updateAccountImageConfigResponse,
    updateAccountConfiguration,
    updateAccountConfigurationResponse,
  }
}
