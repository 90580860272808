import type {ReactNode} from 'react'
import LayoutHeader from '../../LayoutHeader/LayoutHeader'
import LayoutAppOpenButton from '../Header/MenuOpenButton'

type HeaderProps = {
  children?: ReactNode
  endContent?: ReactNode
  headerMenu?: ReactNode
}

const Header = ({
  children,
  endContent,
  headerMenu = <div></div>,
  ...props
}: HeaderProps) => {
  return (
    <LayoutHeader
      startContent={<LayoutAppOpenButton />}
      endContent={
        <>
          {endContent && (
            <AltEndContentWrapper>{endContent}</AltEndContentWrapper>
          )}
          {headerMenu}
        </>
      }
      {...props}
    >
      {children}
    </LayoutHeader>
  )
}

const AltEndContentWrapper = ({children}: {children: ReactNode}) => {
  return (
    <div
      className={`
        flex
        items-center
        border-r
        pr-3
        md:hidden
      `}
    >
      {children}
    </div>
  )
}

Header.AltEndContentWrapper = AltEndContentWrapper
export default Header
