import {Select} from '@myadbox/stellar-ui'
import {FieldHelperProps, useField} from 'formik'
import {ComponentProps, useEffect} from 'react'
import {getCurrentValue, isValueInOptions} from '../helpers'

const normalizedOnChange = (
  helper: FieldHelperProps<unknown>,
  isMulti: boolean
  // @ts-ignore because this component is deprecated
): ((value) => void) =>
  isMulti
    ? // @ts-ignore because this component is deprecated
      value => helper.setValue(prev => [...prev, value])
    : value => helper.setValue(value)

export const SelectFormik = ({
  options,
  name,
  isMulti,
  findCurrentByProperty,
  validate = false,
  childFieldKeys,
  ...rest
}: ComponentProps<typeof Select> & {
  validate?: boolean
  childFieldKeys?: string[]
}) => {
  // @ts-ignore because this component is deprecated
  const [field, , helper] = useField(name)

  // Reset value to first option if value is not in the options
  useEffect(() => {
    if (!validate || field.value === undefined || !options?.length) return

    const isValueValid = isValueInOptions(
      options,
      field.value,
      // @ts-ignore because this component is deprecated
      isMulti,
      childFieldKeys
    )

    if (!isValueValid) {
      helper.setValue(isMulti ? [options[0].value] : options[0].value)
    }
  }, [field.value, helper, isMulti, options, validate, childFieldKeys])

  if (!options) return null

  const currValue = getCurrentValue({
    options,
    value: field.value,
    isMulti,
    findCurrentByProperty,
  })

  const onChange = normalizedOnChange(helper, Boolean(isMulti))
  return (
    <Select
      onChange={onChange}
      value={currValue}
      options={options}
      isMulti={isMulti}
      {...rest}
    />
  )
}
