/**
 * Converts a supplied string to title case.
 * @param str - The string to convert.
 * @returns The converted string in title case.
 */
export const toTitleCase = (str: string): string =>
  `${str}`
    .split(` `)
    .map(
      (w: string) => `${w[0]}`.toUpperCase() + `${w.substring(1)}`.toLowerCase()
    )
    .join(` `)

/**
 * Removes the trailing slash from a path.
 * @param path - The path to process.
 * @returns The path without the trailing slash.
 */
export const stripTrailingSlash = (path: string): string =>
  path.replace(/\/$/, ``)

/**
 * Converts a supplied string to a slug.
 * @param str - The string to convert.
 * @returns The converted string as a slug.
 */
export const slugifyText = (str: string): string =>
  `${str}`
    .toLowerCase()
    .trim()
    .replace(/ /g, `-`)
    .replace(/[^\w-]+/g, ``)

/**
 * Removes the trailing file name from a full path.
 * @param fullPath - The full path to process.
 * @returns The path without the trailing file name.
 */
export const stripTrailingFileName = (fullPath: string): string => {
  const arr = fullPath.split(`/`)
  arr.pop()
  return arr.join(`/`)
}

/**
 * Checks if a string starts with a special character.
 * @param str - The string to check.
 * @returns True if the string starts with a special character, false otherwise.
 */
export const startsWithSpecialChar = (str: string): boolean => {
  const pattern = /^[=\-@+]/
  return pattern.test(str)
}

/**
 * Converts a string to PascalCase.
 * @param str - The string to convert.
 * @returns The converted string in PascalCase.
 */
export const toPascalCase = (str: string) =>
  str
    .toLocaleLowerCase()
    .split(`_`)
    .map(substr => substr.charAt(0).toUpperCase() + substr.slice(1))
    .join(``)
