const exportDefaults = `jpgMax`

// sizes added for 2021 based off numerous sources:
// https://docs.google.com/spreadsheets/d/1FQoW4qCAiLBlWpz-DSR_gWMJBoQX_4T9lVXTRgajDG0/edit#gid=0
// https://sproutsocial.com/insights/social-media-image-sizes-guide/#facebook
// https://louisem.com/2852/social-media-cheat-sheet-sizes
// https://blog.hootsuite.com/social-media-image-sizes-guide/

export const social = {
  facebook: {
    main: {
      sizes: {
        timeline: {
          label: `Facebook Timeline / News Feed`,
          width: 1200,
          height: 630,
          exportDefaults,
        },
        eventCover: {
          label: `Facebook Event Cover`,
          width: 1200,
          height: 628,
          exportDefaults,
        },
        ad: {
          label: `Facebook Ad / Post (square)`,
          width: 1080,
          height: 1080,
          exportDefaults,
        },
        story: {
          label: `Facebook Story`,
          width: 1080,
          height: 1920,
          exportDefaults,
        },
      },
    },

    extended: {
      sizes: {
        coverPhoto: {
          label: `Facebook Page Cover`,
          width: 851,
          height: 315,
          exportDefaults,
        },
        carouselPhoto: {
          label: `Facebook Carousel Image`,
          width: 1200,
          height: 1200,
          exportDefaults,
        },
        eventImage: {
          label: `Facebook Event Image`,
          width: 1920,
          height: 1005,
          exportDefaults,
        },
      },
    },
  },

  instagram: {
    main: {
      sizes: {
        post: {
          label: `Instagram Post`,
          width: 1080,
          height: 1080,
          exportDefaults,
        },
        story: {
          label: `Instagram Story`,
          width: 1080,
          height: 1920,
          exportDefaults,
        },
      },
    },
    extended: {
      sizes: {
        landscapePhoto: {
          label: `Instagram Landscape Photo`,
          width: 1080,
          height: 566,
          exportDefaults,
        },
        portraitPhoto: {
          label: `Instagram Portrait Photo`,
          width: 1080,
          height: 1350,
          exportDefaults,
        },
        igtvCoverPhoto: {
          label: `IGTV Cover Photo`,
          width: 420,
          height: 654,
          exportDefaults,
        },
      },
    },
  },

  twitter: {
    main: {
      sizes: {
        card: {
          label: `Twitter Image`,
          width: 1200,
          height: 675,
          exportDefaults,
        },
        websiteCard: {
          label: `Twitter Website / Ad Card`,
          width: 800,
          height: 418,
          exportDefaults,
        },
      },
    },
    extended: {
      sizes: {
        coverPhoto: {
          label: `Twitter Cover Photo`,
          width: 1500,
          height: 500,
          exportDefaults,
        },
      },
    },
  },

  linkedin: {
    main: {
      sizes: {
        companyCover: {
          label: `LinkedIn Company Page Cover`,
          width: 1128,
          height: 191,
          exportDefaults,
        },
        linkPost: {
          label: `LinkedIn Shared Link / Post`,
          width: 1200,
          height: 627,
          exportDefaults,
        },
        sharedImage: {
          label: `LinkedIn Shared Image Square`,
          width: 1200,
          height: 1200,
          exportDefaults,
        },
      },
    },
    extended: {
      sizes: {
        lifeTabMainImage: {
          label: `LinkedIn Life Tab Main Image`,
          width: 1128,
          height: 376,
          exportDefaults,
        },
        lifeTabCompanyImage: {
          label: `LinkedIn Life Tab Company Image`,
          width: 900,
          height: 600,
          exportDefaults,
        },
      },
    },
  },

  googleMyBusiness: {
    /**
     * @deprecated - use googleMyBusiness.main.sizes instead
     */
    sizes: {
      coverPhoto: {
        label: `Google My Business Cover Photo`,
        width: 1024,
        height: 576,
        exportDefaults,
      },
      postImage: {
        label: `Google My Business Post Image`,
        width: 1200,
        height: 900,
        exportDefaults,
      },
    },
    main: {
      sizes: {
        coverPhoto: {
          label: `Google My Business Cover Photo`,
          width: 1024,
          height: 576,
          exportDefaults,
        },
        postImage: {
          label: `Google My Business Post Image`,
          width: 1200,
          height: 900,
          exportDefaults,
        },
      },
    },
  },

  snapchat: {
    /**
     * @deprecated - use snapchat.main.sizes instead
     */
    sizes: {
      image: {
        label: `Snapchat Ad Image`,
        width: 1080,
        height: 1920,
        exportDefaults,
      },
    },
    main: {
      sizes: {
        image: {
          label: `Snapchat Ad Image`,
          width: 1080,
          height: 1920,
          exportDefaults,
        },
      },
    },
  },

  youtube: {
    /**
     * @deprecated - use youtube.main.sizes instead
     */
    sizes: {
      channelCover: {
        label: `YouTube Channel Cover Photo`,
        width: 2048,
        height: 1152,
        exportDefaults,
      },
      thumbnail: {
        label: `YouTube Thumbnail`,
        width: 1280,
        height: 720,
        exportDefaults,
      },
    },
    main: {
      sizes: {
        channelCover: {
          label: `YouTube Channel Cover Photo`,
          width: 2048,
          height: 1152,
          exportDefaults,
        },
        thumbnail: {
          label: `YouTube Thumbnail`,
          width: 1280,
          height: 720,
          exportDefaults,
        },
      },
    },
  },

  tiktok: {
    /**
     * @deprecated - use tiktok.main.sizes instead
     */
    sizes: {
      videoImage: {
        label: `TikTok Video Image`,
        width: 1080,
        height: 1920,
        exportDefaults,
      },
    },
    main: {
      sizes: {
        videoImage: {
          label: `TikTok Video Image`,
          width: 1080,
          height: 1920,
          exportDefaults,
        },
      },
    },
  },
} as const
