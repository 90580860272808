import {ComponentRef, ForwardedRef, forwardRef} from 'react'
import {cn} from '../../util'
import {FormItem} from '../FormItem'
import {InputIcon} from './components/InputIcon'
import {getInputClassName, textAlignClassName} from './helpers'
import {InputProps} from './types'

const Input = (
  {
    className = ``,
    details,
    endContent,
    htmlSize,
    id,
    inlineActions,
    label,
    labelHidden,
    prefixIcon,
    showSkeleton,
    suffixIcon,
    textAlign = `left`,
    variant = `md`,
    ...props
  }: InputProps,
  ref: ForwardedRef<ComponentRef<`input`>>
) => {
  if (!(props.name && label && id)) return null

  const classNames = cn(
    getInputClassName({...props, variant, className}),
    prefixIcon && `pl-8`,
    suffixIcon && `pr-8`,
    textAlignClassName[textAlign]
  )

  return (
    <FormItem
      label={
        labelHidden ? null : (
          <FormItem.Label htmlFor={id} dim={props.readOnly}>
            {label}
          </FormItem.Label>
        )
      }
      details={details}
    >
      <div className={`flex gap-2`}>
        {showSkeleton ? (
          // eslint-disable-next-line no-irregular-whitespace
          <span className={classNames}>{` `}</span>
        ) : (
          <>
            {prefixIcon && <InputIcon icon={prefixIcon} />}
            <input
              className={classNames}
              id={id}
              aria-label={labelHidden ? `${label}` : undefined}
              style={{contain: `paint`}}
              size={htmlSize}
              {...props}
              ref={ref}
            />
            {suffixIcon && <InputIcon position="after" icon={suffixIcon} />}
          </>
        )}
        {inlineActions}
      </div>
      {endContent}
    </FormItem>
  )
}

export default forwardRef(Input)
