import {useField} from 'formik'

import {FieldError, Input, InputProps, inputHelpers} from '@myadbox/stellar-ui'
import {ErrorMessageFormikStyled} from '../ErrorMessageFormikStyled'

export const InputFormik = ({
  scaleErrorMessage,
  readOnly,
  ...props
}: InputProps & {scaleErrorMessage?: boolean}) => {
  const [field] = useField(props.name)

  return (
    <Input
      {...field}
      endContent={
        <ErrorMessageFormikStyled
          name={props.name}
          component={FieldError}
          data-testid={`error-${props.name}`}
        />
      }
      {...props}
      // we can't pass readOnly to Input for Formik fields because of a flaw in
      // the way we are calculating the readOnly prop in the usePageFields hook.
      // Briefly, fields turn readOnly when they have no content, causing their
      // corresponding hitarea to have zero size. This makes it impossible for
      // the user to click on the field to focus it and start typing.
      // We rely on styling to make the field look disabled/readonly instead.
      className={readOnly ? inputHelpers.disabledClassName : ``}
    />
  )
}

export default InputFormik
