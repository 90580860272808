/* eslint-disable max-lines */
import {useQuery} from '@apollo/client'
import {MagnifyingGlassIcon} from '@heroicons/react/24/outline'
import {Dropdown, SEO} from '@myadbox/gatsby-theme-nebula'
import {useUserSession} from '@myadbox/gatsby-theme-nebula/hooks'
import {PageActionHeader} from '@myadbox/nebula-layouts'
import {
  FETCH_MORE_USERS,
  User,
  useAccount,
  useProfiles,
  useTeams,
} from '@myadbox/nebula-service-api'
import {
  Banner,
  Button,
  Input,
  ComplexModal as Modal,
  TableLoadingState,
  TableScrollWrap,
} from '@myadbox/stellar-ui'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {ReactNode, useEffect, useMemo, useRef, useState} from 'react'
import {useDebouncedCallback} from 'use-debounce'
import {SettingsTabComponentName} from '../helpers'
import BulkUploadModal from './BulkUploadModal/BulkUploadModal'
import UserForm from './UserForm'
import UserTable from './UserTable'
import {downloadUsersData, teamMapByUserId} from './helpers'
import useOnScreen from './useOnScreen'

export const LIMIT = 100
const DEFAULT_OFFSET = 0
const MIN_CHARACTERS = 3

const Users = () => {
  const {t} = useTranslation()
  const {isSesimiAdmin} = useUserSession()

  const [profiles, setProfiles] = useState<User[]>([])
  const [offset, setOffset] = useState(DEFAULT_OFFSET)
  const [keywords, setKeywords] = useState(null)
  const [downloading, setDownloading] = useState(false)
  const [openCsvUploadModal, setOpenCsvUploadModal] = useState(false)

  const ref = useRef(null)
  const isLastVisible = useOnScreen(ref)
  const {account} = useAccount()
  const hasProfiles = profiles.length > 0

  const {
    loading: fetchMoreLoading,
    error: fetchMoreError,
    data: fetchMoreData,
    fetchMore,
  } = useQuery(FETCH_MORE_USERS, {
    variables: {
      offset,
      limit: LIMIT,
    },
  })

  const {
    fetchAllProfiles,
    allProfilesResult: {
      loading: fetchAllLoading,
      error: fetchAllError,
      data: profileData,
    },
    searchUsers,
    searchUsersResult: {data: searchResult, loading: searchUsersLoading},
    bulkUpdateProfiles,
    bulkUpdateProfilesResponse,
  } = useProfiles()

  const {
    fetchAllTeams,
    allTeamsResult: {data: teamData},
  } = useTeams()

  const error = fetchMoreError || fetchAllError

  useEffect(() => {
    if (isLastVisible && hasProfiles && !keywords) {
      const fetchOffsetLimit = (offset + 1) * LIMIT
      if (
        fetchMoreData?.fetchMoreUsers.length <= fetchOffsetLimit &&
        fetchOffsetLimit < fetchMoreData?.fetchMoreUsers.length + LIMIT
      ) {
        setOffset(offset => offset + 1)
        fetchMore({
          variables: {
            offset: fetchOffsetLimit,
          },
        })
      }
    } else if (keywords) {
      searchUsers(keywords)
    }
  }, [
    fetchMore,
    isLastVisible,
    keywords,
    hasProfiles,
    offset,
    searchUsers,
    fetchMoreData?.fetchMoreUsers.length,
  ])

  useEffect(() => {
    fetchAllTeams()
  }, [fetchAllTeams])

  useEffect(() => {
    if (!searchUsersLoading && keywords) {
      setProfiles(searchResult?.searchUsers || [])
    } else if (!fetchMoreLoading && !keywords) {
      setProfiles(fetchMoreData?.fetchMoreUsers || [])
    }
  }, [
    searchUsersLoading,
    fetchMoreData,
    fetchMoreLoading,
    searchResult,
    keywords,
  ])

  const teamsByUserIdMap = useMemo(
    () => teamMapByUserId(teamData?.getTeams || []),
    [teamData]
  )

  useEffect(() => {
    if (downloading && !fetchAllLoading) {
      downloadUsersData(
        profileData?.allUsersByAccountId || [],
        teamsByUserIdMap
      ).then(() => {
        setDownloading(false)
      })
    }
  }, [
    profileData?.allUsersByAccountId,
    downloading,
    fetchAllLoading,
    teamsByUserIdMap,
  ])

  useEffect(() => {
    const {data} = bulkUpdateProfilesResponse
    if (data?.bulkUpdateProfiles) {
      fetchMore({
        variables: {
          offset: DEFAULT_OFFSET,
          limit: LIMIT,
        },
      })
    }
  }, [bulkUpdateProfilesResponse, fetchMore])

  const debounced = useDebouncedCallback(value => {
    if (value.length >= MIN_CHARACTERS) {
      setKeywords(value)
      setOffset(DEFAULT_OFFSET)
    } else {
      setKeywords(null)
    }
  }, 1000)

  const downloadUsers = async () => {
    setDownloading(true)
    fetchAllProfiles()
  }

  return (
    <>
      <SEO title="Users" />
      <PageActionHeader text={t`settings.users.subtitle`}>
        <div
          className={`
            flex
            gap-2
          `}
        >
          <Input
            placeholder={t`settings.users.searchUser`}
            type="search"
            label={t`settings.users.search`}
            labelHidden
            name="user-search"
            id="user-search"
            prefixIcon={<MagnifyingGlassIcon width={16} height={16} />}
            onChange={e => debounced(e.target.value)}
          />

          {isSesimiAdmin && (
            <Dropdown
              trigger={
                <div className="whitespace-nowrap">
                  <Dropdown.Button
                    id="users-download-menu-trigger"
                    variant="secondary"
                  >
                    {t`settings.users.bulkActions.button`}
                  </Dropdown.Button>
                </div>
              }
            >
              <Dropdown.Item
                disabled={!hasProfiles}
                onSelect={() => {
                  downloadUsers() // TODO: add confirmation modal
                }}
              >
                {t`settings.users.bulkActions.downloadCsv`}
              </Dropdown.Item>

              <Dropdown.Item
                disabled={!hasProfiles}
                onSelect={() => {
                  setOpenCsvUploadModal(true)
                }}
              >
                {t`settings.schemas.csvUploader.btnLabel`}
              </Dropdown.Item>
            </Dropdown>
          )}
          <BulkUploadModal
            isOpen={openCsvUploadModal}
            onClose={() => setOpenCsvUploadModal(false)}
            setOpenCsvUploadModal={setOpenCsvUploadModal}
            bulkUpdateProfiles={bulkUpdateProfiles}
            bulkUpdateProfilesResponse={bulkUpdateProfilesResponse}
          />
          <Modal
            accessibleTitle={t`settings.users.create`}
            trigger={({open}) => (
              <Button
                onClick={open}
                variant="primary"
                id="create-user-menu-trigger"
              >{t`settings.users.createUser`}</Button>
            )}
          >
            {({close}): ReactNode => <UserForm close={close} />}
          </Modal>
        </div>
      </PageActionHeader>

      <TableScrollWrap minWidth={700}>
        <UserTable
          teamsByUserIdMap={teamsByUserIdMap}
          profiles={profiles || []}
          searchUsersLoading={searchUsersLoading}
          fetchMoreLoading={fetchMoreLoading}
          userPoolId={account?.configuration?.loginConfig?.userPoolId}
          rowRef={ref}
          keywords={keywords}
        />
      </TableScrollWrap>

      {fetchMoreLoading && <TableLoadingState />}
      {error && (
        <div className={`my-4`}>
          <Banner intent="error">
            {t`settings.error`}: {fetchMoreError.message}
          </Banner>
        </div>
      )}
    </>
  )
}

Users.displayName = `Users` satisfies SettingsTabComponentName
export default Users
