import {type SelectFormikTypes} from '@myadbox/nebula-formik'
import {Category, User} from '@myadbox/nebula-service-api'
import * as Yup from 'yup'

export interface CategoryTree extends Category {
  children: CategoryTree[]
}

export const getParentOptions = (
  categories: Category[],
  exclude?: Category
): SelectFormikTypes.SelectOption[] => {
  const filteredCategories =
    exclude && !exclude.parent
      ? categories.filter(c => c.id !== exclude.id)
      : categories

  const rootCategories = filteredCategories.filter(c => c.parent === null)
  const options = rootCategories.map(category => ({
    value: category.id,
    label: category.title,
  }))
  const sortedOptions = options.sort((a, b) =>
    a.label.localeCompare(b.label, `en`, {sensitivity: `base`})
  )
  return sortedOptions
}

export const getValidationSchema = t =>
  Yup.object().shape({
    title: Yup.string().required(
      t`settings.categories.helpers.validation.title.required`
    ),
  })

export const getCategoryById = (
  id: string,
  categories: Category[]
): Category => {
  return categories.find(category => category.id === id)
}

export const getAncestry = (
  category: Category,
  categories: Category[],
  separator = `>`,
  ancestry?: string
): string => {
  let output = ancestry || category.title

  if (category.parent) {
    const parent = getCategoryById(category.parent, categories)
    output = getAncestry(
      parent,
      categories,
      separator,
      `${parent.title} ${separator} ${output}`
    )
  }

  return output
}

export const getCategoryTree = (
  categories: Category[] = [],
  sortBy: string
): CategoryTree[] => {
  return listToTree(categories, sortBy)
}

/* Note: Only handles 1 level of parent-child struct */
const listToTree = (list = [], sortBy = `id`) => {
  const sortedList = [...list].sort((a, b) => {
    if (typeof a[sortBy] !== `string`) return null
    return a[sortBy].localeCompare(b[sortBy], `en`, {sensitivity: `base`})
  })

  const parents = sortedList.filter(l => !l.parent)
  const tree = []
  parents.forEach(p => {
    const children = sortedList.filter(l => l.parent === p.id)
    tree.push({...p, children})
  })
  return tree
}

export const generateProfilesMap = (profiles: User[]): Record<string, User> => {
  return profiles.reduce((acc, cur) => {
    acc[cur.userId] = cur
    return acc
  }, {})
}
