import {MutationHookOptions, useMutation} from '@apollo/client'
import {EXPORT_SOCIAL} from '../mutations'
import {CarsalesInput, ExportSocialInput, UseExportSocialOutput} from '../types'

export const useExportSocial = (
  options?: MutationHookOptions
): UseExportSocialOutput => {
  const [doExport, exportResponse] = useMutation(EXPORT_SOCIAL, options)

  const exportSocial = (input: ExportSocialInput | CarsalesInput) => {
    return doExport({
      variables: {input},
    })
  }

  return {
    exportSocial,
    exportResponse,
  }
}
