import {TrashIcon} from '@heroicons/react/24/outline'
import {Button, ComplexModal as Modal} from '@myadbox/stellar-ui'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {ReactElement} from 'react'
import DeleteDatasetMessage from '../DeleteDatasetMessage'
import {getUsageDescription} from '../helpers'

interface Props {
  datasetId: string
  schemaId: string
  refetchDatasets(): void
  usage?: {
    assetsCount: number
    usersCount: number
  }
}

export const DeleteDatasetModal = ({
  datasetId,
  schemaId,
  usage,
  refetchDatasets,
}: Props) => {
  const {t} = useTranslation()

  const isUsed = usage?.assetsCount > 0 || usage?.usersCount > 0
  const usageText = getUsageDescription(
    usage?.assetsCount || 0,
    usage?.usersCount || 0,
    t
  )

  return (
    <Modal
      trigger={({open}): ReactElement => (
        <Button
          size="xs"
          onClick={open}
          slim
          variant={`text`}
          type="button"
          title={t`settings.remove`}
        >
          <TrashIcon width={18} />
        </Button>
      )}
    >
      {({close}): ReactElement => (
        <DeleteDatasetMessage
          close={close}
          datasetId={datasetId}
          schemaId={schemaId}
          usageText={usageText}
          isUsed={isUsed}
          refetchDatasets={refetchDatasets}
        />
      )}
    </Modal>
  )
}

export default DeleteDatasetModal
