import {UploadField} from '@myadbox/gatsby-theme-nebula'
import {downloadErrorTextFile} from '@myadbox/gatsby-theme-nebula/utils'
import {useAssets} from '@myadbox/nebula-service-api'
import {
  ActionGroup,
  Banner,
  Button,
  ComplexModal as Modal,
  Text,
  Toast,
} from '@myadbox/stellar-ui'
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next'
import {ReactNode, useEffect, useState} from 'react'
import {
  MAX_ROWS_LIMIT,
  convertAssetData,
  transformHeaders,
  validateData,
} from './helpers'

interface Props {
  close: () => void
}

export const BulkUploadModalContent = ({close}: Props) => {
  const {t} = useTranslation()
  const [assetData, setAssetData] = useState(null)
  const [csvError, setCsvError] = useState({})
  const [bulkUpdateResponse, setBulkUpdateResponse] = useState(null)
  const {
    bulkUpdateAssets,
    bulkUpdateAssetsResponse: {data, error, loading},
  } = useAssets()

  useEffect(() => {
    setBulkUpdateResponse(data)
  }, [data])

  useEffect(() => {
    if (assetData) setCsvError({})
  }, [setCsvError, assetData])

  useEffect(() => {
    if (bulkUpdateResponse && !error) {
      Toast.show(
        <Toast intent="success">
          {t(`settings.account.assetData.csvUploader.successMsg`)}
        </Toast>
      )
      setBulkUpdateResponse(null)
      close()
    }
  }, [bulkUpdateResponse, close, error, t])

  const getErrorMessage = (): string | ReactNode => {
    if (csvError[`limit`])
      return (
        <Trans
          i18nKey={csvError[`limit`]}
          values={{maxRowLimit: MAX_ROWS_LIMIT}}
        />
      )

    return error?.message || t`settings.account.assetData.csvUploader.error`
  }

  const handleSubmit = () => {
    if (!assetData) return
    const errors = validateData(assetData, t)
    if (Object.keys(errors).length === 0) {
      const convertedData = convertAssetData(assetData)
      const batchSize = 200
      const totalRows = convertedData.length

      // Split data into batches if total rows are more than the defined batch size
      if (totalRows > batchSize) {
        for (
          let startIndex = 0;
          startIndex < totalRows;
          startIndex += batchSize
        ) {
          const endIndex = Math.min(startIndex + batchSize, totalRows)
          const batchData = convertedData.slice(startIndex, endIndex)
          bulkUpdateAssets(batchData)
        }
      } else {
        bulkUpdateAssets(convertedData)
      }
    } else {
      setCsvError(errors)
    }
  }

  return (
    <form
      name="csv-upload-form"
      onSubmit={e => {
        e.preventDefault()
        handleSubmit()
      }}
    >
      <Modal.Body>
        <div className="grid gap-4">
          <Text tag="p">{t`settings.account.assetData.csvUploader.notes`}</Text>
          <UploadField
            id="usersCsvData"
            name="csvData"
            label={t`settings.account.assetData.csvUploader.csvFieldLabel`}
            onUpload={setAssetData}
            headerTransformer={transformHeaders}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        {(!!error || Object.keys(csvError).length !== 0) && (
          <div
            className="col-span-full mb-4"
            data-testid="bulk-upload-modal-content-error-banner"
          >
            <Banner intent="error" fadeIn>
              <div>
                <span>{getErrorMessage()}</span>
              </div>
            </Banner>
          </div>
        )}
        {Object.keys(csvError).length !== 0 && (
          <Button
            slim
            size="sm"
            variant="link"
            className={`ml-2`}
            onClick={() =>
              downloadErrorTextFile(csvError, `assets-csv-upload-errors`)
            }
          >
            {t`settings.account.assetData.csvUploader.csvErrorDownloadBtn`}
          </Button>
        )}
        <ActionGroup>
          <Button type="button" variant="secondary" onClick={close}>
            {t`settings.account.assetData.csvUploader.cancelBtn`}
          </Button>
          <Button
            type="submit"
            disabled={!assetData || loading}
            loading={loading}
            loadingText={t`settings.account.assetData.csvUploader.loading`}
            variant="primary"
          >
            {t`settings.account.assetData.csvUploader.uploadBtn`}
          </Button>
        </ActionGroup>
      </Modal.Footer>
    </form>
  )
}
