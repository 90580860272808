import {EVENTS, trackEventByKey} from '@myadbox/mixpanel'
import {Team, useTeams} from '@myadbox/nebula-service-api'
import {
  ActionGroup,
  Button,
  ComplexModal as Modal,
  Text,
} from '@myadbox/stellar-ui'
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next'
import {ReactElement} from 'react'

interface Props {
  team: Team
}

const DeleteTeamModal = ({team}: Props) => {
  const {t} = useTranslation()

  const {
    deleteTeam,
    deleteMutationResult: {loading},
  } = useTeams()

  if (!team) return null

  return (
    <Modal
      accessibleTitle={t`settings.teams.removeTitle`}
      trigger={({open}): ReactElement => (
        <Button variant="link" slim onClick={open}>
          {t`settings.remove`}
        </Button>
      )}
    >
      {({close}): ReactElement => (
        <>
          <Modal.Header>{t`settings.teams.removeTitle`}</Modal.Header>
          <Modal.Body>
            <Text>
              <Trans
                i18nKey="settings.teams.removeText"
                values={{teamName: team.name}}
                components={[<strong key="1" />]}
              />
            </Text>
          </Modal.Body>
          <Modal.Footer>
            <ActionGroup>
              <Button type="button" variant="secondary" onClick={close}>
                {t`settings.teams.removeCancel`}
              </Button>
              <Button
                type="submit"
                variant="danger"
                disabled={loading}
                onClick={(): void => {
                  deleteTeam(team.id)
                  trackEventByKey(EVENTS.TEAM_REMOVED, {teamId: team.id})
                  close()
                }}
              >
                {t`settings.teams.removeConfirm`}
              </Button>
            </ActionGroup>
          </Modal.Footer>
        </>
      )}
    </Modal>
  )
}

export default DeleteTeamModal
